import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Breadcrumb, Button, Modal } from 'react-bootstrap';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import banner from '../assets/images/our-rmc-banner.png'
import rmxconcreto from '../assets/images/rmxconcreto.png'

import bannermobile from '../assets/images/our-rmc-banner-mobile.jpg';
import aboutBannerLogo from '../assets/images/rmcIcon.png'
import abouticon1 from '../assets/images/about-icon1.jpg'
import abouticon2 from '../assets/images/abouticon2.jpg'
import abouticon3 from '../assets/images/abouticon3.jpg'
import doubicon from '../assets/images/doubicon.png'
import abouticon4 from '../assets/images/abouticon4.jpg'
import abouticon5 from '../assets/images/abouticon5.jpg'
import buss1 from '../assets/images/business1.jpg'
import buss2 from '../assets/images/business2.jpg'
import buss3 from '../assets/images/business3.jpg'

import img1 from '../assets/images/vimi1.png'
import img2 from '../assets/images/vimi2.png'
import img3 from '../assets/images/vimi3.png'
import img4 from '../assets/images/vimi4.png'
import img5 from '../assets/images/vimi5.png'
import img6 from '../assets/images/vimi6.png'
import img7 from '../assets/images/vimi7.png'
import coffeeTable from '../assets/images/coffee-table-img.jpg'
import stand4icon from '../assets/images/stand4icon.png'
import stand5icon from '../assets/images/stand5icon.png'
import stand6icon from '../assets/images/stand6icon.png'
import cementLogo from '../assets/images/cement_logo.svg';
import dcement from '../assets/images/dcement.png';
import dbull from '../assets/images/dbull.png';
import nirmex from '../assets/images/nirmex.png';
import psc from '../assets/images/psc.png';
import infracem from '../assets/images/infracem.png';
import artiste from '../assets/images/artiste.png';
import concreto from '../assets/images/concreto.png';
import ecodure from '../assets/images/ecodure.png';
import instamix from '../assets/images/instamix.png';
import xcon from '../assets/images/xcon.png';
import zeroM from '../assets/images/zeroM.png';
import concreto01 from '../assets/images/concreto.jpg'
import landmark01 from '../assets/images/landmark01.png';
import landmark02 from '../assets/images/landmark02.png';
import landmark03 from '../assets/images/landmark03.png';
import landmark04 from '../assets/images/landmark04.png';
import landmark05 from '../assets/images/landmark05.png';
import landmark06 from '../assets/images/landmark06.png';
import landmark07 from '../assets/images/landmark07.png';
import landmark08 from '../assets/images/landmark08.png';
import landmarkcenter01 from '../assets/images/landmarkcenter01.jpg';
import landmarkcenter02 from '../assets/images/landmarkcenter02.jpg';
import landmarkcenter03 from '../assets/images/landmarkcenter03.jpg';
import landmarkcenter04 from '../assets/images/landmarkcenter04.jpg';
import landmarkcenter05 from '../assets/images/landmarkcenter05.jpg';
import landmarkcenter06 from '../assets/images/landmarkcenter06.jpg';
import landmarkcenter07 from '../assets/images/landmarkcenter07.jpg';
import landmarkcenter08 from '../assets/images/landmarkcenter08.jpg';
import landmarkcenter09 from '../assets/images/landmarkcenter09.jpg';
import landmarkcenter10 from '../assets/images/landmarkcenter10.jpg';
import concretoagileflow from '../assets/images/concreto-agile-flow.png';
import concretoagile from '../assets/images/concreto-agile.png';
import concretorobuste from '../assets/images/concreto-robuste.png';
import concretofluide from '../assets/images/concreto-fluide.png';
import concretoinstante from '../assets/images/concreto-instante.png';
import concretoregletherme from '../assets/images/concreto-regletherme.png';
import concretocorrosafeplus from '../assets/images/concreto-corrosafe-plus.png';
import concretopermadure from '../assets/images/concreto-permadure.png';
import concretosteelibre from '../assets/images/concreto-steelibre.png';
import concretoxlite from '../assets/images/concreto-xlite.png';
import concretoeasyfille from '../assets/images/concreto-easyfille.png';
import concretolente from '../assets/images/concreto-lente.png';
import concretocwtplus from '../assets/images/concreto-cwt-plus.png';
import concretoxlitestructure from '../assets/images/concreto-xlite-structure.png';
import concretoecodure from '../assets/images/concreto-ecodure.png';
import concretopolibre from '../assets/images/concreto-polibre.png';
import instamixbaggedconcrete from '../assets/images/instamixbaggedconcrete.png';
import instamixmicrone from '../assets/images/instamixmicrone.png';
import instamixmortareradymixmortar from '../assets/images/instamixmortareradymixmortar.png';
import artistelogo05 from '../assets/images/artistelogo05.png';
import xconlogo from '../assets/images/xconlogo.png';
import green from '../assets/images/green.jpg'
import concretoglydelogo from '../assets/images/concreto-glyde-logo.png';
import artistsengrave from '../assets/images/artists-engrave.png';
import artistshug from '../assets/images/artists-hug.png';
import artistbare from '../assets/images/artist-bare.png';
import artistscoarse from '../assets/images/artists-coarse.png';
import artistlumos from '../assets/images/artist-lumos.png';
import ecodureproduct from '../assets/images/ecodure-product.png';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { usePathLanguage } from "../hooks/usePathLanguage";

const settings = {
    //centerMode: true,
    //autoplay:true,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
};

const slideLandmark = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
};

const slideLandmarkproject = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
};



const OurRMC = () => {

    const [one, setOne] = useState(false);
    const closeOne = () => setOne(false);

    const [two, setTwo] = useState(false);
    const closeTwo = () => setTwo(false);

    const [three, setThree] = useState(false);
    const closeThree = () => setThree(false);

    const [four, setFour] = useState(false);
    const closeFour = () => setFour(false);

    const [five, setFive] = useState(false);
    const closeFive = () => setFive(false);

    const [six, setSix] = useState(false);
    const closeSix = () => setSix(false);

    const [seven, setSeven] = useState(false);
    const closeSeven = () => setSeven(false);

    const [projectone, setProjectone] = useState(false);
    const closeProjectone = () => setProjectone(false);

    const [projecttwo, setProjecttwo] = useState(false);
    const closeProjecttwo = () => setProjecttwo(false);

    const [projectthree, setProjectthree] = useState(false);
    const closeProjectthree = () => setProjectthree(false);

    const [projectfour, setProjectfour] = useState(false);
    const closeProjectfour = () => setProjectfour(false);

    const [projectfive, setProjectfive] = useState(false);
    const closeProjectfive = () => setProjectfive(false);

    const [projectsix, setProjectsix] = useState(false);
    const closeProjectsix = () => setProjectsix(false);

    const [projectseven, setProjectseven] = useState(false);
    const closeProjectseven = () => setProjectseven(false);

    const [projecteight, setProjecteight] = useState(false);
    const closeProjecteight = () => setProjecteight(false);

    const [projectnine, setProjectnine] = useState(false);
    const closeProjectnine = () => setProjectnine(false);

    const [projectten, setProjectten] = useState(false);
    const closeProjectten = () => setProjectten(false);

    const { t } = useTranslation();

    const pathLanguage = usePathLanguage();


    const showComponent = ((varname) => {
        switch (varname) {
            case "one":
                setOne(true);
                break;
            case "two":
                setTwo(true);
                break;
            case "three":
                setThree(true);
                break;
            case "four":
                setFour(true);
                break;
            case "five":
                setFive(true);
                break;
            case "six":
                setSix(true);
                break;
            case "seven":
                setSeven(true);
                break;

            case "projectone":
                setProjectone(true);
                break;
            case "projecttwo":
                setProjecttwo(true);
                break;
            case "projectthree":
                setProjectthree(true);
                break;
            case "projectfour":
                setProjectfour(true);
                break;
            case "projectfive":
                setProjectfive(true);
                break;
            case "projectsix":
                setProjectsix(true);
                break;
            case "projectseven":
                setProjectseven(true);
                break;
            case "projecteight":
                setProjecteight(true);
                break;
            case "projectnine":
                setProjectnine(true);
                break;
            case "projectten":
                setProjectten(true);
                break;

            default: return;
        }

    })

    return (
        <div className="aboutus-pge inner_page landmarkpages">

            <Helmet>
                <title>Ready Mix Concrete manufacturers and Suppliers | Nuvoco Vistas</title>
                <meta name="keywords" description="" />
                <meta name="description" content="Nuvoco vistas produce ready mix concrete using new age technology and it is one of the leading suppliers of ready mix concrete acroos the country" />
                <link rel="canonical" href="https://nuvoco.com/ready-mix-concrete" />
            </Helmet>

            <div className="banner text-center position-relative">
                <img className='desktopbannerinner' src={banner} alt="" />
                <img className='mobilebannerinner' src={bannermobile} alt="" />
                <article className='position-absolute text-center'>
                    <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                    <h1 className="text-uppercase">{t("ready_mix_concrete.title")}</h1>
                </article>
                <div className='breadouter'>
                    <div className='container'>
                        <Breadcrumb className='mb-0 py-2'>
                            <Breadcrumb.Item href="#">{t("breadcrumb.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                                {t("ready_mix_concrete.title")}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div className='midpart'>
                <Container>
                    <h2 className='grad-heading'>{t("ready_mix_concrete.overview.title")}</h2>
                    <p>
                        {t("ready_mix_concrete.overview.para_1")}
                    </p>
                    <p>{t("ready_mix_concrete.overview.para_2")}</p>
                    <p>{t("ready_mix_concrete.overview.para_3")}</p>


                    <div className="vimi-page mt-5">
                        <h2 className='grad-heading'>{t("ready_mix_concrete.what_makes_us_stand_out.title")}</h2>
                        <Row className='topbx stand-page my-5'>
                            <div className="vimiicon pb-2 d-flex justify-content-center">
                                <div className="imgbx d-flex align-items-center justify-content-center">
                                    <div className="text-center">
                                        <div className='imgout'>
                                            <Card.Img variant="top" src={stand4icon} />
                                        </div>
                                        <Card.Body>

                                            <Card.Text>
                                                {t("ready_mix_concrete.what_makes_us_stand_out.assured_quality")}
                                            </Card.Text>
                                        </Card.Body>
                                    </div>
                                </div>
                                <div className="imgbx d-flex align-items-center justify-content-center">
                                    <div className="text-center">
                                        <div className='imgout'>
                                            <Card.Img variant="top" src={stand5icon} />
                                        </div>
                                        <Card.Body>

                                            <Card.Text>
                                                {t("ready_mix_concrete.what_makes_us_stand_out.always_innovative")}
                                            </Card.Text>
                                        </Card.Body>
                                    </div>
                                </div>
                                <div className="imgbx d-flex align-items-center justify-content-center">
                                    <div className="text-center">
                                        <div className='imgout'>
                                            <Card.Img variant="top" src={stand6icon} />
                                        </div>
                                        <Card.Body>

                                            <Card.Text>
                                                {t("ready_mix_concrete.what_makes_us_stand_out.trust_and_transparency")}
                                            </Card.Text>
                                        </Card.Body>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                    <div className="our_brand_inner">
                        <div className="card_round pt-5">
                            <Container>
                                <h2 className='grad-heading pd-5 mb-5'>{t("ready_mix_concrete.our_brands.title")}</h2>
                                <Row className="pb-4 justify-content-center cursorPointer">
                                    <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                                        <Card onClick={() => showComponent('one')}>
                                            <Card.Img src={rmxconcreto} />
                                            <Card.Body>
                                                <article>
                                                    {t("ready_mix_concrete.our_brands.concreto_description")}
                                                </article>
                                                <Card.Title className="g_invert">
                                                    {t("ready_mix_concrete.our_brands.concreto")}
                                                </Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                                        <Card onClick={() => showComponent('two')}>
                                            <Card.Img src={instamix} />
                                            <Card.Body>
                                                <article>
                                                    {t("ready_mix_concrete.our_brands.instaMix_description")}
                                                </article>
                                                <Card.Title className="g_invert">
                                                    {t("ready_mix_concrete.our_brands.instaMix")}
                                                </Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                                        <Card onClick={() => showComponent('three')}>
                                            <Card.Img src={artiste} />
                                            <Card.Body>
                                                <article>
                                                    {t("ready_mix_concrete.our_brands.artiste_description")}
                                                </article>
                                                <Card.Title className="g_invert">
                                                    {t("ready_mix_concrete.our_brands.artiste")}
                                                </Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                                        <Card onClick={() => showComponent('four')}>
                                            <Card.Img src={xcon} />
                                            <Card.Body>
                                                <article>
                                                    {t("ready_mix_concrete.our_brands.x_con_description")}
                                                </article>
                                                <Card.Title className="g_invert">
                                                    {t("ready_mix_concrete.our_brands.x_con")}
                                                </Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                                        <Card onClick={() => showComponent('five')}>
                                            <Card.Img src={ecodure} />
                                            <Card.Body>
                                                <article>
                                                    {t("ready_mix_concrete.our_brands.ecodure_description")}
                                                </article>
                                                <Card.Title className="g_invert">
                                                    {t("ready_mix_concrete.our_brands.ecodure")}
                                                </Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container >
                        </div>

                    </div>

                    <section>
                        <Container>
                            <h2 className='grad-heading pd-5 mb-5'> {t("ready_mix_concrete.what_makes_us_stand_out.coffee_table_book")}</h2>
                            <Row>
                                <Col className="text-center">
                                    <a href="https://rmxprojects.nuvoco.com/" target="_blank">
                                        <img src={coffeeTable} />
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section className="landmark card_round">

                        <Container className="landmarkpage" id="landmark">
                            <h3 className='grad-heading pd-5 mb-5'>{t("ready_mix_concrete.what_makes_us_stand_out.other_projects")}</h3>

                            <Slider {...slideLandmark}>
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card onClick={() => showComponent('projectone')}>
                                        <Card.Img variant="top" src={landmarkcenter01} />
                                        <Card.Body className="wow fadeInRight" data-wow-delay="0.7s">
                                            <Card.Title><h4>{t("home.landmark_projects.project10.project_name")}</h4></Card.Title>
                                            <p>{t("home.landmark_projects.project10.project_location")}</p>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card onClick={() => showComponent('projecttwo')}>
                                        <Card.Img variant="top" src={landmarkcenter02} />
                                        <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                            <Card.Title><h4>{t("home.landmark_projects.project1.project_name")}</h4></Card.Title>
                                            <p>{t("home.landmark_projects.project1.project_location")}</p>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card onClick={() => showComponent('projectthree')}>
                                        <Card.Img variant="top" src={landmarkcenter03} />
                                        <Card.Body className="wow fadeInRight" data-wow-delay="0.7s">
                                            <Card.Title><h4>{t("home.landmark_projects.project2.project_name")}</h4></Card.Title>
                                            <p>{t("home.landmark_projects.project2.project_location")}</p>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card onClick={() => showComponent('projectfour')}>
                                        <Card.Img variant="top" src={landmarkcenter04} />
                                        <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                            <Card.Title><h4>{t("home.landmark_projects.project3.project_name")}</h4></Card.Title>
                                            <p>{t("home.landmark_projects.project3.project_location")}</p>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card onClick={() => showComponent('projectfive')}>
                                        <Card.Img variant="top" src={landmarkcenter05} />
                                        <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                            <Card.Title><h4>{t("home.landmark_projects.project4.project_name")}</h4></Card.Title>
                                            <p>{t("home.landmark_projects.project4.project_location")}</p>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card onClick={() => showComponent('projectsix')}>
                                        <Card.Img variant="top" src={landmarkcenter06} />
                                        <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                            <Card.Title><h4>{t("home.landmark_projects.project5.project_name")}</h4></Card.Title>
                                            <p>{t("home.landmark_projects.project5.project_location")}</p>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card onClick={() => showComponent('projectseven')}>
                                        <Card.Img variant="top" src={landmarkcenter07} />
                                        <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                            <Card.Title><h4>{t("home.landmark_projects.project6.project_name")}</h4></Card.Title>
                                            <p>{t("home.landmark_projects.project6.project_location")}</p>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card onClick={() => showComponent('projecteight')}>
                                        <Card.Img variant="top" src={landmarkcenter08} />
                                        <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                            <Card.Title><h4>{t("home.landmark_projects.project7.project_name")}</h4></Card.Title>
                                            <p>{t("home.landmark_projects.project7.project_location")}</p>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card onClick={() => showComponent('projectnine')}>
                                        <Card.Img variant="top" src={landmarkcenter09} />
                                        <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                            <Card.Title><h4>{t("home.landmark_projects.project8.project_name")}</h4></Card.Title>
                                            <p>{t("home.landmark_projects.project8.project_location")}</p>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card onClick={() => showComponent('projectten')}>
                                        <Card.Img variant="top" src={landmarkcenter10} />
                                        <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                            <Card.Title><h4>{t("home.landmark_projects.project9.project_name")}</h4></Card.Title>
                                            <p>{t("home.landmark_projects.project9.project_location")}</p>
                                        </Card.Body>
                                    </Card>
                                </div>

                            </Slider>
                        </Container>
                    </section>


                </Container>
            </div>


            <Modal size="lg" centered="true"
                show={one} onHide={closeOne} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("ready_mix_concrete.our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={rmxconcreto} alt='' />
                                <article className="p-4">
                                    {t("ready_mix_concrete.our_brands.concreto_description")}
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoagile} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Agile")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-agile`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretorobuste} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Robuste")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-robuste`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretofluide} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Fluide")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-fluide`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoinstante} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Instante")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-instante`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoregletherme} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Regletherme")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-regletherme`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretocorrosafeplus} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Corrosafe")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-corrosafe-plus`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretopermadure} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Permadure")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-permadure`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretosteelibre} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Steelibre")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-steelibre`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoagileflow} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Agile_Flow")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-agile-flow`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoxlite} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Xlite")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-xlite`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoeasyfille} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Easyfille")}

                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-easyfille`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretolente} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Lente")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-lente`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretocwtplus} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_CWT_Plus")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-cwt-plus`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoxlitestructure} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Xlite_Structure")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-xlite-structure`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoecodure} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Endure")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-endura`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretopolibre} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Polibre")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-polibre`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoglydelogo} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Glyde")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-glyde`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>







                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={two} onHide={closeTwo} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("ready_mix_concrete.our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={instamix} alt='' />
                                <article className="p-4">
                                    {t("ready_mix_concrete.our_brands.instaMix_description")}
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixbaggedconcrete} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.instaMix")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/instamix`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixmicrone} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Instamix_Microne")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/instamix-microne`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixmortareradymixmortar} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Concreto_Green_Cement")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/instamix-mortare`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={three} onHide={closeThree} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("ready_mix_concrete.our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={artiste} alt='' />
                                <article className="p-4">{t("ready_mix_concrete.our_brands.artiste_description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistelogo05} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Artiste_Signature")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-signature`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistsengrave} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Artiste_Engrave")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-engrave`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistshug} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Artiste_Hue")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-hue`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistbare} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Artiste_Bare")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-bare`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistscoarse} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Artiste_Coarse")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-coarse`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistlumos} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Artiste_Lumos")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-lumos`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={four} onHide={closeFour} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("ready_mix_concrete.our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={xcon} alt='' />
                                <article className="p-4">{t("ready_mix_concrete.our_brands.x_con_description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={xconlogo} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.x_con")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/x-con`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>

                                </Col>
                                <Col md={4}>

                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={five} onHide={closeFive} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("ready_mix_concrete.our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={ecodure} alt='' />
                                <article className="p-4">{t("ready_mix_concrete.our_brands.ecodure_description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.ecodure")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/ecodure`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Ecodure_Prime")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/ecodure-prime`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("ready_mix_concrete.our_brands.Ecodure_Plus")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/ecodure-plus`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Landmark project popup */}

            <Modal size="lg" centered="true"
                show={projectone} onHide={closeProjectone} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                        <Col md={12}>

                            <div className="align-item-center productlandmark">
                                <img src={landmarkcenter01} alt='' />
                                <article className="p-4">
                                    <h4>{t("home.landmark_projects.project10.project_name")}</h4>
                                    <p>{t("home.landmark_projects.project10.project_description")}</p>
                                </article>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            <Modal size="lg" centered="true"
                show={projecttwo} onHide={closeProjecttwo} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                        <Col md={12}>

                            <div className="align-item-center productlandmark">
                                <img src={landmarkcenter02} alt='' />
                                <article className="p-4">
                                    <h4>{t("home.landmark_projects.project1.project_name")}</h4>
                                    <p>{t("home.landmark_projects.project1.project_description")}</p>
                                </article>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            <Modal size="lg" centered="true"
                show={projectthree} onHide={closeProjectthree} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                        <Col md={12}>

                            <div className="align-item-center productlandmark">
                                <img src={landmarkcenter03} alt='' />
                                <article className="p-4">
                                    <h4>{t("home.landmark_projects.project2.project_name")}</h4>
                                    <p>{t("home.landmark_projects.project2.project_description")}</p>
                                </article>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            <Modal size="lg" centered="true"
                show={projectfour} onHide={closeProjectfour} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                        <Col md={12}>

                            <div className="align-item-center productlandmark">
                                <img src={landmarkcenter04} alt='' />
                                <article className="p-4">
                                    <h4>{t("home.landmark_projects.project3.project_name")}</h4>
                                    <p>{t("home.landmark_projects.project3.project_description")}</p>
                                </article>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            <Modal size="lg" centered="true"
                show={projectfive} onHide={closeProjectfive} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                        <Col md={12}>

                            <div className="align-item-center productlandmark">
                                <img src={landmarkcenter05} alt='' />
                                <article className="p-4">
                                    <h4>{t("home.landmark_projects.project4.project_name")}</h4>
                                    <p>{t("home.landmark_projects.project4.project_description")}</p>
                                </article>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            <Modal size="lg" centered="true"
                show={projectsix} onHide={closeProjectsix} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                        <Col md={12}>

                            <div className="align-item-center productlandmark">
                                <img src={landmarkcenter06} alt='' />
                                <article className="p-4">
                                    <h4>{t("home.landmark_projects.project5.project_name")}</h4>
                                    <p>{t("home.landmark_projects.project5.project_description")}</p>
                                </article>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            <Modal size="lg" centered="true"
                show={projectseven} onHide={closeProjectseven} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                        <Col md={12}>

                            <div className="align-item-center productlandmark">
                                <img src={landmarkcenter07} alt='' />
                                <article className="p-4">
                                    <h4>{t("home.landmark_projects.project6.project_name")}</h4>
                                    <p>{t("home.landmark_projects.project6.project_description")}</p>
                                </article>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            <Modal size="lg" centered="true"
                show={projecteight} onHide={closeProjecteight} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                        <Col md={12}>

                            <div className="align-item-center productlandmark">
                                <img src={landmarkcenter08} alt='' />
                                <article className="p-4">
                                    <h4>{t("home.landmark_projects.project7.project_name")}</h4>
                                    <p>{t("home.landmark_projects.project7.project_description")}</p>
                                </article>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            <Modal size="lg" centered="true"
                show={projectnine} onHide={closeProjectnine} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                        <Col md={12}>

                            <div className="align-item-center productlandmark">
                                <img src={landmarkcenter09} alt='' />
                                <article className="p-4">
                                    <h4>{t("home.landmark_projects.project8.project_name")}</h4>
                                    <p>{t("home.landmark_projects.project8.project_description")}</p>
                                </article>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            <Modal size="lg" centered="true"
                show={projectten} onHide={closeProjectten} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                        <Col md={12}>

                            <div className="align-item-center productlandmark">
                                <img src={landmarkcenter10} alt='' />
                                <article className="p-4">
                                    <h4>{t("home.landmark_projects.project9.project_name")}</h4>
                                    <p>{t("home.landmark_projects.project9.project_description")}</p>
                                </article>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>




        </div >
    )
}

export default OurRMC;