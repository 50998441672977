import { useParams } from "react-router-dom";
import { usePathLanguage } from "../hooks/usePathLanguage";

function withPathLanguage(Component) {
    return function WrappedComponent(props) {
      const pathLanguage = usePathLanguage();
      const { lng } = useParams();
      return <Component {...props} pathLanguage={pathLanguage} lng={lng} />;
    }
}

export default withPathLanguage;