import React from 'react';
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/aboutus-banner.png'
import bannermobile from '../assets/images/aboutus-banner-mobile.jpg'
import aboutBannerLogo from '../assets/images/aboutba-logo.png'
import abouticon1 from '../assets/images/about-icon1.jpg'
import abouticon2 from '../assets/images/abouticon2.jpg'
import abouticon3 from '../assets/images/abouticon3.jpg'
import abouticon4 from '../assets/images/abouticon4.jpg'
import abouticon5 from '../assets/images/abouticon5.jpg'
import buss1 from '../assets/images/business1.jpg'
import buss2 from '../assets/images/business2.jpg'
import buss3 from '../assets/images/business3.jpg'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { usePathLanguage } from '../hooks/usePathLanguage';

const AboutUs = () => {
  const { t } = useTranslation();
  const pathLanguage = usePathLanguage();
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>About Us | Nuvoco vistas Leading Cement Manufacturer in India</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas is one of the leading brand in producing cement, ready mix concrete and modern building materials and rated as fifth largest cement group in India" />
    <link rel="canonical" href="https://nuvoco.com/about-us"/>
    </Helmet>

      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner' src={bannermobile} alt="" />
        <article className='position-absolute text-center'>
          <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
          <h1 className="text-uppercase">{t("about.title")}</h1>
        </article>
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">{t("breadcrumb.home")}</Breadcrumb.Item>
              <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
              {t("about.title")}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className='midpart'>
        <Container>
          <h2 className='grad-heading'>{t("about.Nuvoco_Glance.title")}</h2>
           {/*<p className='onetxt'>
            <strong>
            The fifth-largest cement group in India and the leading cement player in East India, in terms of capacity</strong></p>*/}
            <p dangerouslySetInnerHTML={ { __html: t("about.Nuvoco_Glance.description1") } }></p>
            <p dangerouslySetInnerHTML={ { __html: t("about.Nuvoco_Glance.description2") } }></p>
            <p dangerouslySetInnerHTML={ { __html: t("about.Nuvoco_Glance.description3") } }></p>
         
          <Row className='abt_mt topbx my-5'>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={abouticon1} />
                </div>
                <Card.Body>
                  <Card.Title>{t("about.Nuvoco_Glance.Cement_Plants")}</Card.Title>
                  <Card.Text>
                  {t("about.Nuvoco_Glance.MMTPA")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={abouticon2} />
                </div>
                <Card.Body>
                  <Card.Title>{t("about.Nuvoco_Glance.Captive_Plants")}</Card.Title>
                  <Card.Text>
                  {t("about.Nuvoco_Glance.MW")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={abouticon3} />
                </div>
                <Card.Body>
                  <Card.Title>{t("about.Nuvoco_Glance.Waste_Heat")}</Card.Title>
                  <Card.Text>
                  {t("about.Nuvoco_Glance.waste_MW")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={abouticon4} />
                </div>
                <Card.Body>
                  <Card.Title>{t("about.Nuvoco_Glance.Solar_Plants")}</Card.Title>
                  <Card.Text>
                  {t("about.Nuvoco_Glance.MWp")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={abouticon5} />
                </div>
                <Card.Body>
                  <Card.Title>{t("about.Nuvoco_Glance.Ready_Mix_Plants")}</Card.Title>
                  <Card.Text>
                  {t("about.Nuvoco_Glance.Ready_Mix_count")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <p>{t("about.Nuvoco_Glance.description4")}</p>
         
          
          <h2 className='grad-heading mt-5'>{t("about.our_business.title")}</h2>
          <p dangerouslySetInnerHTML={ { __html: t("about.our_business.description1") } }></p>
          <p dangerouslySetInnerHTML={ { __html: t("about.our_business.description2") } }></p><br></br>
          <p dangerouslySetInnerHTML={ { __html: t("about.our_business.description3") } }></p><br></br>

          <p dangerouslySetInnerHTML={ { __html: t("about.our_business.description4") } }></p><br></br>

          
          <Row className='bussbx my-5'>
            <Col md={4} className='mb-3'>
              <Card className="pt-0">
              <a href={`${pathLanguage}top-cement-company`}><Card.Img variant="top" src={buss1} /></a>
                <Card.Body>
                  <a href={`${pathLanguage}top-cement-company`}><Card.Title className='mb-0'>{t("about.our_business.cement")}</Card.Title></a>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className='mb-3'>
              <Card className="pt-0">
              <a href={`${pathLanguage}ready-mix-concrete`}><Card.Img variant="top" src={buss2} /></a>
                <Card.Body>
                <a href={`${pathLanguage}ready-mix-concrete`}><Card.Title className='mb-0'>{t("about.our_business.ready_mix_concrete")}</Card.Title></a>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="pt-0">
              <a href={`${pathLanguage}modern-building-materials`}><Card.Img variant="top" src={buss3} /></a>
                <Card.Body>
                <a href={`${pathLanguage}modern-building-materials`}><Card.Title className='mb-0'>{t("about.our_business.modern_building_materials")}</Card.Title></a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div >
  )
}

export default AboutUs