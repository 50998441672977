import React, {useState} from "react";
import {Modal,Button} from 'react-bootstrap';
import Slider from "react-slick";
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/home-page-Web-Banner-1.png'
import banner1 from '../assets/images/tile-stone-fixing-solutions2.png'
import banner2 from '../assets/images/waterproofing-solutions-4.png'
import banner3 from '../assets/images/surface-preperation-solutions-category4.png'
import banner4 from '../assets/images/repair-solutions-category5.png'
import banner5 from '../assets/images/ancillary-solutions-category6.png'
import bannermobile from '../assets/images/home-page-Web-Banner-1-mobile.png';
import bannermobile1 from '../assets/images/tile-stone-fixing-solutions2-mobile.png';
import bannermobile2 from '../assets/images/waterproofing-solutions-3-mobile.png';
import bannermobile3 from '../assets/images/surface-preperation-solutions-category4-mobile.png';
import bannermobile4 from '../assets/images/repair-solutions-category5-mobile.png';
import bannermobile5 from '../assets/images/ancillary-solutions-category6-mobile.png';
import aboutBannerLogo from '../assets/images/our-mbm-icon.png'
import abouticon1 from '../assets/images/about-icon1.jpg'
import abouticon2 from '../assets/images/abouticon2.jpg'
import abouticon3 from '../assets/images/abouticon3.jpg'
import doubicon from '../assets/images/doubicon.png'
import abouticon4 from '../assets/images/abouticon4.jpg'
import abouticon5 from '../assets/images/abouticon5.jpg'
import buss1 from '../assets/images/business1.jpg'
import buss2 from '../assets/images/business2.jpg'
import buss3 from '../assets/images/business3.jpg'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import img1 from '../assets/images/vimi1.png'
import img2 from '../assets/images/vimi2.png'
import img3 from '../assets/images/vimi3.png'
import img4 from '../assets/images/vimi4.png'
import img5 from '../assets/images/vimi5.png'
import img6 from '../assets/images/vimi6.png'
import img7 from '../assets/images/vimi7.png'
import stand7icon from '../assets/images/stand7icon.png'
import stand8icon from '../assets/images/stand8icon.png'
import stand9icon from '../assets/images/stand9icon.png'
import cementLogo from '../assets/images/cement_logo.svg';
import dcement from '../assets/images/dcement.png';
import dbull from '../assets/images/dbull.png';
import nirmex from '../assets/images/nirmex.png';
import psc from '../assets/images/psc.png';
import infracem from '../assets/images/infracem.png';
import artiste from '../assets/images/artiste.png';
import concreto from '../assets/images/concreto.png';
import ecodure from '../assets/images/ecodure.png';
import instamix from '../assets/images/instamix.png';
import xcon from '../assets/images/xcon.png';
import zeroM from '../assets/images/zeroM.png';
import concreto01 from '../assets/images/concreto.jpg'
import zeromwatershieldiwc from '../assets/images/zeromwatershieldiwc1.png';
import zerowatershield from '../assets/images/zerowatershield2.png';
import zeromacrylicpower from '../assets/images/zeromacrylicpower3.png';
import zeromlatexexpert from '../assets/images/zeromlatexexpert4.png';
import zeromspeedextileadhesive from '../assets/images/zeromspeedextileadhesive5.png';
import zeromgermicheckwallputty from '../assets/images/zeromgermicheckwallputty6.png';
import zerompolyrichwallputty from '../assets/images/zerompolyrichwallputty7.png';
import zeromcoverblock from '../assets/images/zeromcoverblock8.png';
import zeromspeedextilegrout from '../assets/images/zeromspeedextilegrout9.png';
import zeromwatershieldkee from '../assets/images/zeromwatershieldkee.jpeg';
import instamixbondaid50kg from '../assets/images/instamixbondaid50kg.png';
import ancillarysolutions from '../assets/images/ancillary-solutions.png';
import repairSolution from '../assets/images/Repair-Solutions.png';
import tileStoneFixing from '../assets/images/Tile-Stone-Fixing-solutions.png';
import surfaceSolution from '../assets/images/surface-preperation-solutions.png';
import waterproofingSolution from '../assets/images/waterproofing-solutions.png';
import instamixplastosmart from '../assets/images/ancillary-solutions.jpg';
import instamixxpress from '../assets/images/instamixxpress.png';
import mbmt1t2 from '../assets/images/mbmt1t2.png';
import { Helmet } from "react-helmet";
import Carousel from 'react-bootstrap/Carousel';
import brochurePdf from '../assets/pdf/MBM-Corporate-2024.pdf'
import { useTranslation } from "react-i18next";
const settings = {
  //centerMode: true,
  //autoplay:true,
  autoplay:true,
  autoplaySpeed: 2000,
  dots: false,
  arrow:true,
  infinite:false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
          },
      },
  ],
};

const OurMBM = () =>{
    const {t} = useTranslation();
    const [one, setOne] = useState(false);
    const closeOne = () => setOne(false);

    const [two, setTwo] = useState(false);
    const closeTwo = () => setTwo(false);

    const [three, setThree] = useState(false);
    const closeThree = () => setThree(false);

    const [four, setFour] = useState(false);
    const closeFour = () => setFour(false);

    const [five, setFive] = useState(false);
    const closeFive = () => setFive(false);

  

    const showComponent=((varname) =>{  
      switch (varname) {
        case "one":
          setOne(true);
          break;
        case "two":
          setTwo(true);
          break;
        case "three":
          setThree(true);
          break;
        case "four":
          setFour(true);
          break;
        case "five":
          setFive(true);
          break;
          
        default: return;
      }
    })

    return(
      <div className="aboutus-pge inner_page">
        <Helmet>
        <title>Modern Building Material Suppliers | Nuvoco Vistas</title>
        <meta name="keywords" description="" />
        <meta name="description" content="Nuvoco vistas offers new age modern building materials with different innovative products offering waterproofing and germ protection for individual home owners and professional users" />
        <link rel="canonical" href="https://nuvoco.com/modern-building-materials"/>
        </Helmet>
      <div className="banner text-center position-relative">
      <div className="mbmProduct">
        <Carousel>
            <Carousel.Item>
                <img className="desktopbannerinner" src={banner} alt="" />
                <img className='mobilebannerinner' src={bannermobile} alt="" />
           </Carousel.Item> 
            <Carousel.Item>
                <img className="desktopbannerinner " src={banner2} alt="" />
                <img className='mobilebannerinner' src={bannermobile2} alt="" />
           </Carousel.Item> 
            <Carousel.Item>
                <img className="desktopbannerinner " src={banner1} alt="" />
                <img className='mobilebannerinner' src={bannermobile1} alt="" />
           </Carousel.Item> 
            <Carousel.Item>
                <img className="desktopbannerinner " src={banner3} alt="" />
                <img className='mobilebannerinner' src={bannermobile3} alt="" />
           </Carousel.Item> 
            <Carousel.Item>
                <img className="desktopbannerinner " src={banner4} alt="" />
                <img className='mobilebannerinner' src={bannermobile4} alt="" />
           </Carousel.Item> 
            <Carousel.Item>
                <img className="desktopbannerinner " src={banner5} alt="" />
                <img className='mobilebannerinner' src={bannermobile5} alt="" />
           </Carousel.Item> 
        </Carousel>    
    </div>
    {/* <img className='mobilebannerinner' src={bannermobile} alt="" /> */}
      {/* <img className='desktopbannerinner' src={banner} alt="" />
               <img className='mobilebannerinner' src={bannermobile} alt="" />
        <article className='position-absolute text-center'>
          <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
          <h1 className="text-uppercase">MODERN BUILDING MATERIALS</h1>
        </article> */}
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">{t("breadcrumb.home")}</Breadcrumb.Item>
              <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
              {t("navigation.mbm")}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className='midpart'>
        <Container>
            <h2 className='grad-heading'>{t("our_business.mbm.title")}</h2>
            <p>{t("our_business.mbm.descritpion")}</p>  
            <p>{t("our_business.mbm.descritpion1")} </p>
           
        <div className="vimi-page mt-5">
          <h2 className='grad-heading'>{t("our_business.mbm.title2")}</h2>
          <Row className='topbx stand-page my-5'>
          <div className="vimiicon pb-2 d-flex justify-content-center">
            <div className="imgbx d-flex align-items-center justify-content-center">
              <div className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={stand7icon} />
                </div>
                <Card.Body>
                  
                  <Card.Text>
                  {t("our_business.mbm.Consistent_Quality")}
                  </Card.Text>
                </Card.Body>
              </div>
            </div>
            <div className="imgbx d-flex align-items-center justify-content-center">
              <div className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={stand8icon} />
                </div>
                <Card.Body>
                  
                  <Card.Text>
                  {t("our_business.mbm.Innovative_Products")}
                  </Card.Text>
                </Card.Body>
              </div>
            </div>
            <div className="imgbx d-flex align-items-center justify-content-center">
              <div className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={stand9icon} />
                </div>
                <Card.Body>
                 
                  <Card.Text>
                  {t("our_business.mbm.Ready_To_Use")}
                  </Card.Text>
                </Card.Body>
              </div>
            </div>
            </div>
          </Row>
      </div>
      <div className="our_brand_inner">
          <div className="card_round pt-5">
              <Container>
                  <h2 className='grad-heading pd-5 mb-5'>{t("our_business.mbm.category")}</h2>
                <Row className="pb-4 justify-content-center align-items-center">
                    <Col md={4} lg={4} className="mb-5 wow fadeInUp">
                        <Card onClick={() => showComponent('one')}>
                            <Card.Body>
                                <h4 className="mb-3  gradient-title">{t("our_business.mbm.Waterproofing_Solutions")} <br/>{t("our_business.mbm.solution")}</h4>
                                <img src={waterproofingSolution} alt='' style={{maxWidth:'300px'}} />
                                <Card.Title className="bandLine bluePlum"></Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} lg={4} className="mb-5 wow fadeInUp">
                        <Card onClick={() => showComponent('three')}>
                            <Card.Body>
                                <h4 className="mb-3 gradient-title">{t("our_business.mbm.tile_stone")} <br/>{t("our_business.mbm.solution")}</h4>
                                <img src={tileStoneFixing} alt='' style={{maxWidth:'300px'}} />
                                <Card.Title className="bandLine orangeGreen"></Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} lg={4} className="mb-5 wow fadeInUp">
                        <Card onClick={() => showComponent('two')}>
                            <Card.Body>
                                <h4 className="mb-3 gradient-title">{t("our_business.mbm.Surface_Preparation")} <br/>{t("our_business.mbm.solution")}</h4>
                                <img src={surfaceSolution} alt='' style={{maxWidth:'300px'}} />
                                <Card.Title className="bandLine greenBlack"></Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} lg={4} className="mb-5 wow fadeInUp">
                        <Card onClick={() => showComponent('four')}>
                            <Card.Body>
                                <h4 className="mb-3 gradient-title">{t("our_business.mbm.Repair_Solutions")} <br/>{t("our_business.mbm.solution")}</h4>
                                <img src={repairSolution} alt='' style={{maxWidth:'300px'}} />
                                <Card.Title className="bandLine greenRed"></Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} lg={4} className="mb-5 wow fadeInUp">
                        <Card onClick={() => showComponent('five')}>
                            <Card.Body>
                                <h4 className="mb-3 gradient-title">{t("our_business.mbm.Ancillary_Solutions")} <br/>{t("our_business.mbm.solution")}</h4>
                                <img src={ancillarysolutions} alt='' style={{maxWidth:'300px'}} />
                                <Card.Title className="bandLine yellowBlack"></Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                      
                </Row>
                 
          </Container >
          </div>
      </div>
      <div className="text-center">
        <a href={brochurePdf} target="_blank" class="btn_lg active btn btn-success ml-0">{t("our_business.mbm.brochureDwn")}</a>
      </div>
        </Container>
      </div>
      <Modal  size="lg" centered="true"
        show={one} onHide={closeOne} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>{t("our_business.mbm.Waterproofing_Solutions")} {t("our_business.mbm.solution")}</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-items-center mt-3">
                        <img src={waterproofingSolution} alt='' style={{maxWidth:'300px'}} />
                        <article className="p-4">{t("our_business.mbm.water_description")}</article>
                    </div>
                    
                
            </Col>
        </Row>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={two} onHide={closeTwo} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>{t("our_business.mbm.Surface_Preparation")} {t("our_business.mbm.solution")}</h2>
            </Col>
            <Col md={12}>
                
                <div className="d-flex align-items-center mt-3">
                    <img src={surfaceSolution} alt='' style={{maxWidth:'300px'}} />
                    <article className="p-4">{t("our_business.mbm.Surface_description")}</article>
                </div>
                    
                
            </Col>
        </Row>
        </Modal.Body>           
    </Modal>

    
    <Modal  size="lg" centered="true"
        show={three} onHide={closeThree} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>{t("our_business.mbm.tile_stone")} {t("our_business.mbm.solution")}</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-items-center mt-3">
                        <img src={tileStoneFixing} alt='' style={{maxWidth:'300px'}} />
                        <article className="p-4">{t("our_business.mbm.tile_stone_description")} </article>
                    </div>
                    
                
            </Col>
        </Row>

        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={four} onHide={closeFour} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>{t("our_business.mbm.Repair_Solutions")} {t("our_business.mbm.solution")}</h2>
            </Col>
            <Col md={12}>
                
                <div className="d-flex align-items-center mt-3">
                    <img src={repairSolution} alt='' style={{maxWidth:'300px'}} />
                    <article className="p-4">{t("our_business.mbm.repair_description")}</article>
                </div>
                    
                
            </Col>
        </Row>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={five} onHide={closeFive} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>{t("our_business.mbm.Ancillary_Solutions")} {t("our_business.mbm.solution")}</h2>
            </Col>
            <Col md={12}>
                <div className="d-flex align-items-center mt-3">
                    <img src={ancillarysolutions} alt='' style={{maxWidth:'300px'}} />
                    <article className="p-4">{t("our_business.mbm.Ancillary_description")}</article>
                </div>
            </Col>
        </Row>

        
        </Modal.Body>           
    </Modal>
    </div>
    )
}

export default OurMBM;