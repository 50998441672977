import { MEDIARELATION, BLOG, BLOGDETAILS } from "./type";
import { API_URL } from "../config";
import axios from "axios";
import { supportedLngsObj } from "../../helpers/utils";

const mediaFn = (data) =>{
    return{
        type: MEDIARELATION,
        payload: data
    };
}

export const mediaRelationApi = (category_id, month_id, year_id) =>{
    var x = window.location.pathname.split("/");
    let lang = supportedLngsObj.ENGLISH
    if(x?.includes(supportedLngsObj.HINDI)){
        lang= supportedLngsObj.HINDI;
    }
    let data = {
		year_id: year_id,
		month_id: month_id,
		cat_id: [category_id],
        lang:lang
	};
    return (dispatch) =>{
        axios.post(API_URL + 'MediaRalationSearch', data).then((response) =>{
            dispatch(mediaFn(response));
        });
    };
}

const blogFn = (data) =>{
    return{
        type: BLOG,
        payload: data
    };
}

export const blogApi = () =>{
    return (dispatch) =>{
        axios.get(API_URL + 'blog?lang=en').then((response) =>{
            dispatch(blogFn(response));
        });
    };
}
const blogDetailsFn = (data) =>{
    return{
        type: BLOGDETAILS,
        payload: data
    };
}

export const blogDetailsApi = (page_url) =>{
    let data ={
        lang: 'en',
        page_url: page_url
    }
    return (dispatch) =>{
        axios.post(API_URL + 'BlogDetails?',  data).then((response) =>{
            dispatch(blogDetailsFn(response));
        });
    };
}