import React from "react";
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

import logo from '../assets/images/logo.png';

import watapp from '../assets/images/watapp.png';

import chaticon from '../assets/images/chat-icon.png';

import rightIcon from '../assets/images/right_icon_g.png';
import MobileMenu from "./mobileMenu";
import Button from 'react-bootstrap/Button';
import WaveVideo from '../assets/images/ballet.mp4';
import LanguageSwitcher from "./languageSwitcher";
import withPathLanguage from "../hoc/withPathLanguage";
import { withTranslation } from 'react-i18next';
class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollTop: true,
      cement: true,
      rmx: false,
      mbm: false
    };
    this.onScroll = this.onScroll.bind(this);
    this.changeMenu = this.changeMenu.bind(this);
    this.redirectAnnual = this.redirectAnnual.bind(this);
  }
  componentDidMount() {
    new WOW.WOW({
      live: false
    }).init();

    document.addEventListener('scroll', () => {
      const scrollTop = window.scrollY < 28;
      if (scrollTop !== this.state.scrollTop) {
        this.onScroll(scrollTop);
      }
    });

  }

  onScroll(scrollTop) {
    this.setState({ scrollTop });
  }

  changeMenu(varname) {
    switch (varname) {
      case "cement":
        this.setState({ cement: true, rmx: false, mbm: false });
        break;
      case "rmx":
        this.setState({ cement: false, rmx: true, mbm: false });
        break;
      case "mbm":
        this.setState({ cement: false, rmx: false, mbm: true });
        break;
      default: return;
    }
  }
  reditredTo() {
    alert('hi')
  }
  redirectAnnual() {
    window.open('https://annualreport.nuvoco.com', '_blank');
  }
  render() {
    const { cement, rmx, mbm } = this.state;
    const { t } = this.props;
    const openInNewTab = url => {
      // 👇️ setting target to _blank with window.open
      window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
      <Container className="header">
        <Navbar bg="light" expand="lg" className={this.state.scrollTop ? 'up' : 'fixed'}>

          <Navbar.Brand href={`${this.props.pathLanguage || '/' }`} className="wow fadeInDown" data-wow-delay="0.3s">
            <img
              src={logo}
              className="d-inline-block align-top"
              alt="logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" >

              <Nav.Link as={Link} to={`${this.props.pathLanguage}about-us`} className="wow fadeInLeft">
                {t("navigation.aboutus")}
                <ul className="sub_menu about_menu">
                  <li>
                    <Nav.Link as={Link} to={`${this.props.pathLanguage}our-journey`}>{t("navigation.ourjourney")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to="our-brand-story">{t("navigation.ourbrandstory")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to="vision-mission-and-values">{t("navigation.visionmission")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to="board-of-directors">{t("navigation.boardofdirectors")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to="management-team">{t("navigation.managementteam")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to={`${this.props.pathLanguage}quality-and-innovation`}>{t("navigation.qualityinnovation")} <img src={rightIcon} alt='' /></Nav.Link>
                  </li>
                </ul>
              </Nav.Link>

              <Nav.Link className="wow fadeInLeft">
                {t("navigation.ourbusiness")}
                <ul className="sub_menu">
                  <li>
                    <Nav.Link as={Link} to={`${this.props.pathLanguage}top-cement-company`}>{t("navigation.cement")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to={`${this.props.pathLanguage}ready-mix-concrete`}>{t("navigation.readymixconcrete")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to={`${this.props.pathLanguage}modern-building-materials`}>{t("navigation.mbm")} <img src={rightIcon} alt='' /></Nav.Link>
                  </li>
                </ul>
              </Nav.Link>

              <div className="mege_menu product_mm">
                <Nav.Link as={Link} to={`${this.props.pathLanguage}our-brands`} className="wow fadeInLeft">{t("navigation.ourbrands")}</Nav.Link>
                <div className="mm_tb">
                  <div className="d-flex">
                    <ul className="pr_dt">
                      <li onClick={() => this.changeMenu("cement")} className={cement ? 'active' : ''}>{t("navigation.cement")}</li>
                      <li onClick={() => this.changeMenu("rmx")} className={rmx ? 'active' : ''}>{t("navigation.readymixconcrete")}</li>
                      <li onClick={() => this.changeMenu("mbm")} className={mbm ? 'active' : ''}>{t("navigation.mbm")}</li>
                    </ul>
                    <div className="mm_ct">
                      {cement ? <ul>
                        <li>
                          <h3>{t("navigation.concreto")}</h3>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-cement`}>{t("navigation.concreto")}</Nav.Link>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-uno`}>{t("navigation.concretouno")}</Nav.Link>
                          {/*  <Nav.Link as={Link} to="products/concreto-green-cement">Concreto Green</Nav.Link>  */}
                          <h3 className="mt-3">{t("navigation.doublebullcement")}</h3>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/double-bull-master-cement`}>{t("navigation.Master")}</Nav.Link>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/double-bull-ppc`}>{t("navigation.PPC")}</Nav.Link>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/double-bull-psc`}>{t("navigation.PSC")}</Nav.Link>
                          {/* <Nav.Link as={Link} to="products/double-bull-subh-cement">Subh</Nav.Link>                               */}
                        </li>
                        <li>
                          <h3>{t("navigation.Duraguard")}</h3>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/duraguard-cement`}>{t("navigation.Duraguard")}</Nav.Link>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/duraguard-microfiber-cement`}>{t("navigation.Microfiber")}</Nav.Link>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/duraguard-rapidx-cement`}>{t("navigation.RapidX")}</Nav.Link>
                          {/* <Nav.Link as={Link} to={`${this.props.pathLanguage}products/duraguard-waterseal-cement`}>Waterseal</Nav.Link>  */}
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/duraguard-xtra-cement`}>{t("navigation.Xtra")}</Nav.Link>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/duraguard-f2f`}>{t("navigation.F2F")}</Nav.Link>
                          {/* <Nav.Link as={Link} to="products/duraguard-xtra-cement">Xtra Cement</Nav.Link>  */}

                        </li>
                        <li>
                          <h3>{t("navigation.Infracem")}</h3>
                          {/* <Nav.Link as={Link} to="products/double-bull-opc-43">Double Bull - OPC 43</Nav.Link> 
                                <Nav.Link as={Link} to="products/double-bull-opc-53">Double Bull - OPC 53</Nav.Link> */}
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/infracem-cement`}>{t("navigation.Infracem")}</Nav.Link>
                          {/* <Nav.Link as={Link} to="products/double-bull-procem-cement">Double Bull Procem</Nav.Link> */}
                        </li>

                        <li>
                          <h3>{t("navigation.Nirmax")}</h3>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nirmax-ppc`}>{t("navigation.Nirmax")}</Nav.Link>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nirmax-opc`}>{t("navigation.Nirmax43")}</Nav.Link>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nirmax-opc`}>{t("navigation.Nirmax53")}</Nav.Link>

                          <h3 className="mt-3">{t("navigation.PremiumSlag")}</h3>
                          <Nav.Link as={Link} to={`${this.props.pathLanguage}products/p-s-c`}>{t("navigation.Premium_PSC")}</Nav.Link>

                        </li>
                      </ul> : ''}

                      {rmx ?
                        <ul className="mm_rmx">
                          <li>
                            <h3>{t("navigation.Artiste")}</h3>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/artiste-bare`}>{t("navigation.Bare")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/artiste-coarse`}>{t("navigation.Coarse")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/artiste-engrave`}>{t("navigation.Engrave")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/artiste-hue`}>{t("navigation.Hue")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/artiste-lumos`}>{t("navigation.Lumos")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/artiste-signature`}>{t("navigation.Signature")}</Nav.Link>
                          </li>
                          <li>
                            <h3>{t("navigation.concreto")}</h3>
                            <div className="d-flex">
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-agile`}>{t("navigation.Agile")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-agile-flow`}>{t("navigation.AgileFlow")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-corrosafe-plus`}>{t("navigation.Corrosafe")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-cwt-plus`}>{t("navigation.CWTPlus")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-easyfille`}>{t("navigation.Easyfille")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-endura`}>{t("navigation.Endura")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-fluide`}>{t("navigation.Fluide")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-glyde`}>{t("navigation.Glyde")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-instante`}>{t("navigation.Instante")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-lente`}>{t("navigation.Lente")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-permadure`}>{t("navigation.Permadure")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-polibre`}>{t("navigation.Polibre")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-regletherme`}>{t("navigation.Regletherme")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-robuste`}>{t("navigation.Robuste")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-steelibre`}>{t("navigation.Steelibre")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-xlite`}>{t("navigation.Xlite")}</Nav.Link>
                              <Nav.Link as={Link} to={`${this.props.pathLanguage}products/concreto-xlite-structure`}>{t("navigation.XliteStructure")}</Nav.Link>

                            </div>
                          </li>

                          <li>
                            <h3>{t("navigation.Ecodure")}</h3>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/ecodure`}>{t("navigation.Ecodure")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/ecodure-plus`}>{t("navigation.Plus")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/ecodure-prime`}>{t("navigation.Prime")}</Nav.Link>

                            <h3 className="mt-3">{t("navigation.instamix")}</h3>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/instamix`}>{t("navigation.instamix")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/instamix-microne`}>{t("navigation.Microne")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/instamix-mortare`}>{t("navigation.mortane")}</Nav.Link>

                            <h3 className="mt-3">{t("navigation.X_CON")}</h3>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/x-con`}>{t("navigation.X_CON")}</Nav.Link>

                          </li>
                        </ul>

                        : ''}

                      {mbm ?
                        <ul className="mm_rmx">
                          <h3 className="w-100">{t("navigation.ZeroM")}</h3>
                          <li className="z_m" style={{ minWidth: '230px' }}>

                            <h3 className="mt-0 f15">{t("navigation.Waterproofing_Solutions")}</h3>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-iwc`}>{t("navigation.IWC")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-2k`}>{t("navigation.2K")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-multipurpose-latex-expert`}>{t("navigation.Latex_Expert")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-multipurpose-acrylic-expert`}>{t("navigation.Acrylic_Expert")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-roof-shield`}>{t("navigation.Roof_Shield")}</Nav.Link>
                            <h3 className="mt-3 f15">{t("navigation.Repair_Solutions")}</h3>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-tile-cleaner`}>{t("navigation.Tile_Cleaner")}</Nav.Link>
                          </li>
                          <li className="z_m" style={{ minWidth: '230px' }}>
                            <h3 className="mt-0 f15">{t("navigation.Tile_Stone_Fixing_Solutions")}</h3>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-tile-adhesive-t1`}>{t("navigation.Tile_T1")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-tile-adhesive-t2`}>{t("navigation.Tile_T2")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-tile-adhesive-t3`}>{t("navigation.Tile_T3")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-tile-adhesive-t4`}>{t("navigation.Tile_T4")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-tile-adhesive-t5`}>{t("navigation.Tile_T5")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-epoxy-tile-grout`}>{t("navigation.Epoxy_Tile_Grout")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-tile-grout`}>{t("navigation.Tile_Grout")}</Nav.Link>
                          </li>
                          <li className="z_m" style={{ minWidth: '230px' }}>
                            <h3 className="mt-0 f15">{t("navigation.Surface_Preparation")}</h3>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-wall-putty`}>{t("navigation.Wall_Putty")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-dry-plaster`}>{t("navigation.Dry_Plaster")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-dry-concrete`}>{t("navigation.Dry_Concrete")}</Nav.Link>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-block-jointing-mortar`}>{t("navigation.Block_Joining")}</Nav.Link>
                            <h3 className="mt-3 f15">{t("navigation.Ancillary_Solutions")}</h3>
                            <Nav.Link as={Link} to={`${this.props.pathLanguage}products/nuvoco-zero-m-cover-block`}>{t("navigation.Cover_Block")}</Nav.Link>
                          </li>

                        </ul>
                        : ''}
                    </div>
                  </div>
                </div>
              </div>


              <Nav.Link as={Link} to={`${this.props.pathLanguage}sustainability`} className="wow fadeInLeft">
                {t("navigation.Sustainability")}
                <ul className="sub_menu sustainability-menu">
                  <li>
                    <Nav.Link as={Link} to="/environment">{t("navigation.Environment")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to="/social">{t("navigation.Social")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to="/governance">{t("navigation.Governance_ESG")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to={`${this.props.pathLanguage}health-and-safety`}>{t("navigation.Health_Safety")} <img src={rightIcon} alt='' /></Nav.Link>
                  </li>
                </ul>
              </Nav.Link>
              <Nav.Link className="wow fadeInLeft">
                {t("navigation.Investor_Relations")}
                <ul className="sub_menu">
                  <li>
                    <Nav.Link as={Link} to="performance-highlights">{t("navigation.Performance_Highlights")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to="investors-corner">{t("navigation.Investors_Corner")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to="corporate-governance">{t("navigation.Corporate_Governance")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to="annual-reports">{t("navigation.Annual_Reports")} <img src={rightIcon} alt='' /></Nav.Link>
                    <a className="nav-link" onClick={() => this.redirectAnnual()} >{t("navigation.Annual_Report_FY2023_24_Microsite")}</a>
                    {/* <Nav.Link as={Link} to="">Annual Reports Website<img src={rightIcon} alt=''/></Nav.Link>                     */}
                  </li>
                </ul>
              </Nav.Link>
              <Nav.Link as={Link} to={`${this.props.pathLanguage}life-at-nuvoco`} className="wow fadeInLeft">
                {t("navigation.Life_Nuvoco")}
                <ul className="sub_menu">
                  <li>
                    <Nav.Link as={Link} to={{ pathname: "/vision-mission-and-values", hash: "#ourValue" }}>{t("navigation.Our_Values")} <img src={rightIcon} alt='' /></Nav.Link>
                    {/* <Nav.Link as={Link} to={{ pathname: "/life-at-nuvoco", hash: "#ourPeople"}}>{t("navigation.Our_People")} <img src={rightIcon} alt=''/></Nav.Link> */}
                    <Nav.Link as={Link} to={`${this.props.pathLanguage}life-at-nuvoco#ourPeople`}>{t("navigation.Our_People")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to={`${this.props.pathLanguage}our-employee-value-proposition`}>{t("navigation.Our_Employee_Value_Proposition")} <img src={rightIcon} alt='' /></Nav.Link>
                    <Nav.Link as={Link} to={`${this.props.pathLanguage}life-at-nuvoco#awardandrecognition`}>{t("navigation.Awards_Recognition")} <img src={rightIcon} alt='' /></Nav.Link>
                    {/* <Nav.Link as={Link} to={{pathname: "/life-at-nuvoco", hash: "#awardandrecognition"}}>{t("navigation.Awards_Recognition")} <img src={rightIcon} alt=''/></Nav.Link> */}
                    <Nav.Link onClick={() => openInNewTab('https://career44.sapsf.com/career?company=nuvocovist')}>
                      {t("navigation.Join_Our_Team")} <img src={rightIcon} alt='' /></Nav.Link>

                  </li>
                </ul>
              </Nav.Link>
              <Nav.Link as={Link} to={`${this.props.pathLanguage}media/Press-Releases`} className="wow fadeInLeft">{t("navigation.media")}</Nav.Link>
              <Nav.Link as={Link} to={`${this.props.pathLanguage}contact-us`} className="wow fadeInLeft">{t("navigation.Contactus")}</Nav.Link>
              <LanguageSwitcher />
            </Nav>
            <MobileMenu />
          </Navbar.Collapse>
        </Navbar>
        <div className="right_btn wow fadeInRigth">
          <Button variant="danger" as={Link} to="/contact-us">Contact Us</Button> &nbsp;&nbsp;&nbsp;
          <Button variant="success" href="https://nuvonirmaan.com/" target="_blank">Nuvo Nirmaan</Button>
        </div>


        <div className="waapp">

          <a href="https://wa.me/919830017272?text=hi" target="_blank"><img className="imgwa" src={watapp} alt='' /></a>
          {/* <a href="javascript:void(0)" data-ifrmae="https://bot.surbo.io/widget-bot/5ec6b70a4e581e24787a6799?srb_1=&amp;srb_2=&amp;srb_3="><img className="chat_icon" src={chaticon} alt='' /></a>  */}


        </div>
      </Container>

    );
  }
}

export default withTranslation()(withPathLanguage(Header));