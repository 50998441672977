import React from 'react';
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/disclaimerBanner.jpg'
import bannermobile from '../assets/images/disclaimerBannerMobile.jpg'
import aboutBannerLogo from '../assets/images/aboutba-logo.png'
import abouticon1 from '../assets/images/about-icon1.jpg'
import abouticon2 from '../assets/images/abouticon2.jpg'
import abouticon3 from '../assets/images/abouticon3.jpg'
import abouticon4 from '../assets/images/abouticon4.jpg'
import abouticon5 from '../assets/images/abouticon5.jpg'
import buss1 from '../assets/images/business1.jpg'
import buss2 from '../assets/images/business2.jpg'
import buss3 from '../assets/images/business3.jpg'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useTranslation } from 'react-i18next';

const Disclaimer = () => {
  const { t } = useTranslation();
  return (
    <div className="aboutus-pge inner_page">
      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner' src={bannermobile} alt="" />
        <article className='position-absolute text-center'>
          <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
          <h1 className="text-uppercase">{t("disclaimer.title")}</h1>
        </article>
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">{t("breadcrumb.home")}</Breadcrumb.Item>
              <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
              {t("disclaimer.title")}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className='midpart mb-5'>
        <Container>
          <h2 className='grad-heading'>{t("disclaimer.title")}</h2>
          <p className='onetxt'>{t("disclaimer.desc1")}</p>
          <p className='onetxt'>{t("disclaimer.desc2")}</p>
        </Container>
      </div>

    </div>
  )
}

export default Disclaimer