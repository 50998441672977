export const API_URL = "https://testadmin.nuvoco.com/api/";
//export const API_URL = "https://nuvoco.com/Nuvoco/API/index.php";
export const imgUrl = "https://testadmin.nuvoco.com/";

export const TRANSLATED_PATHS = [
    "/",
    "/about-us",
    "/our-journey",
    "/quality-and-innovation",
    "/top-cement-company",
    "/ready-mix-concrete",
    "/modern-building-materials",
    "/our-brands",
    "/products/concreto-cement",
    "/products/concreto-uno",
    "/products/double-bull-master-cement",
    "/products/double-bull-ppc",
    "/products/double-bull-psc",
    "/products/nirmax-opc",
    "/products/p-s-c",
    "/products/duraguard-cement",
    "/products/duraguard-microfiber-cement",
    "/products/duraguard-rapidx-cement",
    "/products/duraguard-xtra-f2f",
    "/products/duraguard-xtra-cement",
    "/products/infracem-cement",
    "/products/artiste-bare",
    "/products/artiste-coarse",
    "/products/artiste-engrave",
    "/products/artiste-hue",
    "/products/artiste-lumos",
    "/products/ecodure",
    "/products/ecodure-plus",
    "/products/ecodure-prime",
    "/products/x-con",
    "/products/concreto-agile",
    "/products/concreto-agile-flow",
    "/products/concreto-corrosafe-plus",
    "/products/concreto-cwt-plus",
    "/products/concreto-easyfille",
    "/products/concreto-endura",
    "/products/concreto-fluide",
    "/products/concreto-glyde",
    "/products/concreto-instante",
    "/products/concreto-lente",
    "/products/concreto-permadure",
    "/products/concreto-polibre",
    "/products/concreto-regletherme",
    "/products/concreto-robuste",
    "/products/concreto-steelibre",
    "/products/concreto-xlite",
    "/products/concreto-xlite-structure",
    "/products/instamix",
    "/products/instamix-microne",
    "/products/instamix-mortare",
    "/products/artiste-signature",
    "/products/nuvoco-zero-m-iwc",
    "/products/nuvoco-zero-m-2k",
    "/products/nuvoco-zero-m-multipurpose-latex-expert",
    "/products/nuvoco-zero-m-multipurpose-acrylic-expert",
    "/products/nuvoco-zero-m-tile-cleaner",
    "/products/nuvoco-zero-m-wall-putty",
    "/products/nuvoco-zero-m-dry-plaster",
    "/products/nuvoco-zero-m-dry-concrete",
    "/products/nuvoco-zero-m-block-jointing-mortar",
    "/products/nuvoco-zero-m-cover-block",
    "/products/nuvoco-zero-m-tile-adhesive-t1",
    "/products/nuvoco-zero-m-tile-adhesive-t2",
    "/products/nuvoco-zero-m-tile-adhesive-t3",
    "/products/nuvoco-zero-m-tile-adhesive-t4",
    "/products/nuvoco-zero-m-tile-adhesive-t5",
    "/products/nuvoco-zero-m-epoxy-tile-grout",
    "/products/nuvoco-zero-m-tile-grout",
    "/sustainability",
    "/health-and-safety",
    "/life-at-nuvoco",
    "/our-employee-value-proposition",
    "/life-at-nuvoco#awardandrecognition",
    "/media/Press-Releases",
    "/contact-us",
    // "/disclaimer",
    "/hi/",
    "/hi/about-us",
    "/hi/our-journey",
    "/hi/quality-and-innovation", 
    "/hi/top-cement-company",
    "/hi/ready-mix-concrete",
    "/hi/modern-building-materials",
    "/hi/our-brands",
    "/hi/products/concreto-cement",
    "/hi/products/concreto-uno",
    "/hi/products/double-bull-master-cement",
    "/hi/products/double-bull-ppc", 
    "/hi/products/double-bull-psc",
    "/hi/products/nirmax-opc",
    "/hi/products/p-s-c",
    "/hi/products/duraguard-cement",
    "/hi/products/duraguard-microfiber-cement",
    "/hi/products/duraguard-rapidx-cement",
    "/hi/products/duraguard-xtra-f2f",
    "/hi/products/duraguard-xtra-cement",
    "/hi/products/infracem-cement",
    "/hi/products/artiste-bare",
    "/hi/products/artiste-coarse",
    "/hi/products/artiste-engrave",
    "/hi/products/artiste-hue",
    "/hi/products/artiste-lumos",
    "/hi/products/ecodure",
    "/hi/products/ecodure-plus",
    "/hi/products/ecodure-prime",
    "/hi/products/x-con",
    "/hi/products/concreto-agile",
    "/hi/products/concreto-agile-flow",
    "/hi/products/concreto-corrosafe-plus",
    "/hi/products/concreto-cwt-plus",
    "/hi/products/concreto-easyfille",
    "/hi/products/concreto-endura",
    "/hi/products/concreto-fluide",
    "/hi/products/concreto-glyde",
    "/hi/products/concreto-instante",
    "/hi/products/concreto-lente",
    "/hi/products/concreto-permadure",
    "/hi/products/concreto-polibre",
    "/hi/products/concreto-regletherme",
    "/hi/products/concreto-robuste",
    "/hi/products/concreto-steelibre",
    "/hi/products/concreto-xlite",
    "/hi/products/concreto-xlite-structure",
    "/hi/products/instamix",
    "/hi/products/instamix-microne",
    "/hi/products/instamix-mortare",
    "/hi/products/artiste-signature",
    "/hi/products/nuvoco-zero-m-iwc",
    "/hi/products/nuvoco-zero-m-2k",
    "/hi/products/nuvoco-zero-m-multipurpose-latex-expert",
    "/hi/products/nuvoco-zero-m-multipurpose-acrylic-expert",
    "/hi/products/nuvoco-zero-m-tile-cleaner",
    "/hi/products/nuvoco-zero-m-wall-putty",
    "/hi/products/nuvoco-zero-m-dry-plaster",
    "/hi/products/nuvoco-zero-m-dry-concrete",
    "/hi/products/nuvoco-zero-m-block-jointing-mortar",
    "/hi/products/nuvoco-zero-m-cover-block",
    "/hi/products/nuvoco-zero-m-tile-adhesive-t1",
    "/hi/products/nuvoco-zero-m-tile-adhesive-t2",
    "/hi/products/nuvoco-zero-m-tile-adhesive-t3",
    "/hi/products/nuvoco-zero-m-tile-adhesive-t4",
    "/hi/products/nuvoco-zero-m-tile-adhesive-t5",
    "/hi/products/nuvoco-zero-m-epoxy-tile-grout",
    "/hi/products/nuvoco-zero-m-tile-grout",
    "/hi/sustainability",
    "/hi/health-and-safety",
    "/hi/life-at-nuvoco",
    "/hi/our-employee-value-proposition",
    "/hi/life-at-nuvoco#awardandrecognition",
    "/hi/media/Press-Releases",
    "/hi/contact-us",
    // "/hi/disclaimer",
];
