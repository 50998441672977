import React from 'react';
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/journey-banner.png'
import bannermobile from '../assets/images/journey-banner-mobile.jpg'
import aboutBannerLogo from '../assets/images/journeyba-logo.png'
import icon1 from '../assets/images/jr1.png'
import icon2 from '../assets/images/jr2.png'
import icon3 from '../assets/images/jr3.png'
import icon4 from '../assets/images/jr4.png'
import icon5 from '../assets/images/jr5.png'
import icon6 from '../assets/images/jr6.png'
import icon7 from '../assets/images/jr7.png'
import icon8 from '../assets/images/jr8.png'
import icon9 from '../assets/images/jr9.png'
import icon10 from '../assets/images/jr10.png'
import icon11 from '../assets/images/jr11.png'
import icon12 from '../assets/images/jr12.png'
import { Helmet } from 'react-helmet';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useTranslation } from 'react-i18next';


const OurJourney = () => {
    const { t } = useTranslation();
    return (
        <div className="journey-page">

<Helmet>
    <title>Our Journey | Nuvoco Vistas</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Here is a small glimpse of the Journey of Nuvoco Vistas the milestones which was acheived during the last few decades that empower the overall cement and concrete industry" />
    <link rel="canonical" href="https://nuvoco.com/our-journey"/>
    </Helmet>

            <div className="banner text-center position-relative">
                
                <img className='desktopbannerinner' src={banner} alt="" />
               <img className='mobilebannerinner' src={bannermobile} alt="" />

                <article className='position-absolute text-center'>
                    <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                    <h1 className="text-uppercase">{t("our_journey.title")}</h1>
                </article>
                <div className='breadouter'>
                    <div className='container'>
                        <Breadcrumb className='mb-0 py-2'>
                            <Breadcrumb.Item href="#">{t("breadcrumb.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                            {t("our_journey.title")}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <Container>
                <div className='midbx midboxRes my-5'>
                    <div className='bxin bxin-one d-flex align-items-center ourja_22' style={{minHeight:"unset"}}>
                       
                        <div className='rpart d-flex align-items-center justify-content-end flex-row-reverse'>
                        <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon1} alt="" />
                            </div>
                            <ul>
                                <li>Upgradation of Bhiwani Blending
                                    Unit to a Griding Unit</li>

                                <li> Sustainability projects at Risda
                                    & Nimbol Cement Plants</li>

                                <li>Green field expansion of Gulbarga</li>
                            </ul>
                        </div>
                        <div className='lpart'>
                            <h2 className='grad-heading'>2022</h2>
                            
                        </div>
                    </div>

                    {/* *************************************** */}

                    <div className='bxin txt24 bxin-newfour d-flex align-items-center flex-row-reverse'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>{t("our_journey.txt2024.title")}</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end flex-row-reverse'>
                            <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon12} alt="" />
                            </div>
                            <ul>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2024.txt1") } }></li>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2024.txt2") } }></li>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2024.txt3") } }></li>
                            </ul>
                        </div>
                    </div>
                    <div className='bxin bxin-two mt-minus50 d-flex align-items-center'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>{t("our_journey.txt2023.title")}</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end'>
                            <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon11} alt="" />
                            </div>
                            <ul>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2023.txt1") } }></li>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2023.txt2") } }></li>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2023.txt3") } }></li>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2023.txt4") } }></li>
                            </ul>
                        </div>
                    </div>
                    <div className='bxin bxin-three txt22 mt-minus30 d-flex align-items-center flex-row-reverse'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>{t("our_journey.txt2022.title")}</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end flex-row-reverse'>
                            <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon10} alt="" />
                            </div>
                            <ul>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2022.txt1") } }></li>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2022.txt2") } }></li>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2022.txt3") } }></li>
                            </ul>
                        </div>
                    </div>
            
                    {/* *************************************** */}
                    <div className='bxin bxin-two mt-minus30 txt21 d-flex align-items-center'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>{t("our_journey.txt2021.title")}</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end'>
                            <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon2} alt="" />
                            </div>
                            <ul>
                                <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2021.txt1") } }></li>

                                <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2021.txt2") } }></li>
                            </ul>
                        </div>
                    </div>





                    <div className='bxin bxin-three mt-minus30 txt20 d-flex align-items-center flex-row-reverse'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>{t("our_journey.txt2020.title")}</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end flex-row-reverse'>
                            <div className='imgbx d-flex align-items-center justify-content-center'>
                            <img src={icon5} alt="" />
                            </div>
                            <ul>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2020.txt1") } }></li>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2020.txt2") } }></li>
                            <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2020.txt3") } }></li>
                            </ul>
                        </div>
                    </div>


                  




                    <div className='bxin bxin-two mt-minus60 txt19 bxin-four d-flex align-items-center'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>{t("our_journey.txt2019.title")}</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end'>
                       <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon4} alt="" />
                            </div>
                            <ul>
                                <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2019.txt1") } }></li>
                            </ul>
                        </div>
                    </div>

                    <div className='bxin bxin-three bxin-five txt17 mt-minus50 d-flex align-items-center flex-row-reverse'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>{t("our_journey.txt2017.title")}</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end flex-row-reverse'>
                            <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon8} alt="" />
                            </div>
                            <ul>
                                <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2017.txt1") } }></li>
                            </ul>
                        </div>
                    </div>

                    <div className='bxin bxin-two bxin-six mt-minus50 d-flex align-items-center'>
                    <div className='lpart'>
                        <h2 className='grad-heading'>{t("our_journey.txt2016.title")}</h2>
                        </div>
                        
                        <div className='rpart d-flex align-items-center justify-content-end'>
                        <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon9} alt="" />
                            </div>
                            <ul>
                                <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2016.txt1") } }></li>
                            </ul>
                        </div>
                       
                    </div>


                    <div className='bxin bxin-three bxin-seven txt14 mt-minus60 d-flex align-items-center flex-row-reverse'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>{t("our_journey.txt2014.title")}</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end flex-row-reverse'>
                            <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon7} alt="" />
                            </div>
                            <ul>
                                <li dangerouslySetInnerHTML={ { __html: t("our_journey.txt2014.txt1") } }></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container >
        </div >
    )
}

export default OurJourney