import React from "react";
import footerBg from '../assets/images/footer.jpg';
import footermobileBg from '../assets/images/footermobileBg.jpg';
import footerLogo from '../assets/images/footer_logo.gif';
import fakeEmployement from '../assets/images/fake-employement.jpg';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";

import fb from '../assets/images/fb.png';
import tw from '../assets/images/tw.png';
import lin from '../assets/images/in.png';
import ig from '../assets/images/ig.png';
import yt from '../assets/images/yt.png';

import fb1 from '../assets/images/fb1.png';
import tw1 from '../assets/images/tw1.png';
import lin1 from '../assets/images/in1.png';
import ig1 from '../assets/images/ig1.png';
import yt1 from '../assets/images/yt1.png';
import  withPathLanguage  from "../hoc/withPathLanguage";
import { withTranslation } from 'react-i18next';

class Footer extends React.Component{
    render(){
        const { t } = this.props;
        return(
            <footer>
                <img className="footerbgdesktop" src={footerBg} alt='' />
                <img className="footerbgmobile" src={footermobileBg} alt='' />
                <Container>
                    <div className="footer_bottom">
                        <Row>
                            <Col sm={4}>
                                <ul>
                                    <li><Link as={Link} to={`${this.props.pathLanguage}about-us`}>{t("navigation.aboutus")}</Link></li>
                                    <li><Link to="/investors-corner">{t("navigation.Investor_Relations")}</Link></li>
                                    <li><a href="https://nuvocohomeassist.com/" target="_blank">{t("navigation.Home_Assist")}</a></li>
                                </ul>
                            </Col>
                            <Col sm={4}>
                                <ul>
                                    <li><Link as={Link} to={`${this.props.pathLanguage}top-cement-company`}>{t("navigation.cement")}</Link></li>
                                    <li><Link as={Link} to={`${this.props.pathLanguage}ready-mix-concrete`}>{t("navigation.readymixconcrete")}</Link></li>
                                    <li><Link as={Link} to={`${this.props.pathLanguage}modern-building-materials`}>{t("f_MBM_Full")}</Link></li>
                                </ul>
                            </Col>
                            <Col sm={4}>
                                <ul>
                                    <li><Link to={`${this.props.pathLanguage}sustainability`}>{t("navigation.Sustainability")}</Link></li>
                                    <li><Link to={`${this.props.pathLanguage}ready-mix-concrete#landmark`}>{t("home.landmark_projects.title")}</Link></li>
                                    <li><Link to={`${this.props.pathLanguage}life-at-nuvoco`}>{t("navigation.Life_Nuvoco")}</Link></li>
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 9, offset: 3 }} >
                                <div>
                                <Link to={`${this.props.pathLanguage}life-at-nuvoco#fake-employment-disclaimer`}><img  style={{borderRadius:'10px'}} src={fakeEmployement} /></Link>
                                </div>
                            </Col>
                        </Row> 
                        <div className="d-flex copyright">
                            <img src={footerLogo} alt='' />
                            <div>
                                <p>{t("copyright")} <Link to="disclaimer">{t("f_disclaimer")}.</Link> <a href="https://nuvoco.com/Final_RMX_Standard_Draft_3rd_Nov_2021.pdf" rel="noreferrer" target='_blank'><b><u>{t("f_rmx_suppy")}.</u></b></a></p>
                                                                
                            </div>
                            <ul className="d-flex">
                                    <li><a href="https://www.facebook.com/NuvocoVistas/" rel="noreferrer" target='_blank'><img className="in" src={fb} alt='' /><img className="out" src={fb1} alt='' /></a></li>
                                    <li><a href="https://twitter.com/nuvocovistas"  rel="noreferrer" target='_blank'><img className="in" src={tw} alt='' /><img className="out" src={tw1} alt='' /></a></li>
                                    <li><a href="https://in.linkedin.com/company/nuvocovistas"  rel="noreferrer" target='_blank'><img className="in" src={lin} alt='' /><img className="out" src={lin1} alt='' /></a></li>
                                    <li><a href="https://www.instagram.com/nuvoco_vistas_corp.ltd?igsh=azAwMzAwcWRob3hw"  rel="noreferrer" target='_blank'><img className="in" src={ig} alt='' /><img className="out" src={ig1} alt='' /></a></li>
                                    <li><a href="https://www.youtube.com/@Nuvocovistas-official"  rel="noreferrer" target='_blank'><img className="in" src={yt} alt='' /><img className="out" src={yt1} alt='' /></a></li>
                                </ul>
                        </div>
                        <div className="bis_web">
                            <p dangerouslySetInnerHTML={ { __html: t("footerInfo") } }></p>
                            <p><a href="https://smartodr.in/login" rel="noreferrer" target='_blank'><b><u>{t("footerInfo1")}</u></b></a></p>
                            {/* <p><Link to="life-at-nuvoco#fake-employment-disclaimer"><b><u>Fake Employment Disclaimer</u></b></Link></p> */}
                        </div>
                        
                    </div>
                </Container>

                
             
            </footer>
        )
    }
}

export default withTranslation() (withPathLanguage(Footer));