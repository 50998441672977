import React from "react";
import Slider from "react-slick";
import {Card} from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const settings = {
    autoplay:true,
    autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    mode:'center',

 responsive: [
    {
        breakpoint: 991,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
        },
    },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
  };
  
const BenefitSlider = ({imgList, titleList, imgUrl}) =>{
    const { t } = useTranslation();
    var imgList1 = JSON.parse(imgList);
    var titleList1 = JSON.parse(titleList);
    return(
        <div className="benefits">
            <h2 className='grad-heading'>{t("our_brands.Benefits")}</h2>
            <div className="slide_cta center_slide card_round">
                <Slider {...settings}>
                    {imgList1.map((prev, i) => {
                        return(
                            <div key={i} >
                            <Card>
                                <Card.Img variant="top" src={imgUrl + prev} />
                                <Card.Body>
                                    <Card.Title>
                                        {titleList1[i]}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </div>  
                        );
                    })}                
                </Slider>
            </div>
        </div>
    )
}

export default BenefitSlider;