import React, {useState, useEffect} from "react";
import {Modal,Button} from 'react-bootstrap';
import Slider from "react-slick";
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/our-cement-banner.png'
import bannermobile from '../assets/images/our-cement-banner-mobile.jpg';
import aboutBannerLogo from '../assets/images/our-cement-icon.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import stand1icon from '../assets/images/stand2icon.png'
import stand2icon from '../assets/images/stand3icon.png'
import stand3icon from '../assets/images/stand1icon.png'
import standcement from '../assets/images/standcement.png'
import cementLogo from '../assets/images/cement_logo.svg';
import dcement from '../assets/images/dcement.png';
import dbull from '../assets/images/dbull.png';
import nirmex from '../assets/images/nirmex.png';
import psc from '../assets/images/psc.png';
import infracem from '../assets/images/infracem.png';
import concreto01 from '../assets/images/concreto.jpg'
import Infracem50kg from '../assets/images/Infracem50kg.png';
import ppcpscprocem from '../assets/images/ppcpsc-procem.png';
import ppc43 from '../assets/images/ppc43.png';
import ppc53 from '../assets/images/ppc43.png';

import green from '../assets/images/green.jpg'
import duraguard50kg from '../assets/images/duraguard50kg.png';
import duraguardmicrofibre50kg from '../assets/images/duraguardmicro-fibre-50kg.png';
import duraguardrapidx50kg53 from '../assets/images/duraguard-rapidx50kg-53.png';
import duraguardxtra50kg from '../assets/images/duraguard-xtra-50kg.png';
import duraguardwaterseal50kg from '../assets/images/duraguard-waterseal-50kg.png';
import duraguardsilver50kg from '../assets/images/duraguard-silver-50kg.png';
import masterpremiumcement from '../assets/images/masterpremiumcement.png';
import DuraguardSilver from '../assets/images/duraguard-silver.png';
import concretoUno from '../assets/images/Concreto-uno-50kg.png';


import ppcbag from '../assets/images/ppcbag.png';
import pscbag from '../assets/images/pscbag.png';
import subhpremiumpsc from '../assets/images/subhpremiumpsc.png';
import pscadstarbags from '../assets/images/pscadstarbags.png';
import nirmax50kg from '../assets/images/nirmax50kg.png';
import nirmaxbags01 from '../assets/images/nirmaxbags01.png';
import { cementApi } from '../redux';
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom";
import { supportedLngsObj } from "../helpers/utils";
import { useTranslation } from "react-i18next";
import { usePathLanguage } from "../hooks/usePathLanguage";

const settings = {
  //centerMode: true,
  //autoplay:true,
  autoplay:true,
  autoplaySpeed: 2000,
  dots: false,
  arrow:true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
          },
      },
  ],
};


const OurCement = ({cementApi, cement}) =>{
    const {t} = useTranslation();
    const location = useLocation();
    const pathLanguage = usePathLanguage();
    useEffect(() => {
        var x = location.pathname.split("/");
        if(x?.includes(supportedLngsObj.HINDI)){
            cementApi('product-cement-portfolio',supportedLngsObj.HINDI);        
        }else{
            cementApi('product-cement-portfolio',supportedLngsObj.ENGLISH);
        }        
    }, []);

    console.log(cement)
    if (Object.keys(cement).length) {
		var data = { ...cement.data };
        console.log(data)
	}

  const [one, setOne] = useState(false);
    const closeOne = () => setOne(false);

    const [two, setTwo] = useState(false);
    const closeTwo = () => setTwo(false);

    const [three, setThree] = useState(false);
    const closeThree = () => setThree(false);

    const [four, setFour] = useState(false);
    const closeFour = () => setFour(false);

    const [five, setFive] = useState(false);
    const closeFive = () => setFive(false);

    const [six, setSix] = useState(false);
    const closeSix = () => setSix(false);

    const showComponent=((varname) =>{    
      switch (varname) {
        case "one":
          setOne(true);
          break;
          case "two":
          setTwo(true);
          break;
          case "three":
              setThree(true);
              break;
              case "four":
              setFour(true);
              break;
              case "five":
              setFive(true);
              break;
              case "six":
              setSix(true);
              break;
                            
        default: return;
      }
    })

    return(
        <div className="aboutus-pge inner_page">
    <Helmet>
     <title>Top Cement Companies in India | Nuvoco Cement</title>
     <meta name="keywords" description="" />
     <meta name="description" content="Build your home with India's best cement manufacturing company, It offers widest quality of cement products like concreto,Nirmax, duraguard, double bull cement & infracem" />
     <link rel="canonical" href="https://nuvoco.com/top-cement-company"/>
    </Helmet>
        <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
               <img className='mobilebannerinner' src={bannermobile} alt="" />
          <article className='position-absolute text-center'>
            <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
            <h1 className="text-uppercase">{t("our_business.cement.bannertitle")}</h1>
          </article>
          <div className='breadouter'>
            <div className='container'>
              <Breadcrumb className='mb-0 py-2'>
                <Breadcrumb.Item href="#">{t("breadcrumb.home")}</Breadcrumb.Item>
                <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                {t("breadcrumb.cement")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div className='midpart'>
          <Container>
            <h2 className='grad-heading'>{t("our_business.cement.title")}</h2>
          <p>{t("our_business.cement.para1")}</p>
          <p>{t("our_business.cement.para2")}</p>
         <p>{t("our_business.cement.para3")}</p>
        <p>{t("our_business.cement.para4")}</p>
           
            <div className="vimi-page mt-5">
            <h2 className='grad-heading'>{t("our_business.cement.title2")}</h2>
            <Row className='topbx stand-page my-5'>
            <div className="vimiicon pb-2 d-flex justify-content-center">
              <div className="imgbx d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <div className='imgout'>
                    <Card.Img variant="top" src={stand1icon} />
                  </div>
                  <Card.Body>
                    
                    <Card.Text>
                    {t("our_business.cement.innovation")}
                    </Card.Text>
                  </Card.Body>
                </div>
              </div>
              <div className="imgbx d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <div className='imgout'>
                    <Card.Img variant="top" src={stand2icon} />
                  </div>
                  <Card.Body>
                    
                    <Card.Text>
                    {t("our_business.cement.quality_raw")}

                    </Card.Text>
                  </Card.Body>
                </div>
              </div>
              <div className="imgbx d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <div className='imgout'>
                    <Card.Img variant="top" src={standcement} />
                  </div>
                  <Card.Body>
                   
                    <Card.Text>
                    {t("our_business.cement.bis_standards")}
                    </Card.Text>
                  </Card.Body>
                </div>
              </div>
              </div>
            </Row>
        </div>
        <div className="our_brand_inner">
            <div className="card_round pt-5">
                <Container>
                    <h2 className='grad-heading pd-5 mb-5'>{t("our_business.cement.ourBrands.title")}</h2>
                    <Row className="pb-4 cursorPointer">
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('one')}>
                            <Card.Img src={cementLogo} />
                                <Card.Body>
                                    <article>{t("our_business.cement.ourBrands.description")}
                                    {/* Nuvoco’s premium concreto range offers products that are carefully created after incorporating the latest research and development specializing in low water consumption and other eco-friendly variants. Concreto is a frontrunner in Eastern India and has a specialized variant for Northern India. */}
                                    </article>
                                    <Card.Title className="g_invert">
                                    {t("our_business.cement.ourBrands.Concreto")}
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                        
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('two')}>
                            <Card.Img src={dcement} />
                                <Card.Body>
                                    <article>
                                    {t("our_business.cement.ourBrands.duraguard_descritpion")}
                                    </article>
                                    <Card.Title className="g_invert">
                                    {t("our_business.cement.ourBrands.Duraguard")}
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('three')}>
                            <Card.Img src={dbull} />
                                <Card.Body>
                                    <article>{t("our_business.cement.ourBrands.doublebull_description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_business.cement.ourBrands.doublebull")}
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('four')}>
                            <Card.Img src={psc} />
                                <Card.Body>
                                    <article>{t("our_business.cement.ourBrands.psc_description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_business.cement.ourBrands.PSC")}
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                        
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('five')}>
                            <Card.Img src={nirmex} />
                                <Card.Body>
                                    <article>{t("our_business.cement.ourBrands.nirmax_description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_business.cement.ourBrands.Nirmax")}
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('six')}>
                            <Card.Img src={infracem} />
                                <Card.Body>
                                    <article>{t("our_business.cement.ourBrands.infracem_description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_business.cement.ourBrands.Infracem")}
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                   
            </Container >
            </div>
        </div>
          </Container>
        </div>

    <Modal  size="lg" centered="true"
        show={one} onHide={closeOne} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
        <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>{t("our_business.cement.ourBrands.title")}</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center">
                        <img src={cementLogo} alt='' />
                        <article className="p-4">
                        {t("our_business.cement.ourBrands.description")}
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concreto01} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.Concreto")}
                            </Card.Title>  
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-cement`}>{t("home.read_more")} </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoUno} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.Concretouno")}
                            </Card.Title>
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-uno`}>{t("home.read_more")} </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    
                </Col>
                

                </Slider> 
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={two} onHide={closeTwo} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>{t("our_business.cement.ourBrands.title")}</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center logospacepop">
                        <img src={dcement} alt='' />
                        <article className="p-4">
                        {t("our_business.cement.ourBrands.duraguard_descritpion")}
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

 <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                <Col md={4}>
                    <Card>
                        <Card.Img src={duraguard50kg} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.Duraguard")}
                            </Card.Title>  
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/duraguard-cement`}>{t("home.read_more")} </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={duraguardmicrofibre50kg} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.Microfiber")}
                            </Card.Title>  
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/duraguard-microfiber-cement`}>{t("home.read_more")} </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={duraguardrapidx50kg53} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.RapidX")}
                            </Card.Title>  
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/duraguard-rapidx-cement`}>{t("home.read_more")} </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={duraguardxtra50kg} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.Xtra")}
                            </Card.Title>  
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/duraguard-xtra-cement`}>{t("home.read_more")} </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                {/* <Col md={4}>
                    <Card>
                        <Card.Img src={duraguardwaterseal50kg} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.Waterseal_Cement")}
                            </Card.Title>  
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/duraguard-waterseal-cement`}>{t("home.read_more")} </Button> 
                        </Card.Body>
                    </Card>
                </Col> */}
                <Col md={4}>
                    <Card>
                        <Card.Img src={DuraguardSilver} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.F2F")}
                            </Card.Title>  
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/duraguard-f2f`}>{t("home.read_more")} </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                

                </Slider> 
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={three} onHide={closeThree} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>{t("our_business.cement.ourBrands.title")}</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center logospacepop">
                        <img src={dbull} alt='' />
                        <article className="p-4">
                            {t("our_business.cement.ourBrands.doublebull_description")}
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                <Col md={4}>
                    <Card>
                        <Card.Img src={masterpremiumcement} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.MasterCement")}
                            </Card.Title>  
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/double-bull-master-cement`}>{t("home.read_more")} </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={ppcbag} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.PPC")}
                            </Card.Title>  
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/double-bull-ppc`}>{t("home.read_more")} </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={pscbag} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.PSC")}
                            </Card.Title>  
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/double-bull-psc`}>{t("home.read_more")} </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                {/* <Col md={4}>
                    <Card>
                        <Card.Img src={subhpremiumpsc} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Subh Cement
                            </Card.Title>  
                            <Button variant="success" className="read_more" >{t("home.read_more")} </Button> 
                          
                        </Card.Body>
                    </Card>
                </Col> */}

                </Slider> 
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={four} onHide={closeFour} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>{t("our_business.cement.ourBrands.title")}</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center logospacepop">
                        <img src={psc} alt='' />
                        <article className="p-4">{t("our_business.cement.ourBrands.psc_description")}</article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                <Col md={4}>
                    <Card>
                        <Card.Img src={pscadstarbags} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_business.cement.ourBrands.PSC")}
                            </Card.Title>  
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/p-s-c`}>{t("home.read_more")}</Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
               
                </Col>
                <Col md={4}>
              
                </Col>
                

                </Slider> 
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={five} onHide={closeFive} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>{t("our_business.cement.ourBrands.title")}</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center logospacepop">
                        <img src={nirmex} alt='' />
                        <article className="p-4">{t("our_business.cement.ourBrands.nirmax_description")}</article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={nirmax50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_business.cement.ourBrands.Nirmax")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nirmax-ppc`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={nirmaxbags01} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_business.cement.ourBrands.Nirmax")} 43/53
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nirmax-opc`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>

                                </Col>


                            </Slider>
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={six} onHide={closeSix} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>{t("our_business.cement.ourBrands.title")}</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center">
                        <img src={infracem} alt='' />
                        <article className="p-4">{t("our_business.cement.ourBrands.infracem_description")}</article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={Infracem50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_business.cement.ourBrands.Infracem")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/infracem-cement`}>{t("home.read_more")}</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                {/* <Card>
                                        <Card.Img src={ppcpscprocem} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Procem
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-procem-cement">Read More </Button>
                                        </Card.Body>
                                    </Card> */}
                                </Col>
                                <Col md={4}>
                                {/* <Card>
                                        <Card.Img src={ppc43} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Cement OPC 43
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-opc-43">Read More </Button>
                                        </Card.Body>
                                    </Card> */}
                                </Col>
                                {/* <Col md={4}>
                                <Card>
                                        <Card.Img src={ppc53} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Cement OPC 53
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-opc-53">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col> */}

                            </Slider>
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

      </div >
    )
}

const mapStatetoProps = (state) => {
	return {
		cement: state.cementR.cement,
	};
};
const mapDispatchtoProps = (dispatch) => {
	return {
		cementApi: function (page_url,lng) {
			dispatch(cementApi(page_url,lng));
		},
	};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(OurCement);