import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { supportedLngsObj } from "../helpers/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { TRANSLATED_PATHS } from "../redux/config";
import LanguageDisclaimer from "../components/languageDisclaimer";

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const location = useLocation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (lng) => {
    if (TRANSLATED_PATHS.indexOf(location.pathname) == -1) {
      setModalOpen(true);
      return;
    }
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
    if (lng === supportedLngsObj.HINDI) {
      if (
        !window.location.href
          .split(window.location.origin)[1]
          .startsWith("/hi/")
      ) {
        navigate("/hi" + window.location.href.split(window.location.origin)[1]);
      }
    }
    if (lng === supportedLngsObj.ENGLISH) {
      if (
        window.location.href.split(window.location.origin)[1].startsWith("/hi/")
      ) {
        navigate(
          window.location.href.split(window.location.origin)[1].substring(3),
        );
      } else {
        navigate(window.location.href.split(window.location.origin)[1]);
      }
    }
  };

  // if (TRANSLATED_PATHS.indexOf(location.pathname) != -1) {
    return (
      <div className="d-flex justify-center align-center langBox">
        <LanguageDisclaimer
        modalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
        }}
      />
        <select
          name="language"
          id="language-selector"
          onChange={(e) => {
            changeLanguage(e.target.value);
          }}
          value={currentLanguage}
        >
          <option value="en">English</option>
          <option value="hi">हिंदी</option>
        </select>
      </div>
    );
  // }
  return null;
}

export default LanguageSwitcher;
