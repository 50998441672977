import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { supportedLngs, supportedLngsObj } from "../helpers/utils";
import LanguageDisclaimer from "./../components/languageDisclaimer";
import { TRANSLATED_PATHS } from "../redux/config";

function LanguageHandler() {
  const { lng } = useParams();
  const { i18n } = useTranslation();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (i18n.language === supportedLngsObj.HINDI && TRANSLATED_PATHS.indexOf(location.pathname) == -1) {
      setModalOpen(true);
      // setTimeout(() => {
      //   setModalOpen(false);
      // }, 3000);
    }
  }, [lng, i18n,location]);

  useEffect(() => {
    if (lng && supportedLngs.includes(lng)) {
      i18n.changeLanguage(lng); // Change language based on URL parameter
    } else if (!lng) {
      i18n.changeLanguage(supportedLngsObj.ENGLISH); // Change language based on URL parameter
    }
  }, [lng, i18n]);

  return (
    <div>
      <LanguageDisclaimer
        modalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
        }}
      />
      <Outlet />
    </div>
  );
}

export default LanguageHandler;
