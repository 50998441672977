import React, { useEffect } from "react";
import { Breadcrumb } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const TopBanner = ({ title, bannerImg, categoryName }) => {
    const { t } = useTranslation();
    const getCategoryName = () => {
        if (categoryName == "1") {
            return t("navigation.mbm");
        }
        else if (categoryName == "2") {
            return t("navigation.cement");
        }
        else if (categoryName == "3") {
            return t("navigation.readymixconcrete");
        }
        else {
            return t("navigation.cement");
        }
    }

    return (
        <div className="banner text-center position-relative">
            <img src={bannerImg} alt="" />
            <div className='breadouter'>
                <div className='container'>
                    <Breadcrumb className='mb-0 py-2'>
                        <Breadcrumb.Item href="/">{t("breadcrumb.home")}</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">{getCategoryName()}</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {title}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </div>
    )
}

export default TopBanner;