import React, { useEffect } from "react";
import { Container, Row, Col, Card, Breadcrumb, Button, Modal } from 'react-bootstrap';

import banner from '../assets/images/susBanner.jpg'
import bannermobile from '../assets/images/susbanner-mobile.jpg'
import aboutBannerLogo from '../assets/images/susicon.png'
import st01 from '../assets/images/environment-home.jpg';
import st02 from '../assets/images/governance-home.jpg';
import st03 from '../assets/images/social-home.jpg';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Sustainability = () => {
  const { t } = useTranslation();
  return (
    <div className="SustainabilityPage inner_page">
      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner' src={bannermobile} alt="" />
        <article className='position-absolute text-center'>
          <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
          <h1 className="text-uppercase">{t("navigation.Sustainability")}</h1>
        </article>
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">{t("breadcrumb.home")}</Breadcrumb.Item>
              <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                {t("navigation.Sustainability")}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className='midpart'>
        <Container>
          <h2 className='grad-heading'>{t("sustainability.title")}</h2>
          <p dangerouslySetInnerHTML={ { __html: t("sustainability.desc1") } }></p>
          <p dangerouslySetInnerHTML={ { __html: t("sustainability.desc2") } }></p>
          <p dangerouslySetInnerHTML={ { __html: t("sustainability.desc3") } }></p>
          <p dangerouslySetInnerHTML={ { __html: t("sustainability.desc4") } }></p>
          <p dangerouslySetInnerHTML={ { __html: t("sustainability.desc5") } }></p>
          

        </Container>
      </div>

      <div className="sustainability card_round text-center card_hover row mt-5 mb-5">


        <Container>
          <div className="sus_in">
            <Row>
              <Col md={4} className="wow fadeInUp" data-wow-delay="0.5s">
                <Link to='/environment'>   <Card>
                  <Card.Img variant="top" src={st01} />
                  <Card.Body>
                    <Card.Title className="g_invert">{t("sustainability.Environment")}</Card.Title>
                  </Card.Body>
                </Card></Link>
              </Col>

              <Col md={4} className="wow fadeInUp" data-wow-delay="0.7s">
                <Link to='/social'>   <Card>
                  <Card.Img variant="top" src={st02} />
                  <Card.Body>
                    <Card.Title className="g_invert">{t("sustainability.Social")} </Card.Title>
                  </Card.Body>
                </Card></Link>
              </Col>

              <Col md={4} className="wow fadeInUp" data-wow-delay="0.8s">
                <Link to='/governance'>  <Card>
                  <Card.Img variant="top" src={st03} />
                  <Card.Body>
                    <Card.Title className="g_invert">{t("sustainability.Governance")}</Card.Title>
                  </Card.Body>
                </Card></Link>
              </Col>

            </Row>
          </div>
        </Container>

      </div>



    </div >


  )
}

export default Sustainability