import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Header from './common/header';
import Footer from './common/footer';
import Home from './pages/home';
import AboutUs from './pages/about-us';

import LifeatNuvoco from './pages/lifeatnuvoco';
import OurJourney from './pages/our-journey';
import Cement from './pages/cement-plants';
import VisionMission from './pages/vision-mission';
import BoardOfDirectors from './pages/board-of-directors';
import ManagementTeam from './pages/management-team';
import CementConcreto from './pages/cement-concreto';
import MBM from './pages/mbm';
import InstamixMortare from './pages/instamix-mortare'
import CorporateGovernance from './pages/corporate-governance'
import InvestorsCorner from './pages/investors-corner';
import PerformanceHighlight from './pages/performance-highlight';
import AnnualReports from './pages/annul-reports';
import Media from './pages/media';
import InnovationCentre from './pages/innovation-centre';
import ScrollToTop from "./common/scrolltop";
import OurBarnd from './pages/our-brand'
import ContactUs from './pages/contactUs'
import WOW from 'wowjs';
import './App.css';
import './responsive.css';
import Blog from './pages/blog';
import OurCement from './pages/ourCement';
import OurMBM from './pages/ourMBM';
import OurRMC from './pages/ourRMC';
import Disclaimer from './pages/disclaimer';
import Governance from './pages/governance';
import Sustainability from './pages/sustainability';
import Environment from './pages/environment';
import Social from './pages/social';
import HealthSafety from './pages/health-and-safety';
import BrandStory from './pages/our-brand-story';
import EVP from './pages/our-employee-value-proposition';
import WomenInNuvoco from './pages/press-releases/women-in-nuvoco';
import FinancialResultQ4FY22 from './pages/press-releases/financial-result-q4-fy22';
import SolidifiesNorthIndia from './pages/press-releases/solidifies-north-india';
import ConcretoGlyde from './pages/press-releases/concreto-glyde';
import FinancialResultQ1FY23 from './pages/press-releases/financial-result-q1-fy23';
import PlantinCoimbatore from './pages/press-releases/plant-in-coimbatore';
import FinancialResultQ4FY23 from './pages/press-releases/financial-result-q4-fy23';
import PlantinVizag from './pages/press-releases/plant-in-vizag';
import AwardForExcellence2022 from './pages/press-releases/award-for-excellence-2022';
import SwarnMahotsavinBihar from './pages/press-releases/swarn-mahotsav-in-bihar';
import CommissioningofAFR from './pages/press-releases/Commissioning-of-AFR-at-Risda-and-Nimbol';
import JojoberaCementPlant from './pages/press-releases/Jojobera-Cement-Plant';
import FinancialResultQ2FY23 from './pages/press-releases/financial-result-q2-fy23';
import NuvoNirmaanApp from './pages/press-releases/Nuvo-Nirmaan-App';
import GreenProEcolabel from './pages/press-releases/GreenPro-Ecolabel';
import SuperPremiumCement from './pages/press-releases/Super-Premium-Cement';
import UltraModernAluminium from './pages/press-releases/ultra-modern-aluminium';
import MasonSkillDevelopment from './pages/press-releases/mason-skill-development';
import TechnicalMobileVan from './pages/press-releases/technical-mobile-van';
import AFRProjectRisda from './pages/press-releases/afr-project-Risda';
import InstaMixSuperiorConcrete from './pages/press-releases/instaMix-superior-column-concrete';
import FinancialResultQ1FY24 from './pages/press-releases/financial-result-q1-fy24';
import FibreReinforcedCement from './pages/press-releases/fibre-reinforced-cement';
import DuraguardXtraJharkhand from './pages/press-releases/duraguard-xtra-jharkhand';
import FinancialResultQ2FY24 from './pages/press-releases/financial-result-q2-fy24';
import FinancialResultQ3FY24 from './pages/press-releases/financial-result-q3-fy24';
import FinancialResultQ4FY24 from './pages/press-releases/financial-result-q4-fy24';
import MasonSkillDevelopmentJamshedpur from './pages/press-releases/mason-skill-development-Jamshedpur';
import ChittorCementPlant from './pages/press-releases/chittor-cement-plant-success';
import ConcretoDawshomAwbotaar from './pages/press-releases/concreto-with-dawshom-awbotaar';
import FootprintCuttingEdge from './pages/press-releases/footprint-in-hyderabad-cutting-edge';
import ReadyMixPlantPatna from './pages/press-releases/ready-mix-plant-Patna';
import StrengthensPresence from './pages/press-releases/strengthens-presence-in-pune-mumbai';
import AnniversaryJojoberaPlant from './pages/press-releases/anniversary-jojobera-cement-plant';
import BasantiDurgaPuja from './pages/press-releases/shri-basanti-durga-puja';
import HydrophobicConcreteConcretoUno from './pages/press-releases/hydrophobic-concrete-concreto-uno';
import NuvoMasonSkillDevelopmentCharkhi from './pages/press-releases/nuvo-mason-skill-development-charkhi';
import EcodureThermalInsulatedConcrete from './pages/press-releases/launch-ecodure-thermal-insulated-concrete';

import { Helmet } from 'react-helmet';
import HirenPatel from './pages/board-of-director/hiren-patel';
import KaushikbhaiPatel from './pages/board-of-director/Kaushikbhai-Patel';
import BhavnaDoshi from './pages/board-of-director/Bhavna-Doshi';
import BerjisDesai from './pages/board-of-director/Berjis-Desai';
import AchalBakeri from './pages/board-of-director/Achal-Bakeri';
import JayakumarKrishnaswamy from './pages/board-of-director/Jayakumar-Krishnaswamy';
import ShrutaSanghavi from './pages/board-of-director/Shruta-Sanghavi';
import MananShah from './pages/board-of-director/Manan-Shah';
import MadhumitaBasu from './pages/board-of-director/Madhumita-Basu';
import i18nRoutes from './i18n.routes';
import LanguageHandler from './common/languageHandler';

class App extends Component {
  componentDidMount() {
    new WOW.WOW({
    live: false
}).init();

}
  render() {
    return (
       <Router>
          <ScrollToTop />
          <Header/>
          <Helmet>
            <title>Nuvoco</title>
            <meta name="keywords" description="" />
            <meta name="description" description="" />
          </Helmet>
           <Routes>
                <Route element={<LanguageHandler/>}>
                 <Route  path='/' element={< Home />}></Route>
                 <Route path='/about-us' element={< AboutUs />}></Route>
                   <Route path='life-at-nuvoco' element={< LifeatNuvoco />}></Route>
                  <Route path='our-journey' element={< OurJourney/>}></Route>
                  <Route path='cement-plants/:location' element={< Cement />}></Route>
                  <Route path='vision-mission-and-values' element={< VisionMission />}></Route>
                  <Route path='quality-and-innovation' element={< InnovationCentre />}></Route>
                  <Route path='board-of-directors' element={< BoardOfDirectors />}></Route>
                  <Route path='management-team' element={< ManagementTeam />}></Route>
                  <Route path='products/:cementconcreto' element={< CementConcreto />}></Route>
                  <Route path='mbm' element={< MBM />}></Route>
                  <Route path='instamix-mortare' element={< InstamixMortare />}></Route>
                  <Route path='corporate-governance' element={< CorporateGovernance />}></Route>
                  <Route path='investors-corner' element={< InvestorsCorner />}></Route>
                  <Route path='performance-highlights' element={< PerformanceHighlight />}></Route>
                  <Route path='media/:type' element={< Media />}></Route>
                  <Route path='our-brands' element={< OurBarnd />}></Route>
                  <Route path='contact-us' element={< ContactUs />}></Route>
                  <Route path='annual-reports' element={< AnnualReports />}></Route>
                  <Route path='blog/:blog' element={< Blog />}></Route>
                  <Route path='top-cement-company' element={< OurCement />}></Route>
                  <Route path='modern-building-materials' element={< OurMBM />}></Route>
                  <Route path='ready-mix-concrete' element={< OurRMC />}></Route>
                  <Route path='disclaimer' element={< Disclaimer />}></Route>
                  <Route path='governance' element={< Governance />}></Route>  
                  <Route path='sustainability' element={< Sustainability />}></Route>
                  <Route path='environment' element={< Environment />}></Route>
                  <Route path='social' element={< Social />}></Route>
                  <Route path='health-and-safety' element={< HealthSafety />}></Route>
                  <Route path='our-brand-story' element={< BrandStory />}></Route>
                  <Route path='our-employee-value-proposition' element={< EVP />}></Route>

                  {/* Press Releases */}
                  <Route path='media/:type/women-in-nuvoco' element={< WomenInNuvoco />}></Route>
                  <Route path='media/:type/financial-result-q4-fy22' element={< FinancialResultQ4FY22 />}></Route>
                  <Route path='media/:type/solidifies-position-in-north-india' element={< SolidifiesNorthIndia />}></Route>
                  <Route path='media/:type/concreto-glyde' element={< ConcretoGlyde />}></Route>
                  <Route path='media/:type/financial-result-q1-fy23' element={< FinancialResultQ1FY23 />}></Route>
                  <Route path='media/:type/plant-in-coimbatore' element={< PlantinCoimbatore />}></Route>
                  <Route path='media/:type/financial-result-q4-fy23' element={< FinancialResultQ4FY23 />}></Route>
                  <Route path='media/:type/plant-in-vizag' element={< PlantinVizag />}></Route>
                  <Route path='media/:type/award-for-excellence-2022' element={< AwardForExcellence2022 />}></Route>
                  <Route path='media/:type/swarn-mahotsav-in-bihar' element={< SwarnMahotsavinBihar />}></Route>
                  <Route path='media/:type/Commissioning-of-AFR-at-Risda-and-Nimbol' element={< CommissioningofAFR />}></Route>
                  <Route path='media/:type/Jojobera-Cement-Plant' element={< JojoberaCementPlant />}></Route>
                  <Route path='media/:type/financial-result-q2-fy23' element={< FinancialResultQ2FY23 />}></Route>
                  <Route path='media/:type/Nuvo-Nirmaan-App' element={< NuvoNirmaanApp />}></Route>
                  <Route path='media/:type/GreenPro-Ecolabel' element={< GreenProEcolabel />}></Route>
                  <Route path='media/:type/Super-Premium-Cement' element={< SuperPremiumCement />}></Route>
                  <Route path='media/:type/ultra-modern-aluminium' element={< UltraModernAluminium />}></Route>
                  <Route path='media/:type/mason-skill-development' element={< MasonSkillDevelopment />}></Route>
                  <Route path='media/:type/technical-mobile-van' element={< TechnicalMobileVan />}></Route>
                  <Route path='media/:type/afr-project-Risda' element={< AFRProjectRisda />}></Route>
                  <Route path='media/:type/instaMix-superior-column-concrete' element={< InstaMixSuperiorConcrete />}></Route>
                  <Route path='media/:type/financial-result-q1-fy24' element={< FinancialResultQ1FY24 />}></Route>
                  <Route path='media/:type/fibre-reinforced-cement' element={< FibreReinforcedCement />}></Route>
                  <Route path='media/:type/duraguard-xtra-jharkhand' element={< DuraguardXtraJharkhand />}></Route>
                  <Route path='media/:type/financial-result-q2-fy24' element={< FinancialResultQ2FY24 />}></Route>
                  <Route path='media/:type/financial-result-q3-fy24' element={< FinancialResultQ3FY24 />}></Route>
                  <Route path='media/:type/mason-skill-development-Jamshedpur' element={< MasonSkillDevelopmentJamshedpur />}></Route>
                  <Route path='media/:type/chittor-cement-plant-success' element={< ChittorCementPlant />}></Route>
                  <Route path='media/:type/concreto-with-dawshom-awbotaar' element={< ConcretoDawshomAwbotaar />}></Route>
                  <Route path='media/:type/footprint-in-hyderabad-cutting-edge' element={< FootprintCuttingEdge />}></Route>
                  <Route path='media/:type/ready-mix-plant-Patna' element={< ReadyMixPlantPatna />}></Route>
                  <Route path='media/:type/strengthens-presence-in-pune-mumbai' element={< StrengthensPresence />}></Route>
                  <Route path='media/:type/anniversary-jojobera-cement-plant' element={< AnniversaryJojoberaPlant />}></Route>
                  <Route path='media/:type/shri-basanti-durga-puja' element={< BasantiDurgaPuja />}></Route>
                  <Route path='media/:type/financial-result-q4-fy24' element={< FinancialResultQ4FY24 />}></Route>
                  <Route path='media/:type/hydrophobic-concrete-concreto-uno' element={< HydrophobicConcreteConcretoUno />}></Route>
                  <Route path='media/:type/nuvo-mason-skill-development-charkhi' element={< NuvoMasonSkillDevelopmentCharkhi />}></Route>
                  <Route path='media/:type/launch-ecodure-thermal-insulated-concrete' element={< EcodureThermalInsulatedConcrete />}></Route>

                  {/* Board of Directors */}
                  <Route path='hiren-patel' element={< HirenPatel />}></Route>
                  <Route path='Kaushikbhai-Patel' element={< KaushikbhaiPatel />}></Route>
                  <Route path='Bhavna-Doshi' element={< BhavnaDoshi />}></Route>
                  <Route path='Berjis-Desai' element={< BerjisDesai />}></Route>
                  <Route path='Achal-Bakeri' element={< AchalBakeri />}></Route>
                  <Route path='Jayakumar-Krishnaswamy' element={< JayakumarKrishnaswamy />}></Route>
                  <Route path='Shruta-Sanghavi' element={< ShrutaSanghavi />}></Route>
                  <Route path='Manan-Shah' element={< MananShah />}></Route>
                  <Route path='Madhumita-Basu' element={< MadhumitaBasu />}></Route>
                  </Route>
                  {/* i18n Routes */}
                  <Route path='/:lng' element={< LanguageHandler />}>
                    {i18nRoutes}
                  </Route>

          </Routes>
          <Footer/>
       </Router>
   );
  }
}

export default App;
