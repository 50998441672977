import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Button, Form, Alert, Card } from 'react-bootstrap';
import banner from '../assets/images/evp.png';
import bannermobile from '../assets/images/evp.png';
import aboutBannerLogo from '../assets/images/socialIcon.png';
import StoryImg1 from '../assets/images/our-brand-story-img-1.png';
import StoryImg2 from '../assets/images/our-brand-story-img-2.jpg';
import slogodescription from '../assets/images/new-logo-description.jpg';
import BrandStoryBanner from '../assets/images/our-brand-story-banner.png';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

const EVP = () => {
  const { t } = useTranslation();
  const [IsTab, setTabType] = useState(1);
  const setTab = (id) => {
    setTabType(id);
  }
  return (
    <div className="socialpage enviromentPage">


      <Helmet>
        <title>Our Employee Value Proposition | Nuvoco Vistas</title>
        <meta name="keywords" description="" />
        <meta name="description" content="Nuvoco's Brand has acheived it's milestones since its inception shaping a new robust world with its wordclass cement and ready mix concrete quality" />
        <link rel="canonical" href="https://nuvoco.com/our-employee-value-proposition" />
      </Helmet>

      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner' src={bannermobile} alt="" />

        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">{t("breadcrumb.home")}</Breadcrumb.Item>
              <Breadcrumb.Item active>
                {t("value_proposition.title")}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className='midpart'>
        <Container>
          <Row>
            <h2 className='grad-heading'>{t("value_proposition.title")}</h2>
          </Row>

          <Row>
            <Col md={12}>
              <p><br></br>
                <Row>
                  <Col md={12} >
                    <h2 className="grad-heading mb-4">{t("value_proposition.heading")}</h2>
                    <p>{t("value_proposition.desc1")}</p>
                    <p><strong>{t("value_proposition.desc2")}</strong></p>
                  </Col>

                  {/* <Col md={6} >
                    <iframe style={{ width: '100%', height: '400px' }} src="https://www.youtube.com/embed/z7UyjocQQl0?si=RV4FtF4NlbS8QTwR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </Col> */}
                </Row>
              </p>

            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={12}>
              <h2 className="grad-heading">{t("value_proposition.evm_pillers.title")}</h2>
              <h3 className="mb-3">{t("value_proposition.evm_pillers.leadership.title")}</h3>
              <ul className="mb-4">
                <li dangerouslySetInnerHTML={{ __html: t("value_proposition.evm_pillers.leadership.point1") }}></li>
                <li dangerouslySetInnerHTML={{ __html: t("value_proposition.evm_pillers.leadership.point2") }}></li>
                <li dangerouslySetInnerHTML={{ __html: t("value_proposition.evm_pillers.leadership.point3") }}></li>
              </ul>
              <h3 className="mb-3">{t("value_proposition.evm_pillers.learning.title")}</h3>
              <ul className="mb-4">
                <li dangerouslySetInnerHTML={{ __html: t("value_proposition.evm_pillers.learning.point1") }}></li>
                <li dangerouslySetInnerHTML={{ __html: t("value_proposition.evm_pillers.learning.point2") }}></li>
              </ul>
              <h3 className="mb-3">{t("value_proposition.evm_pillers.learning_development.title")}</h3>
              <ul className="mb-4">
                <li dangerouslySetInnerHTML={{ __html: t("value_proposition.evm_pillers.learning_development.point1") }}></li>
              </ul>
              <h3 className="mb-3">{t("value_proposition.evm_pillers.care.title")}</h3>
              <ul className="mb-4">
                <li dangerouslySetInnerHTML={{ __html: t("value_proposition.evm_pillers.care.point1") }}></li>
                <li dangerouslySetInnerHTML={{ __html: t("value_proposition.evm_pillers.care.point2") }}></li>
              </ul>
              <h3 className="mb-3">{t("value_proposition.evm_pillers.career.title")}</h3>
              <ul className="mb-4">
                <li dangerouslySetInnerHTML={{ __html: t("value_proposition.evm_pillers.career.point1") }}></li>
                <li dangerouslySetInnerHTML={{ __html: t("value_proposition.evm_pillers.career.point2") }}></li>
              </ul>
              <p><strong>{t("value_proposition.evm_pillers.desc1")}</strong></p>
              <p><strong>{t("value_proposition.evm_pillers.desc2")}</strong></p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default EVP