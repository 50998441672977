import { CEMENT } from "./type";
import { API_URL } from "../config";
import axios from "axios";

const cementFn = (data) =>{
    return{
        type: CEMENT,
        payload: data
    };
}

export const cementApi = (page_type,lng) =>{
    let data ={page_url: page_type,lang:lng};
    console.log(data)
    return (dispatch) =>{
        axios.post(API_URL + 'ProductAndSolutionCategory', data).then((response) =>{
            dispatch(cementFn(response));
        });
    };
}