import React from 'react';
import { Route } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Home from './pages/home';
import CementConcreto from './pages/cement-concreto';
import AboutUs from './pages/about-us';
import OurCement from './pages/ourCement';
import OurRMC from './pages/ourRMC';
import OurMBM from './pages/ourMBM';
import OurJourney from './pages/our-journey';
import InnovationCentre from './pages/innovation-centre';
import OurBarnd from './pages/our-brand';
import Sustainability from './pages/sustainability';
import HealthSafety from './pages/health-and-safety';
import LifeatNuvoco from './pages/lifeatnuvoco';
import EVP from './pages/our-employee-value-proposition';
import Disclaimer from './pages/disclaimer';
import Media from './pages/media';
import ContactUs from './pages/contactUs';

const i18nRoutes = (
     <Route>
          <Route index element={< Home />}></Route>
          <Route path='about-us' element={< AboutUs />}></Route>
          <Route path='products/:cementconcreto' element={< CementConcreto />}></Route>
          <Route path='top-cement-company' element={< OurCement />}></Route>
          <Route path='ready-mix-concrete' element={< OurRMC />}></Route>
          <Route path='modern-building-materials' element={< OurMBM />}></Route>
          <Route path='our-journey' element={< OurJourney/>}></Route>
          <Route path='quality-and-innovation' element={< InnovationCentre />}></Route>
          <Route path='our-brands' element={< OurBarnd />}></Route>
          <Route path='sustainability' element={< Sustainability />}></Route>
          <Route path='health-and-safety' element={< HealthSafety />}></Route>
          <Route path='life-at-nuvoco' element={< LifeatNuvoco />}></Route>
          <Route path='our-employee-value-proposition' element={< EVP />}></Route>
          <Route path='disclaimer' element={< Disclaimer />}></Route>
          <Route path='media/:type' element={< Media />}></Route>
          <Route path='contact-us' element={< ContactUs />}></Route>
     </Route>
)

export default i18nRoutes;