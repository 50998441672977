import React, { useState } from "react";
import Slider from "react-slick";
import { Container, Breadcrumb, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import banner from '../assets/images/ourbrand.jpg'
import aboutBannerLogo from '../assets/images/barndlogo.png'

import cementLogo from '../assets/images/cement_logo.svg';
import rmxconcreto from '../assets/images/rmxconcreto.png';


import dcement from '../assets/images/dcement.png';
import duraguard50kg from '../assets/images/duraguard50kg.png';
import duraguardmicrofibre50kg from '../assets/images/duraguardmicro-fibre-50kg.png';
import duraguardrapidx50kg53 from '../assets/images/duraguard-rapidx50kg-53.png';
import duraguardsilver50kg from '../assets/images/duraguardsilver50kg.jpg';
import duraguardwaterseal50kg from '../assets/images/duraguard-waterseal-50kg.png';
import duraguardxtra50kg from '../assets/images/duraguard-xtra-50kg.png';

import masterpremiumcement from '../assets/images/masterpremiumcement.png';
import ppcbag from '../assets/images/ppcbag.png';
import pscbag from '../assets/images/pscbag.png';
import subhpremiumpsc from '../assets/images/subhpremiumpsc.png';
import pscadstarbags from '../assets/images/pscadstarbags.png';

import nirmax50kg from '../assets/images/nirmax50kg.png';
import nirmaxbags01 from '../assets/images/nirmaxbags01.png';
import Infracem50kg from '../assets/images/Infracem50kg.png';
import ppcpscprocem from '../assets/images/ppcpsc-procem.png';
import ppc43 from '../assets/images/ppc43.png';
import ppc53 from '../assets/images/ppc43.png';
import concretoUno from '../assets/images/Concreto-uno-50kg.png';



import instamixbaggedconcrete from '../assets/images/instamixbaggedconcrete.png';
import instamixmicrone from '../assets/images/instamixmicrone.png';
import instamixmortareradymixmortar from '../assets/images/instamixmortareradymixmortar.png';

import instamixbondaid50kg from '../assets/images/instamixbondaid50kg.png';
import instamixplastosmart from '../assets/images/instamixplastosmart.png';
import instamixxpress from '../assets/images/instamixxpress.png';
import xconlogo from '../assets/images/xconlogo.png';
import artistelogo05 from '../assets/images/artistelogo05.png';

import zeromacrylicpower from '../assets/images/zeromacrylicpower3.png';
import zeromcoverblock from '../assets/images/zeromcoverblock8.png';
import zeromgermicheckwallputty from '../assets/images/zeromgermicheckwallputty6.png';
import zeromlatexexpert from '../assets/images/zeromlatexexpert4.png';
import zerompolyrichwallputty from '../assets/images/zerompolyrichwallputty7.png';
import zeromspeedextileadhesive from '../assets/images/zeromspeedextileadhesive5.png';
import zeromspeedextilegrout from '../assets/images/zeromspeedextilegrout9.png';

import zeromwatershieldkee from '../assets/images/zeromwatershieldkee.jpeg';
import zeromwatershieldiwc from '../assets/images/zeromwatershieldiwc1.png';
import zerowatershield from '../assets/images/zerowatershield2.png';

import concretoagile from '../assets/images/concreto-agile.png';
import concretoagileflow from '../assets/images/concreto-agile-flow.png';
import concretocorrosafeplus from '../assets/images/concreto-corrosafe-plus.png';
import concretocwtplus from '../assets/images/concreto-cwt-plus.png';
import concretoeasyfille from '../assets/images/concreto-easyfille.png';
import concretoecodure from '../assets/images/concreto-ecodure.png';
import concretofluide from '../assets/images/concreto-fluide.png';
import concretoinstante from '../assets/images/concreto-instante.png';
import concretolente from '../assets/images/concreto-lente.png';
import concretopermadure from '../assets/images/concreto-permadure.png';
import concretopolibre from '../assets/images/concreto-polibre.png';
import concretoregletherme from '../assets/images/concreto-regletherme.png';
import concretorobuste from '../assets/images/concreto-robuste.png';
import concretosteelibre from '../assets/images/concreto-steelibre.png';
import concretoxlite from '../assets/images/concreto-xlite.png';
import concretoxlitestructure from '../assets/images/concreto-xlite-structure.png';
import concretoglydelogo from '../assets/images/concreto-glyde-logo.png';



import artistsengrave from '../assets/images/artists-engrave.png';
import artistshug from '../assets/images/artists-hug.png';
import artistbare from '../assets/images/artist-bare.png';
import artistscoarse from '../assets/images/artists-coarse.png';
import artistlumos from '../assets/images/artist-lumos.png';

import zeroMIWC from '../assets/images/zero-m-iwc.png';
import zeroM2k from '../assets/images/2k.png';
import zeroMMultipurposeLatexExpert from '../assets/images/MultipurposeLatexExpert.png';
import zeroMAcrylicPower from '../assets/images/AcrylicPower.png';
import zeroMRoofShield from '../assets/images/Roof_Shield.png';
import zeroMTileCleaner from '../assets/images/TileCleaner_JerryCan.png';
import zeroMTileAdhesiveT1 from '../assets/images/TileAdhesive_T1.png';
import zeroMTileAdhesiveT2 from '../assets/images/TileAdhesive_T2.png';
import zeroMTileAdhesiveT3 from '../assets/images/TileAdhesive_T3.png';
import zeroMTileAdhesiveT4 from '../assets/images/TileAdhesive_T4.png';
import zeroMTileAdhesiveT5 from '../assets/images/TileAdhesive_T5.png';
import zeroMEpoxyTileGrout from '../assets/images/EpoxyTileGrout.png';
import zeroMCementTileGrout from '../assets/images/CementTileGrout_Comp.png';
import zeroMWallPutty from '../assets/images/Wall-Putty.png';
import zeroMDryPlaster from '../assets/images/DryPlaster.png';
import zeroMDryConcrete from '../assets/images/DryConcrete.png';
import zeroMBlockJointingMortar from '../assets/images/BlockJointingMortar.png';
import zeroMCoverBlocksTerracotta from '../assets/images/CoverBlocks_Terracotta.png';


import dbull from '../assets/images/dbull.png';
import nirmex from '../assets/images/nirmex.png';
import psc from '../assets/images/psc.png';
import infracem from '../assets/images/infracem.png';
import artiste from '../assets/images/artiste.png';
import concreto from '../assets/images/concreto.png';
import ecodure from '../assets/images/ecodure.png';
import instamix from '../assets/images/instamix.png';
import xcon from '../assets/images/xcon.png';
import zeroM from '../assets/images/zeroM.png';
import concreto01 from '../assets/images/concreto.jpg'
import green from '../assets/images/green.jpg'
import ecodureproduct from '../assets/images/ecodure-product.png'
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { usePathLanguage } from "../hooks/usePathLanguage";

const settings = {
    //centerMode: true,
    //autoplay:true,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
};

const settings1 = {
    //centerMode: true,
    //autoplay:true,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
};


const OurBarnd = () => {
    const { t } = useTranslation();
    const pathLanguage = usePathLanguage();
    const [one, setOne] = useState(false);
    const closeOne = () => setOne(false);

    const [two, setTwo] = useState(false);
    const closeTwo = () => setTwo(false);

    const [three, setThree] = useState(false);
    const closeThree = () => setThree(false);

    const [four, setFour] = useState(false);
    const closeFour = () => setFour(false);

    const [five, setFive] = useState(false);
    const closeFive = () => setFive(false);

    const [six, setSix] = useState(false);
    const closeSix = () => setSix(false);

    const [seven, setSeven] = useState(false);
    const closeSeven = () => setSeven(false);

    const [eight, setEight] = useState(false);
    const closeEight = () => setEight(false);

    const [nine, setNine] = useState(false);
    const closeNine = () => setNine(false);

    const [ten, setTen] = useState(false);
    const closeTen = () => setTen(false);

    const [eleven, setEleven] = useState(false);
    const closeEleven = () => setEleven(false);


    const [twelve, setTwelve] = useState(false);
    const closeTwelve = () => setTwelve(false);

    const [thirteen, setThirteen] = useState(false);
    const closeThirteen = () => setThirteen(false);



    const showComponent = ((varname) => {
        switch (varname) {
            case "one":
                setOne(true);
                break;
            case "two":
                setTwo(true);
                break;
            case "three":
                setThree(true);
                break;
            case "four":
                setFour(true);
                break;
            case "five":
                setFive(true);
                break;
            case "six":
                setSix(true);
                break;
            case "seven":
                setSeven(true);
                break;
            case "eight":
                setEight(true);
                break;
            case "nine":
                setNine(true);
                break;
            case "ten":
                setTen(true);
                break;
            case "eleven":
                setEleven(true);
                break;
            case "twelve":
                setTwelve(true);
                break;
            case "thirteen":
                setThirteen(true);
                break;
            default: return;
        }
    })

    return (
        <div className="our_brand_inner">
    <Helmet>
    <title>Our Brands | Nuvoco Vistas</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas is offering wide range of products across cement ,ready mix concrete and modern building material categories with great range of durable products" />
    <link rel="canonical" href="https://nuvoco.com/our-brands"/>
    </Helmet>
            <div className="banner text-center position-relative">
                <img className='img_round' src={banner} alt="" />
                <article className='position-absolute text-center'>
                    <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                    <h1 className="text-uppercase">{t("our_brands.title")}</h1>
                </article>
                <div className='breadouter'>
                    <div className='container'>
                        <Breadcrumb className='mb-0 py-2'>
                            <Breadcrumb.Item href="#">{t("breadcrumb.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                            {t("our_brands.title")}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div className="card_round pt-5">
                <Container>
                    <h2 className='grad-heading pd-5 mb-5'>{t("our_brands.cement.title")}</h2>
                    <Row className="pb-4">
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('one')}>
                                <Card.Img src={cementLogo} />
                                <Card.Body>
                                    <article>{t("our_brands.cement.Concreto.description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.cement.Concreto.title")}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                         <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('two')}>
                                <Card.Img src={dcement} />
                                <Card.Body>
                                    <article>
                                    {t("our_brands.cement.Duraguard.description")}
                                    </article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.cement.Duraguard.title")}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col> 

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('three')}>
                                <Card.Img src={dbull} />
                                <Card.Body>
                                    <article>{t("our_brands.cement.doublebull.description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.cement.doublebull.title")}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('four')}>
                                <Card.Img src={psc} />
                                <Card.Body>
                                    <article>{t("our_brands.cement.psc.description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.cement.psc.title")}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('five')}>
                                <Card.Img src={nirmex} />
                                <Card.Body>
                                    <article>{t("our_brands.cement.Nirmax.description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.cement.Nirmax.title")}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('six')}>
                                <Card.Img src={infracem} />
                                <Card.Body>
                                    <article>{t("our_brands.cement.infracem.description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.cement.infracem.title")}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <h2 className='grad-heading pd-5 mb-5'>{t("our_brands.readyMix.title")}</h2>
                    <Row className="pb-4 justify-content-center">
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('seven')}>
                                <Card.Img src={rmxconcreto} />
                                <Card.Body>
                                    <article>{t("our_brands.readyMix.Concreto.description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.readyMix.Concreto.title")}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('eight')}>
                                <Card.Img src={instamix} />
                                <Card.Body>
                                    <article>{t("our_brands.readyMix.Instamix.description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.readyMix.Instamix.title")}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('nine')}>
                                <Card.Img src={artiste} />
                                <Card.Body>
                                    <article>{t("our_brands.readyMix.Artiste.description")} </article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.readyMix.Artiste.title")} 
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('ten')}>
                                <Card.Img src={xcon} />
                                <Card.Body>
                                    <article>{t("our_brands.readyMix.x_Con.description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.readyMix.x_Con.title")} 
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('eleven')}>
                                <Card.Img src={ecodure} />
                                <Card.Body>
                                    <article>{t("our_brands.readyMix.Ecodure.description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.readyMix.Ecodure.title")}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <h2 className='grad-heading pd-5 mb-5'>{t("our_brands.mbm.title")}</h2>
                    <Row className="pb-4 justify-content-center">
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('twelve')}>
                                <Card.Img src={zeroM} />
                                <Card.Body>
                                    <article>{t("our_brands.mbm.zero_m.description")}</article>
                                    <Card.Title className="g_invert">
                                    {t("our_brands.mbm.zero_m.title")}
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('thirteen')}>
                                <Card.Img src={instamix} />
                                <Card.Body>
                                    <article>
                                        Our InstaMix range is a careful selection of products that make the construction process convenient. It is the immediate solution for all construction needs that require ready-to-use, pre-mixed products and help in saving time, cost, and other resources.
                                    </article>
                                    <Card.Title className="g_invert">
                                        InstaMix
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                </Container >
            </div>


            <Modal size="lg" centered="true"
                show={one} onHide={closeOne} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={cementLogo} alt='' />
                                <article className="p-4">{t("our_brands.cement.Concreto.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concreto01} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.Concreto.Concreto_Cement")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-cement`}> {t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoUno} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            {t("our_brands.cement.Concreto.Concreto_Uno")}
                            </Card.Title>
                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-uno`}>{t("home.read_more")}  </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                                <Col md={4}>
                                    {/* <Card>
                                        <Card.Img src={green} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Concreto Green
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-green-cement">Read More </Button>
                                        </Card.Body>
                                    </Card> */}
                                </Col>
                                

                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={two} onHide={closeTwo} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center logospacepop">
                                <img src={dcement} alt='' />
                                <article className="p-4">{t("our_brands.cement.Duraguard.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>



                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={duraguardsilver50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.Duraguard.F2F")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/duraguard-f2f`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/* <Col md={4}>
                                    <Card>
                                        <Card.Img src={duraguardwaterseal50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Waterseal Cement
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/duraguard-waterseal-cement">Read More </Button>
                                        </Card.Body>
                                    </Card> 
                                </Col>*/}


                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={duraguardmicrofibre50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.Duraguard.Microfiber")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/duraguard-microfiber-cement`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={duraguardxtra50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.Duraguard.Xtra")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/duraguard-xtra-cement`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={duraguardrapidx50kg53} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.Duraguard.RapidX")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/duraguard-rapidx-cement`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                <Card>
                                        <Card.Img src={duraguard50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.Duraguard.Duraguard")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/duraguard-cement`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>



                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={three} onHide={closeThree} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center logospacepop">
                                <img src={dbull} alt='' />
                                <article className="p-4">{t("our_brands.cement.doublebull.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                {/* <Col md={4}>
                                    <Card>
                                        <Card.Img src={subhpremiumpsc} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                Subh Cement
                                            </Card.Title>
                                            <Button variant="success" className="read_more">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col> */}
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={masterpremiumcement} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.doublebull.MasterCement")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/double-bull-master-cement`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={pscbag} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.doublebull.PSC")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/double-bull-psc`}>{t("home.read_more")}</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ppcbag} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.doublebull.PPC")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/double-bull-ppc`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                             
                            

                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={four} onHide={closeFour} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center logospacepop">
                                <img src={psc} alt='' />
                                <article className="p-4">{t("our_brands.cement.psc.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={pscadstarbags} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.psc.title")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/p-s-c`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>

                                </Col>
                                <Col md={4}>

                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={five} onHide={closeFive} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center logospacepop">
                                <img src={nirmex} alt='' />
                                <article className="p-4">{t("our_brands.cement.Nirmax.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={nirmax50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.Nirmax.Nirmax")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nirmax-ppc`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={nirmaxbags01} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.Nirmax.Nirmax")} 43/53
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nirmax-opc`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>

                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={six} onHide={closeSix} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={infracem} alt='' />
                                <article className="p-4">{t("our_brands.cement.infracem.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={Infracem50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.cement.infracem.title")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/infracem-cement`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                {/* <Card>
                                        <Card.Img src={ppcpscprocem} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Procem
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-procem-cement">Read More </Button>
                                        </Card.Body>
                                    </Card> */}
                                </Col>
                            
                                <Col md={4}>
                                {/* <Card>
                                        <Card.Img src={ppc43} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Cement OPC 43
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-opc-43">Read More </Button>
                                        </Card.Body>
                                    </Card> */}
                                </Col>
                                    {/* 
                                <Col md={4}>
                                <Card>
                                        <Card.Img src={ppc53} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Double Bull Cement OPC 53
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/double-bull-opc-53">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col> */}

                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={seven} onHide={closeSeven} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={rmxconcreto} alt='' />
                                <article className="p-4"> {t("our_brands.readyMix.Concreto.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoagile} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Agile")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-agile`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretorobuste} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Robuste")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-robuste`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretofluide} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Fluide")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-fluide`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoinstante} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Instante")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-instante`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoregletherme} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Regletherme")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-regletherme`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretocorrosafeplus} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Corrosafe_Plus")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-corrosafe-plus`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretopermadure} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Permadure")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-permadure`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretosteelibre} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Steelibre")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-steelibre`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoagileflow} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Agile_Flow")} 
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-agile-flow`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoxlite} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Xlite")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-xlite`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoeasyfille} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Easyfille")}

                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-easyfille`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretolente} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Lente")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-lente`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretocwtplus} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_CWT_Plus")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-cwt-plus`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoxlitestructure} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Xlite_Structure")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-xlite-structure`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoecodure} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Endura")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-endura`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretopolibre} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Polibre")} 
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-polibre`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoglydelogo} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Concreto.Concreto_Glyde")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/concreto-glyde`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                




                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={eight} onHide={closeEight} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={instamix} alt='' />
                                <article className="p-4">{t("our_brands.readyMix.Instamix.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixbaggedconcrete} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Instamix.title")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/instamix`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixmicrone} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Instamix.Instamix_Microne")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/instamix-microne`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixmortareradymixmortar} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Instamix.Instamix_Mortare")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/instamix-mortare`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={nine} onHide={closeNine} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={artiste} alt='' />
                                <article className="p-4">{t("our_brands.readyMix.Artiste.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistelogo05} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Artiste.Artiste_Signature")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-signature`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistsengrave} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Artiste.Artiste_Engrave")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-engrave`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistshug} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Artiste.Artiste_Hue")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-hue`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistbare} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Artiste.Artiste_Bare")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-bare`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistscoarse} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Artiste.Artiste_Coarse")} 
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-coarse`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistlumos} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Artiste.Artiste_Lumos")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/artiste-lumos`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={ten} onHide={closeTen} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={xcon} alt='' />
                                <article className="p-4">{t("our_brands.readyMix.x_Con.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={xconlogo} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                                {t("our_brands.readyMix.x_Con.title")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/x-con`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>

                                </Col>
                                <Col md={4}>

                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={eleven} onHide={closeEleven} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={ecodure} alt='' />
                                <article className="p-4">{t("our_brands.readyMix.Ecodure.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Ecodure.title")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/ecodure`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.readyMix.Ecodure.Ecodure_Prime")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/ecodure-prime`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                             {t("our_brands.readyMix.Ecodure.Ecodure_Plus")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/ecodure-plus`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={twelve} onHide={closeTwelve} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={zeroM} alt='' />
                                <article className="p-4">{t("our_brands.mbm.zero_m.description")}</article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4 titleHeight70">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMIWC} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Zero_M_IWC")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-iwc`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroM2k} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Zero_M_2K")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-2k`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMMultipurposeLatexExpert} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Zero_M_Latex")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-multipurpose-latex-expert`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMAcrylicPower} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Zero_M_Acrylic_Power")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-multipurpose-acrylic-expert`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMRoofShield} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Zero_M_Roof_Shield")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-roof-shield`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMTileCleaner} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Tile_Cleaner")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-tile-cleaner`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMTileAdhesiveT1} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Adhesive_T1")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-tile-adhesive-t1`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMTileAdhesiveT2} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Adhesive_T2")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-tile-adhesive-t2`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMTileAdhesiveT3} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Adhesive_T3")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-tile-adhesive-t3`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMTileAdhesiveT4} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Adhesive_T4")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-tile-adhesive-t4`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMTileAdhesiveT5} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Adhesive_T5")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-tile-adhesive-t5`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMEpoxyTileGrout} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Epoxy_Tile_Grout")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-epoxy-tile-grout`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMCementTileGrout} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Tile_Grout")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-tile-grout`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMWallPutty} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Wall_Putty")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-wall-putty`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMDryPlaster} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Dry_Plaster")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-dry-plaster`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMDryConcrete} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Dry_Concrete")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-dry-concrete`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMBlockJointingMortar} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Block_Joining_Mortar")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-block-jointing-mortar`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeroMCoverBlocksTerracotta} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            {t("our_brands.mbm.zero_m.Cover_Block")}
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href={`${pathLanguage}products/nuvoco-zero-m-cover-block`}>{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                {/* <Col md={4}>
                                    <Card>
                                        <Card.Img src={zerowatershield} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Water Shield
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/zero-m-water-shield">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromacrylicpower} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Acrylic Power
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-multipurpose-acrylic-expert">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromlatexexpert} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Latex Expert
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-multipurpose-latex-expert">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                          
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromgermicheckwallputty} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            GermiCheck Wall Putty
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/zero-m-germicheck-wall-putty">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zerompolyrichwallputty} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Wall Putty
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="products/zero-m-wall-putty">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromcoverblock} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Cover Blocks
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-cover-block">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromwatershieldkee} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Watershield 2K
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-2k">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={zeromspeedextilegrout} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Speedex Tile Grout
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-tile-grout">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col> */}



                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" centered="true"
                show={thirteen} onHide={closeThirteen} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                <Modal.Body>
                    <Modal.Header closeButton></Modal.Header>
                    <Row>
                        <Col md={12} className="d-flex align-items-center">
                            <h2 className='grad-heading pd-5 m-0'>{t("our_brands.title")}</h2>
                        </Col>
                        <Col md={12}>

                            <div className="d-flex align-item-center">
                                <img src={instamix} alt='' />
                                <article className="p-4">
                                    Our InstaMix range is a careful selection of products that make the construction process convenient. It is the immediate solution for all construction needs that require ready-to-use, pre-mixed products and help in saving time, cost, and other resources.
                                </article>
                            </div>


                        </Col>
                    </Row>

                    <div className="mt-3 pb-4">
                        <Row>
                            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixplastosmart} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Plastosmart
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-dry-plaster">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixbondaid50kg} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Bond-Aid
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/nuvoco-zero-m-block-jointing-mortar">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={instamixxpress} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Xpress
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/instamix-xpress-dry-bag-concrete">{t("home.read_more")} </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Slider>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

        </div >
    )
}

export default OurBarnd;