import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, Breadcrumb, Button, Modal } from 'react-bootstrap';
import Slider from "react-slick";
import ReactPlayer from 'react-player';
import Carousel from 'react-bootstrap/Carousel';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Map from './map.js';
import HomeOurBarnd from './home-our-brand';

import banner1 from '../assets/images/newbanner1.jpg';
import banner2 from '../assets/images/newbanner2.png';
import banner3 from '../assets/images/newbanner3.jpg';
import banner4 from '../assets/images/newbanner4.jpg';
import banner5 from '../assets/images/mbm-banner.png';
import banner6 from '../assets/images/sra-banner.jpg';

import bannerhi1 from '../assets/images/hindi/newbanner1_hi.jpg';
import bannerhi2 from '../assets/images/hindi/newbanner2_hi.jpg';
import bannerhi3 from '../assets/images/hindi/newbanner3_hi.jpg';
import bannerhi4 from '../assets/images/hindi/newbanner4_hi.jpg';
import bannerhi5 from '../assets/images/hindi/newbanner5_hi.jpg';


import homeAbout from '../assets/images/home_about.jpg';
import bCement from '../assets/images/business1.jpg';
import homeAsist from '../assets/images/home_asist.jpg';
import readyMix from '../assets/images/ready_mix.jpg';
import mbuilding from '../assets/images/mbuilding.jpg';
import construction from '../assets/images/construction.png';
import house from '../assets/images/house.png';
import application from '../assets/images/application.png';
import cost from '../assets/images/cost.png';
import st01 from '../assets/images/environment-home.jpg';
import st02 from '../assets/images/governance-home.jpg';
import st03 from '../assets/images/social-home.jpg';
import news01 from '../assets/images/news01.jpg';
import news02 from '../assets/images/news02.jpg';
import news03 from '../assets/images/news03.jpg';
import total from '../assets/images/total-rewards.jpg';
import learning from '../assets/images/learning-developemnt.jpg';
import employee from '../assets/images/employee-engagement.jpg';
import job from '../assets/images/growth-accelerator.jpg';
import people from '../assets/images/people.jpg';
import rightIcon from '../assets/images/right_icon.png';
import landmarkBanner01 from '../assets/images/landmark01.jpg';
import landmarkBanner02 from '../assets/images/landmark02.jpg';
import blogImg from '../assets/images/blog.jpg';

import about01 from '../assets/images/about01.png';
import about02 from '../assets/images/about02.png';
import about03 from '../assets/images/about03.png';
import about04 from '../assets/images/about04.png';
import ourbs01 from '../assets/images/ourbs01.png';
import ourbs02 from '../assets/images/ourbs02.png';
import brand02 from '../assets/images/brand02.png';
import brand03 from '../assets/images/brand03.png';
import brand04 from '../assets/images/brand04.png';
import brand05 from '../assets/images/brand05.png';
import brand06 from '../assets/images/brand06.png';
import landmarkcenter01 from '../assets/images/landmarkcenter01.jpg';
import landmarkcenter02 from '../assets/images/landmarkcenter02.jpg';
import landmarkcenter03 from '../assets/images/landmarkcenter03.jpg';
import landmarkcenter04 from '../assets/images/landmarkcenter04.jpg';
import landmarkcenter05 from '../assets/images/landmarkcenter05.jpg';
import landmarkcenter06 from '../assets/images/landmarkcenter06.jpg';
import landmarkcenter07 from '../assets/images/landmarkcenter07.jpg';
import landmarkcenter08 from '../assets/images/landmarkcenter08.jpg';
import landmarkcenter09 from '../assets/images/landmarkcenter09.jpg';
import landmarkcenter10 from '../assets/images/landmarkcenter10.jpg';
import landmark01 from '../assets/images/landmark01.png';
import landmark02 from '../assets/images/landmark02.png';
import landmark03 from '../assets/images/landmark03.png';
import landmark04 from '../assets/images/landmark04.png';
import landmark05 from '../assets/images/landmark05.png';
import landmark06 from '../assets/images/landmark06.png';
import landmark07 from '../assets/images/landmark07.png';
import landmark08 from '../assets/images/landmark08.png';
import assit01 from '../assets/images/assit01.png';
import assit02 from '../assets/images/assit02.png';
import assit03 from '../assets/images/assit03.png';
import assit04 from '../assets/images/assit04.png';
import footprint02 from '../assets/images/footprint02.png';
import footprint09 from '../assets/images/footprint09.png';
import sus01 from '../assets/images/sus01.png';
import sus03 from '../assets/images/sus03.png';
import sus04 from '../assets/images/sus04.png';
import sus05 from '../assets/images/sus05.png';
import sus06 from '../assets/images/sus06.png';
import sus07 from '../assets/images/sus07.png';
import sus08 from '../assets/images/sus08.png';
import sus09 from '../assets/images/sus09.png';
import sus10 from '../assets/images/sus10.png';
import sus11 from '../assets/images/sus11.png';
import blog01 from '../assets/images/blog01.png';
import blog02 from '../assets/images/blog02.png';
import blog03 from '../assets/images/blog03.png';
import blog04 from '../assets/images/blog04.png';
import blog05 from '../assets/images/blog05.png';
import life01 from '../assets/images/life01.png';
import life02 from '../assets/images/life02.png';
import life03 from '../assets/images/life03.png';
import life04 from '../assets/images/life04.png';
import life05 from '../assets/images/life05.png';
import life06 from '../assets/images/life06.png';
import life07 from '../assets/images/life07.png';
import life08 from '../assets/images/life08.png';
import news04 from '../assets/images/news04.png';
import news05 from '../assets/images/news05.png';
import cementHome from '../assets/images/cementHome.jpg';
import VideoFile from '../assets/images/ballet.mp4';
import { supportedLngsObj } from "../helpers/utils";
import { blogApi, mediaRelationApi } from "../redux";
import { connect } from "react-redux";
import BlogCard from "../common/blogCard.jsx";
import { imgUrl } from "../redux/config.jsx";
import { Link, useLocation, useParams } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { usePathLanguage } from "../hooks/usePathLanguage.js";

const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 991,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
        },
    }, {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
        },
    },
    ],
};
const settings1 = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
};
const slideLandmark = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            },
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        }
    ],
};



const Home = ({ mediaRelationApi, media, blogApi, blog }) => {
    const { t } = useTranslation();
    const { lng } = useParams();
    const pathLanguage = usePathLanguage();
    useEffect(() => {
        blogApi();
        mediaRelationApi(1, '', '');
    }, []);

    const [isShown, setIsShown] = useState(false);

    const handleClick = () => {
        setIsShown(current => !current);
    };

    if (Object.keys(blog).length) {
        const blogData = { ...blog.data };
        var mediaList = blogData.Blogs.slice(0, 9);
    }

    if (Object.keys(media).length) {
        const mediaData = { ...media.data };
        const data = JSON.parse(mediaData.media);
        var newList = data.slice(0, 9);
    }

    const dateFormat = (date) => {
        let data = new Date(date);
        data = data.toGMTString();
        data = data.substring(4, 16);
        return data;
    }


    const [projectone, setProjectone] = useState(false);
    const closeProjectone = () => setProjectone(false);

    const [disclaimer, setDisclaimer] = useState(false);
    const [forDisclaimer, setForDisclaimer] = useState(true);
    const closeDisclaimer = () => {
        setDisclaimer(false);
        setForDisclaimer(false);
    };


    const [projecttwo, setProjecttwo] = useState(false);
    const closeProjecttwo = () => setProjecttwo(false);

    const [projectthree, setProjectthree] = useState(false);
    const closeProjectthree = () => setProjectthree(false);

    const [projectfour, setProjectfour] = useState(false);
    const closeProjectfour = () => setProjectfour(false);

    const [projectfive, setProjectfive] = useState(false);
    const closeProjectfive = () => setProjectfive(false);

    const [projectsix, setProjectsix] = useState(false);
    const closeProjectsix = () => setProjectsix(false);

    const [projectseven, setProjectseven] = useState(false);
    const closeProjectseven = () => setProjectseven(false);

    const [projecteight, setProjecteight] = useState(false);
    const closeProjecteight = () => setProjecteight(false);

    const [projectnine, setProjectnine] = useState(false);
    const closeProjectnine = () => setProjectnine(false);

    const [projectten, setProjectten] = useState(false);
    const closeProjectten = () => setProjectten(false);


    const [lifenov1, setlifenov1] = useState(false);
    const closelifenov1 = () => setlifenov1(false);

    const [lifenov2, setlifenov2] = useState(false);
    const closelifenov2 = () => setlifenov2(false);

    const [lifenov3, setlifenov3] = useState(false);
    const closelifenov3 = () => setlifenov3(false);

    const [lifenov4, setlifenov4] = useState(false);
    const closelifenov4 = () => setlifenov4(false);

    useEffect(() => {
        if (forDisclaimer) {
            setTimeout(() => {
                setDisclaimer(true)
                setForDisclaimer(false)
            }, 3000);
        }
    }, []);

    const showComponent = ((varname) => {
        switch (varname) {

            case "projectone":
                setProjectone(true);
                break;
            case "projecttwo":
                setProjecttwo(true);
                break;
            case "projectthree":
                setProjectthree(true);
                break;
            case "projectfour":
                setProjectfour(true);
                break;
            case "projectfive":
                setProjectfive(true);
                break;
            case "projectsix":
                setProjectsix(true);
                break;
            case "projectseven":
                setProjectseven(true);
                break;
            case "projecteight":
                setProjecteight(true);
                break;
            case "projectnine":
                setProjectnine(true);
                break;
            case "projectten":
                setProjectten(true);
                break;

            case "lifenov1":
                setlifenov1(true);
                break;
            case "lifenov2":
                setlifenov2(true);
                break;
            case "lifenov3":
                setlifenov3(true);
                break;
            case "lifenov4":
                setlifenov4(true);
                break;


            default: return;
        }
    });

    const slider = useRef();
    const nslider = useRef();
    const slideNext = () => {
        slider.current.slickNext();
        nslider.current.slickNext();
    }

    const slidePrev = () => {
        slider.current.slickPrev();
        nslider.current.slickPrev();
    }

    return (
        <div className="home">
            <Helmet>
                <title>Best Cement Company | Cement Suppliers Near Me - Nuvoco Vistas</title>
                <meta name="keywords" description="" />
                <link rel="canonical" href="https://nuvoco.com/" />
                <meta name="description" content="Nuvoco Vistas is one of the leading cement suppliers in India and rated as one of the best cement dealers and manufacturers. It has cement plants across major parts of India." />
            </Helmet>

            {/* <ReactPlayer url={VideoFile} playing="true" /> */}


            <div>
                <div className="banner text-center wow fadeIn">
                    <div>
                        <Carousel>
                            {/* <Carousel.Item>
                            <img className="imgbannerdesktop" src={banner10} alt="" />
                            {<img className="imgbannermobile" src={banner10mobile} alt="" /> }
                        </Carousel.Item> */}
                            <Carousel.Item>
                                <img className="imgbannerdesktop" src={lng != supportedLngsObj.HINDI ? banner6 : banner6} alt="" />
                                {<img className="imgbannermobile" src={lng != supportedLngsObj.HINDI ? banner6 : banner6} alt="" />}
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="imgbannerdesktop" src={lng != supportedLngsObj.HINDI ? banner1 : bannerhi1} alt="" />
                                {<img className="imgbannermobile" src={lng != supportedLngsObj.HINDI ? banner1 : bannerhi1} alt="" />}
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="imgbannerdesktop" src={lng != supportedLngsObj.HINDI ? banner2 : bannerhi2} alt="" />
                                {<img className="imgbannermobile" src={lng != supportedLngsObj.HINDI ? banner2 : bannerhi2} alt="" />}
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="imgbannerdesktop" src={lng != supportedLngsObj.HINDI ? banner3 : bannerhi3} alt="" />
                                {<img className="imgbannermobile" src={lng != supportedLngsObj.HINDI ? banner3 : bannerhi3} alt="" />}
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="imgbannerdesktop" src={lng != supportedLngsObj.HINDI ? banner4 : bannerhi4} alt="" />
                                {<img className="imgbannermobile" src={lng != supportedLngsObj.HINDI ? banner4 : bannerhi4} alt="" />}
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="imgbannerdesktop" src={lng != supportedLngsObj.HINDI ? banner5 : bannerhi5} alt="" />
                                {<img className="imgbannermobile" src={lng != supportedLngsObj.HINDI ? banner5 : bannerhi5} alt="" />}
                            </Carousel.Item>
                        </Carousel>
                    </div>


                </div>

                <section className="about_us">
                    <img className="a_icon about_01 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.5s" src={about01} alt=""></img>
                    <img className="a_icon about_02 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s" src={about02} alt=""></img>
                    <img className="a_icon about_03 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.7s" src={about03} alt=""></img>
                    <img className="a_icon about_04 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s" src={about04} alt=""></img>
                    <Container>
                        <Row className="align-center">
                            <Col sm={5}>
                                <div className="about_img">
                                    <div className="wow fadeInLeft" data-wow-delay="0.6s">
                                        <img src={homeAbout} alt='' />
                                    </div>
                                    <div className="inner_box"></div>
                                </div>
                            </Col>
                            <Col>
                                <div className="wow fadeInDown abouttext-section" data-wow-delay="0.6s">

                                    <h1><Link to={`${pathLanguage}about-us`}>{t("home.best_cement_manufacturers")}</Link></h1>
                                    <p className="title">{t("home.best_cement_manufacturers_description")}</p>
                                    <Button variant="success" className="read_more" as={Link} to={`${pathLanguage}about-us`}>{t("home.read_more")} <img src={rightIcon} alt='' /> </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="our_businesses text-center card_round card_hover">
                    <img className="a_icon ourbs01 wow fadeInUp" data-wow-delay="0.5s" src={ourbs01} alt=""></img>
                    <img className="a_icon ourbs02 wow fadeInUp" data-wow-delay="0.7s" src={ourbs02} alt=""></img>
                    <Container>
                        <div className="wow fadeInDown" data-wow-delay="0.5s">
                            <h1>{t("home.our_businesses.title")}</h1>
                            <p className="title">{t("home.our_businesses.description")}</p>
                        </div>
                        <Row>
                            <Col>
                                <Card className="wow fadeInUp" data-wow-delay="0.5s">
                                    <Link to={`${pathLanguage}top-cement-company`}><Card.Img variant="top" src={cementHome} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">{t("home.our_businesses.products.cement")}</Card.Title>
                                        </Card.Body>
                                    </Link>
                                </Card>
                            </Col>

                            <Col>
                                <Card className="wow fadeInUp cardHome" data-wow-delay="0.7s">
                                    <Link to={`${pathLanguage}ready-mix-concrete`}><Card.Img variant="top" src={readyMix} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">{t("home.our_businesses.products.ready_mix_concrete")}</Card.Title>
                                        </Card.Body>
                                    </Link>
                                </Card>
                            </Col>

                            <Col>
                                <Card className="wow fadeInUp" data-wow-delay="0.9s">
                                    <Link to={`${pathLanguage}modern-building-materials`}><Card.Img variant="top" src={mbuilding} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">{t("home.our_businesses.products.modern_building_materials")}</Card.Title>
                                        </Card.Body>
                                    </Link>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </section>

                <HomeOurBarnd />

                <section className="home_asist card_round our_brands" id='home_assit'>
                    <img className="a_icon brand02 wow fadeInUp" data-wow-delay="0.5s" src={brand02} alt='' />
                    <img className="a_icon brand03 wow fadeInUp" data-wow-delay="0.6s" src={brand03} alt='' />
                    <img className="a_icon brand04 wow fadeInUp" data-wow-delay="0.7s" src={brand04} alt='' />
                    <img className="a_icon brand05 wow fadeInUp" data-wow-delay="0.8s" src={brand05} alt='' />
                    <img className="a_icon brand06 wow fadeInUp" data-wow-delay="0.9s" src={brand06} alt='' />

                    <img className="a_icon assit01 wow fadeInUp" data-wow-delay="0.10s" src={assit01} alt='' />
                    <img className="a_icon assit02 wow fadeInUp" data-wow-delay="0.11s" src={assit02} alt='' />
                    <img className="a_icon assit03 wow fadeInUp" data-wow-delay="0.12s" src={assit03} alt='' />
                    <img className="a_icon assit04 wow fadeInUp" data-wow-delay="0.13s" src={assit04} alt='' />
                    <Container>
                        <Row>
                            <Col md={12} lg={5} className="wow fadeInDown" data-wow-delay="0.5s">
                                <h1>{t("home.nuvonirman.title")}</h1>
                                <p className="title">{t("home.nuvonirman.description")}</p>
                            </Col>

                            <Col md={12} lg={7} className="wow fadeInRight" data-wow-delay="0.5s">
                                <img className="assit_banner" src={homeAsist} alt='' />
                            </Col>
                        </Row>

                        <div className="brand_carousel text-center">
                            <Row>
                                <Col className="wow fadeInUp col-6" md={3} data-wow-delay="0.5s">
                                    <a href="https://nuvonirmaan.com/construction-stages/sub-structure/" rel="noreferrer" target="_blank" title="Construction Stages">
                                        <Card>
                                            <Card.Img variant="top" src={construction} />
                                            <p className="g_invert">&nbsp;</p>
                                        </Card>
                                        <h4>{t("home.nuvonirman.stages.construction_stages")}</h4>
                                    </a>
                                </Col>
                                <Col className="wow fadeInUp col-6" md={3} data-wow-delay="0.6s">
                                    <a href="https://nuvonirmaan.com/house-plans/" rel="noreferrer" target="_blank" title="House Plans">
                                        <Card>
                                            <Card.Img variant="top" src={house} />
                                            <p className="g_invert">&nbsp;</p>
                                        </Card>
                                        <h4>{t("home.nuvonirman.stages.house_plans")}</h4>
                                    </a>
                                </Col>
                                <Col className="wow fadeInUp col-6" md={3} data-wow-delay="0.7s">
                                    <a href="https://nuvonirmaan.com/#ApplicationGuide" rel="noreferrer" target="_blank" title="Application Guide">
                                        <Card>
                                            <Card.Img variant="top" src={application} />
                                            <p className="g_invert">&nbsp;</p>
                                        </Card>
                                        <h4>{t("home.nuvonirman.stages.application_guide")}</h4>
                                    </a>
                                </Col>
                                <Col className="wow fadeInUp col-6" md={3} data-wow-delay="0.8s">
                                    <a href="https://nuvonirmaan.com/calculators/" rel="noreferrer" target="_blank" title="Cost Calculator">
                                        <Card>
                                            <Card.Img variant="top" className="cost" src={cost} />
                                            <p className="g_invert">&nbsp;</p>
                                        </Card>
                                        <h4>{t("home.nuvonirman.stages.cost_calculator")}</h4>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>

                <section className="landmark card_round">
                    <img className="a_icon landmark01 wow fadeInUp" data-wow-delay="0.5s" src={landmark01} alt='' />
                    <img className="a_icon landmark02 wow fadeInUp" data-wow-delay="0.6s" src={landmark02} alt='' />
                    <img className="a_icon landmark03 wow fadeInUp" data-wow-delay="0.7s" src={landmark03} alt='' />
                    <img className="a_icon landmark04 wow fadeInUp" data-wow-delay="0.8s" src={landmark04} alt='' />
                    <img className="a_icon landmark05 wow fadeInUp" data-wow-delay="0.9s" src={landmark05} alt='' />
                    <img className="a_icon landmark06 wow fadeInUp" data-wow-delay="0.10s" src={landmark06} alt='' />
                    <img className="a_icon landmark07 wow fadeInUp" data-wow-delay="0.11s" src={landmark07} alt='' />
                    <img className="a_icon landmark08 wow fadeInUp" data-wow-delay="0.12s" src={landmark08} alt='' />

                    <Container className="full">
                        <div className="fullSilder">
                            <div className="slide_cta">
                                <Slider ref={(c) => (slider.current = c)} {...slideLandmark}>
                                    <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                        <Card onClick={() => showComponent('projectone')}>
                                            <Card.Img variant="top" src={landmarkcenter01} />
                                            <Card.Body className="wow fadeInRight" data-wow-delay="0.7s">
                                                <Card.Title>{t("home.landmark_projects.project10.project_name")}</Card.Title>
                                                <p>{t("home.landmark_projects.project10.project_location")}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                        <Card onClick={() => showComponent('projecttwo')}>
                                            <Card.Img variant="top" src={landmarkcenter02} />
                                            <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                                <Card.Title>{t("home.landmark_projects.project1.project_name")}</Card.Title>
                                                <p>{t("home.landmark_projects.project1.project_location")}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                        <Card onClick={() => showComponent('projectthree')}>
                                            <Card.Img variant="top" src={landmarkcenter03} />
                                            <Card.Body className="wow fadeInRight" data-wow-delay="0.7s">
                                                <Card.Title>{t("home.landmark_projects.project2.project_name")}</Card.Title>
                                                <p>{t("home.landmark_projects.project2.project_location")}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                        <Card onClick={() => showComponent('projectfour')}>
                                            <Card.Img variant="top" src={landmarkcenter04} />
                                            <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                                <Card.Title>{t("home.landmark_projects.project3.project_name")}</Card.Title>
                                                <p>{t("home.landmark_projects.project3.project_location")}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                    <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                        <Card onClick={() => showComponent('projectfive')}>
                                            <Card.Img variant="top" src={landmarkcenter05} />
                                            <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                                <Card.Title>{t("home.landmark_projects.project4.project_name")}</Card.Title>
                                                <p>{t("home.landmark_projects.project4.project_location")}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                    <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                        <Card onClick={() => showComponent('projectsix')}>
                                            <Card.Img variant="top" src={landmarkcenter06} />
                                            <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                                <Card.Title>{t("home.landmark_projects.project5.project_name")}</Card.Title>
                                                <p>{t("home.landmark_projects.project5.project_location")}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                    <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                        <Card onClick={() => showComponent('projectseven')}>
                                            <Card.Img variant="top" src={landmarkcenter07} />
                                            <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                                <Card.Title>{t("home.landmark_projects.project6.project_name")}</Card.Title>
                                                <p>{t("home.landmark_projects.project6.project_location")}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                    <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                        <Card onClick={() => showComponent('projecteight')}>
                                            <Card.Img variant="top" src={landmarkcenter08} />
                                            <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                                <Card.Title>{t("home.landmark_projects.project7.project_name")}</Card.Title>
                                                <p>{t("home.landmark_projects.project7.project_location")}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                    <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                        <Card onClick={() => showComponent('projectnine')}>
                                            <Card.Img variant="top" src={landmarkcenter09} />
                                            <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                                <Card.Title>{t("home.landmark_projects.project8.project_name")}</Card.Title>
                                                <p>{t("home.landmark_projects.project8.project_location")}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                    <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                        <Card onClick={() => showComponent('projectten')}>
                                            <Card.Img variant="top" src={landmarkcenter10} />
                                            <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                                <Card.Title>{t("home.landmark_projects.project9.project_name")}</Card.Title>
                                                <p>{t("home.landmark_projects.project9.project_location")}</p>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                </Slider>
                            </div>
                            <div className="right_cta wow fadeInDown" data-wow-delay="0.5s">
                                <h1>{t("home.landmark_projects.title")}</h1>
                                <h4>{t("home.landmark_projects.subtitle")}​</h4>
                                <p className="title mb-6">{t("home.landmark_projects.description")} </p>
                                <div className="d-flex">
                                    <button type="button" data-role="none" onClick={slidePrev} class="slick-arrow slick-prev"> Previous</button>
                                    <button type="button" data-role="none" onClick={slideNext} class="slick-arrow slick-next"> Next</button>
                                    <Button variant="success" as={Link} to={{
                                        pathname: `${pathLanguage}ready-mix-concrete`,
                                        hash: "#landmark",
                                    }}>{t("view_all")}</Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>

                <Map />

                <section className="sustainability card_round text-center card_hover">
                    <img className="a_icon sus01 wow fadeInUp" data-wow-delay="0.5s" src={footprint09} alt='' />
                    <img className="a_icon sus02 wow fadeInUp" data-wow-delay="0.6s" src={sus01} alt='' />
                    <img className="a_icon sus03 wow fadeInUp" data-wow-delay="0.7s" src={sus03} alt='' />
                    <img className="a_icon sus08 wow fadeInUp" data-wow-delay="0.8s" src={sus08} alt='' />
                    <img className="a_icon sus09 wow fadeInUp" data-wow-delay="0.9s" src={sus09} alt='' />
                    <img className="a_icon sus10 wow fadeInUp" data-wow-delay="0.10s" src={sus10} alt='' />
                    <img className="a_icon sus11 wow fadeInUp" data-wow-delay="0.11s" src={sus11} alt='' />
                    <div className="bg_img">
                        <Container className="wow fadeInDown" data-wow-delay="0.5s">
                            <a href={`${pathLanguage}sustainability`}><h1>{t("home.sustainability.title")}</h1></a>
                            <p className="title" dangerouslySetInnerHTML={ { __html: t("home.sustainability.description") } }></p>
                            <Button variant="success" className="read_more" as={Link} to={`${pathLanguage}sustainability`}>{t("home.read_more")}</Button>
                        </Container>
                    </div>

                    <div className="st_cta">
                        <Container>
                            <div className="sus_in">
                                <Row>
                                    <Col className="wow fadeInUp" data-wow-delay="0.5s">
                                        <Link to='/environment'>  <Card>
                                            <Card.Img variant="top" src={st01} />
                                            <Card.Body>
                                                <Card.Title className="g_invert">{t("home.sustainability.environment")}</Card.Title>
                                            </Card.Body>
                                        </Card></Link>
                                    </Col>

                                    <Col className="wow fadeInUp" data-wow-delay="0.7s">
                                        <Link to='/social'>  <Card>
                                            <Card.Img variant="top" src={st02} />
                                            <Card.Body>
                                                <Card.Title className="g_invert">{t("home.sustainability.social")} </Card.Title>
                                            </Card.Body>
                                        </Card>
                                        </Link>
                                    </Col>

                                    <Col className="wow fadeInUp" data-wow-delay="0.8s">
                                        <Link to='/governance'>  <Card>
                                            <Card.Img variant="top" src={st03} />
                                            <Card.Body>
                                                <Card.Title className="g_invert">{t("home.sustainability.governance")}</Card.Title>
                                            </Card.Body>
                                        </Card></Link>
                                    </Col>

                                </Row>
                            </div>
                        </Container>
                    </div>
                </section>
                {lng != supportedLngsObj.HINDI && (
                    <section className="news card_round">
                        <img className="a_icon sus04 wow fadeInUp" data-wow-delay="0.5s" src={sus04} alt='' />
                        <img className="a_icon sus05 wow fadeInUp" data-wow-delay="0.6s" src={sus05} alt='' />
                        <img className="a_icon sus06 wow fadeInUp" data-wow-delay="0.7s" src={sus06} alt='' />
                        <img className="a_icon sus07 wow fadeInUp" data-wow-delay="0.8s" src={sus07} alt='' />
                        <img className="a_icon news04 wow fadeInUp" data-wow-delay="0.9s" src={news04} alt='' />
                        <img className="a_icon news05 wow fadeInUp" data-wow-delay="0.10s" src={news05} alt='' />
                        <img className="a_icon news06 wow fadeInUp" data-wow-delay="0.11s" src={footprint02} alt='' />
                        <Container className="full">
                            <div className="fullSilder">
                                <div className="slide_cta">
                                    {(Object.keys(media).length && (
                                        <Slider ref={(c) => (nslider.current = c)} {...settings1}>
                                            {newList.map((slide, index) => {
                                                return (
                                                    <div key={index} className="wow fadeInLeft blog" data-wow-delay="0.5s">
                                                        <BlogCard item={slide} id={1} uri={imgUrl} />
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    )) || ''}

                                </div>
                                <div className="right_cta wow fadeInDown nuvocoNewsSection" data-wow-delay="0.5s">

                                    <h1><Link to='/media'>{t("home.nuvoco_news.title")}</Link></h1>

                                    <div className="d-flex">
                                        <button type="button" data-role="none" onClick={slidePrev} class="slick-arrow slick-prev"> Previous</button>
                                        <button type="button" data-role="none" onClick={slideNext} class="slick-arrow slick-next"> Next</button>
                                        <Button variant="success" as={Link} to="/media/Press-Releases" className="nuvocoNewsinner">{t("view_all")}</Button>
                                    </div>

                                </div>
                            </div>
                        </Container>
                    </section>
                )}
                {lng != supportedLngsObj.HINDI && (
                    <section className="blog text-center card_round wow fadeInUp" data-wow-delay="0.5s">
                        <img className="a_icon blog01 wow fadeInUp" data-wow-delay="0.5s" src={blog01} alt='' />
                        <img className="a_icon blog02 wow fadeInUp" data-wow-delay="0.6s" src={blog02} alt='' />
                        <img className="a_icon blog03 wow fadeInUp" data-wow-delay="0.7s" src={blog03} alt='' />
                        <img className="a_icon blog04 wow fadeInUp" data-wow-delay="0.8s" src={blog04} alt='' />
                        <img className="a_icon blog05 wow fadeInUp" data-wow-delay="0.9s" src={blog05} alt='' />
                        <img className="a_icon blog06 wow fadeInUp" data-wow-delay="0.10s" src={landmark08} alt='' />
                        <Container>

                            <h1 className="BlogHeadcut"><Link to='/media'>{t("home.home_blogs.title")}</Link></h1>
                            <p className="title">{t("home.home_blogs.description")}</p>
                            {(Object.keys(blog).length && (
                                <Slider {...settings}>
                                    {mediaList.map((slide, index) => {
                                        return (
                                            <div key={index} className="wow fadeInUp" data-wow-delay="0.5s">
                                                <BlogCard item={slide} id={4} uri={imgUrl} />
                                            </div>
                                        );
                                    })}
                                </Slider>
                            )) || ''}
                            <div className="text-right">
                                <Button variant="success" className="read_more" as={Link} to="/media/blog">{t("view_all")} {t("home.home_blogs.title")}</Button>
                            </div>
                        </Container>
                    </section>
                )}
                <section className="life_novoco card_round">
                    <img className="a_icon life01 wow fadeInUp" data-wow-delay="0.5s" src={life01} alt='' />
                    <img className="a_icon life02 wow fadeInUp" data-wow-delay="0.6s" src={life02} alt='' />
                    <img className="a_icon life03 wow fadeInUp" data-wow-delay="0.7s" src={life03} alt='' />
                    <img className="a_icon life04 wow fadeInUp" data-wow-delay="0.8s" src={life04} alt='' />
                    <img className="a_icon life05 wow fadeInUp" data-wow-delay="0.9s" src={life05} alt='' />
                    <img className="a_icon life06 wow fadeInUp" data-wow-delay="0.10s" src={life06} alt='' />
                    <img className="a_icon life07 wow fadeInUp" data-wow-delay="0.11s" src={life07} alt='' />
                    <img className="a_icon life08 wow fadeInUp" data-wow-delay="0.12s" src={life08} alt='' />
                    <Container>
                        <Row>
                            <Col md={12} lg={5} className="wow fadeInDown" data-wow-delay="0.5s">

                                <h1><Link to={`${pathLanguage}life-at-nuvoco`}>{t("home.life_nuvoco.title")}</Link></h1>

                                <p className="title">{t("home.life_nuvoco.description")}</p>
                            </Col>
                            <Col lg={7}>
                                <Row>
                                    <Col md={6} className="wow fadeInUp mb-4" data-wow-delay="0.5s">
                                        <Card onClick={() => showComponent('lifenov1')}>
                                            <Card.Img variant="top" src={total} />
                                            <Card.Body>
                                                <Card.Title className="g_invert">{t("home.life_nuvoco.totla_rewards")}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col md={6} className="wow fadeInUp mb-4" data-wow-delay="0.6s">
                                        <Card onClick={() => showComponent('lifenov2')}>
                                            <Card.Img variant="top" src={learning} />
                                            <Card.Body>
                                                <Card.Title className="g_invert">{t("home.life_nuvoco.learning_development")}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={6} className="wow fadeInUp mb-4" data-wow-delay="0.7s">
                                        <Card onClick={() => showComponent('lifenov3')}>
                                            <Card.Img variant="top" src={employee} />
                                            <Card.Body>
                                                <Card.Title className="g_invert">{t("home.life_nuvoco.employee_engagement")}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={6} className="wow fadeInUp mb-4" data-wow-delay="0.8s">
                                        <Card onClick={() => showComponent('lifenov4')}>
                                            <Card.Img variant="top" src={job} />
                                            <Card.Body>
                                                <Card.Title className="g_invert">{t("home.life_nuvoco.growth_accelerator")}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Disclaimer Modal */}
                {/* <Modal  size="lg" centered="true"
        show={disclaimer} onHide={closeDisclaimer} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
            <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <article className="p-4">
                            <h4>Khodhaldham Temple</h4>
                            <p>One of the largest comprehensive Hindu Temples in India that is 299 feet tall. It is renowned for setting Guinness World Record in the category of the highest number of people (3.5 lakhs) singing the national anthem at a single venue. Nuvoco’s Artiste installed on the steps that cover a 39,600 sq. ft. area and has footfall as high as three lakhs.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal> */}
                <Modal size="lg" centered="true"
                    show={disclaimer} onHide={closeDisclaimer} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            <Col md={12} className="d-flex align-items-center">
                                <h2 className='grad-heading pd-5 m-0'>{t("home.disclaimer.title")}</h2>
                            </Col>
                            <Col md={12}>

                                <div className="d-flex align-item-center">
                                    <article className="p-4">
                                        <p>{t("home.disclaimer.greeting")}</p>
                                        <p>{t("home.disclaimer.description_1")}</p>
                                        <p dangerouslySetInnerHTML={{ __html: t("home.disclaimer.description_2") }}></p>
                                        <p><strong>{t("home.disclaimer.investor_relations")}</strong></p>
                                    </article>
                                </div>


                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>


                <Modal size="lg" centered="true"
                    show={projectone} onHide={closeProjectone} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            <Col md={12}>

                                <div className="align-item-center productlandmark">
                                    <img src={landmarkcenter01} alt='' />
                                    <article className="p-4">
                                        <h4><Card.Title>{t("home.landmark_projects.project10.project_name")}</Card.Title></h4>
                                        <p>{t("home.landmark_projects.project10.project_description")}</p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={projecttwo} onHide={closeProjecttwo} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            <Col md={12}>

                                <div className="align-item-center productlandmark">
                                    <img src={landmarkcenter02} alt='' />
                                    <article className="p-4">
                                        <h4><Card.Title>{t("home.landmark_projects.project1.project_name")}</Card.Title></h4>
                                        <p>{t("home.landmark_projects.project1.project_description")}</p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={projectthree} onHide={closeProjectthree} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center productlandmark">
                                    <img src={landmarkcenter03} alt='' />
                                    <article className="p-4">
                                        <h4><Card.Title>{t("home.landmark_projects.project2.project_name")}</Card.Title></h4>
                                        <p>{t("home.landmark_projects.project2.project_description")}</p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={projectfour} onHide={closeProjectfour} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center productlandmark">
                                    <img src={landmarkcenter04} alt='' />
                                    <article className="p-4">
                                        <h4><Card.Title>{t("home.landmark_projects.project3.project_name")}</Card.Title></h4>
                                        <p>{t("home.landmark_projects.project3.project_description")}</p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={projectfive} onHide={closeProjectfive} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center productlandmark">
                                    <img src={landmarkcenter05} alt='' />
                                    <article className="p-4">
                                        <h4><Card.Title>{t("home.landmark_projects.project4.project_name")}</Card.Title></h4>
                                        <p>{t("home.landmark_projects.project4.project_description")}</p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={projectsix} onHide={closeProjectsix} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center productlandmark">
                                    <img src={landmarkcenter06} alt='' />
                                    <article className="p-4">
                                        <h4><Card.Title>{t("home.landmark_projects.project5.project_name")}</Card.Title></h4>
                                        <p>{t("home.landmark_projects.project5.project_description")}</p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={projectseven} onHide={closeProjectseven} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center productlandmark">
                                    <img src={landmarkcenter07} alt='' />
                                    <article className="p-4">
                                        <h4><Card.Title>{t("home.landmark_projects.project6.project_name")}</Card.Title></h4>
                                        <p>{t("home.landmark_projects.project6.project_description")}</p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={projecteight} onHide={closeProjecteight} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center productlandmark">
                                    <img src={landmarkcenter08} alt='' />
                                    <article className="p-4">
                                        <h4><Card.Title>{t("home.landmark_projects.project7.project_name")}</Card.Title></h4>
                                        <p>{t("home.landmark_projects.project7.project_description")}</p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={projectnine} onHide={closeProjectnine} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center productlandmark">
                                    <img src={landmarkcenter09} alt='' />
                                    <article className="p-4">
                                        <h4><Card.Title>{t("home.landmark_projects.project8.project_name")}</Card.Title></h4>
                                        <p>{t("home.landmark_projects.project8.project_description")}</p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={projectten} onHide={closeProjectten} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center productlandmark">
                                    <img src={landmarkcenter10} alt='' />
                                    <article className="p-4">
                                        <h4><Card.Title>{t("home.landmark_projects.project9.project_name")}</Card.Title></h4>
                                        <p>{t("home.landmark_projects.project9.project_description")}</p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>

                {/* life at nuvoco popup modal */}


                <Modal size="lg" centered="true"
                    show={lifenov1} onHide={closelifenov1} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center lifepopupdesign">
                                    <article className="p-4">
                                        <h4>{t("home.life_nuvoco.totla_rewards")}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: t("home.life_nuvoco.totla_rewards_desc") }}></p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={lifenov2} onHide={closelifenov2} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center lifepopupdesign">
                                    <article className="p-4">
                                        <h4>{t("home.life_nuvoco.learning_development")}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: t("home.life_nuvoco.learning_development_desc") }}></p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={lifenov3} onHide={closelifenov3} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center lifepopupdesign">
                                    <article className="p-4">
                                    <h4>{t("home.life_nuvoco.employee_engagement")}</h4>
                                    <p dangerouslySetInnerHTML={{ __html: t("home.life_nuvoco.employee_engagement_desc") }}></p>

                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={lifenov4} onHide={closelifenov4} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center lifepopupdesign">
                                    <article className="p-4">
                                    <h4>{t("home.life_nuvoco.growth_accelerator")}</h4>
                                    <p dangerouslySetInnerHTML={{ __html: t("home.life_nuvoco.growth_accelerator_desc") }}></p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
            </div>



        </div>


    );
}

const mapStatetoProps = (state) => {
    return {
        blog: state.mediaR.blog,
        media: state.mediaR.media,
    };
};
const mapDispatchtoProps = (dispatch) => {
    return {
        mediaRelationApi: function (category_id, month_id, year_id) {
            dispatch(mediaRelationApi(category_id, month_id, year_id));
        },
        blogApi: function () {
            dispatch(blogApi());
        },
    };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Home);
