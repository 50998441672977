import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { Navbar } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./languageSwitcher";
import { usePathLanguage } from '../hooks/usePathLanguage';

function MobileMenu() {
    const {t} = useTranslation();
    const pathLanguage = usePathLanguage();
    // redirectAnnual(){
    //  window.open('https://annualreport.nuvoco.com', '_blank');
    // }
    return (
        <>
            <div className='mm_menu wow fadeInRight'>
                <div className="pt-3 ps-3">
                 <LanguageSwitcher/>
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <div className='slide_menu'>
                    <Accordion defaultActiveKey="" flush>
                        <Accordion.Item eventKey="0" className="wow fadeInRight">
                            <Accordion.Header as={Link} to={`${pathLanguage}about-us`}>{t("navigation.aboutus")}</Accordion.Header>
                            <Accordion.Body>
                                <Navbar.Toggle>
                           <Nav.Link as={Link} to={`${pathLanguage}our-journey`}>{t("navigation.ourjourney")}</Nav.Link>
                          <Nav.Link as={Link} to="our-brand-story">{t("navigation.ourbrandstory")}</Nav.Link>
                          <Nav.Link as={Link} to="vision-mission-and-values">{t("navigation.visionmission")}</Nav.Link>
                          <Nav.Link as={Link} to="board-of-directors">{t("navigation.boardofdirectors")}</Nav.Link>
                          <Nav.Link as={Link} to="management-team">{t("navigation.managementteam")}</Nav.Link>
                          <Nav.Link as={Link} to={`${pathLanguage}quality-and-innovation`}>{t("navigation.qualityinnovation")}</Nav.Link>
                                </Navbar.Toggle>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1" className="wow fadeInRight">
                            <Accordion.Header>{t("navigation.ourbusiness")}</Accordion.Header>
                            <Accordion.Body>
                                <Navbar.Toggle>
                                <Nav.Link as={Link} to={`${pathLanguage}top-cement-company`}>{t("navigation.cement")}</Nav.Link>
                          <Nav.Link as={Link} to={`${pathLanguage}ready-mix-concrete`}>{t("navigation.readymixconcrete")} </Nav.Link>
                          <Nav.Link as={Link} to={`${pathLanguage}modern-building-materials`}>{t("navigation.mbm")}</Nav.Link> 
                                </Navbar.Toggle>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2" className="wow fadeInRight">
                            <Accordion.Header> {t("navigation.ourbrands")}</Accordion.Header>
                            <Accordion.Body>
                                <Accordion defaultActiveKey="">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>{t("navigation.cement")}</Accordion.Header>
                                        <Accordion.Body>
                                            <Navbar.Toggle>
                                            
                                                <h3>{t("navigation.concreto")}</h3>
                                                 <Nav.Link as={Link} to={`${pathLanguage}products/concreto-cement`}>{t("navigation.concreto")}</Nav.Link>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/concreto-uno`}>{t("navigation.concretouno")}</Nav.Link>
                                                {/* <Nav.Link as={Link} to="products/concreto-green-cement">Concreto Green</Nav.Link>   */} 
                                                <h3 className="mt-3">{t("navigation.doublebullcement")}</h3>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/double-bull-master-cement`}>{t("navigation.Master")}</Nav.Link> 
                                                <Nav.Link as={Link} to={`${pathLanguage}products/double-bull-ppc`}>{t("navigation.PPC")}</Nav.Link>  
                                                <Nav.Link as={Link} to={`${pathLanguage}products/double-bull-psc`}>{t("navigation.PSC")}</Nav.Link> 
                                                {/* <Nav.Link as={Link} to={`${pathLanguage}products/double-bull-subh-cement">Subh</Nav.Link>  */}

                                                <h3>{t("navigation.Duraguard")}</h3>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/duraguard-cement`}>{t("navigation.Duraguard")}</Nav.Link> 
                                                <Nav.Link as={Link} to={`${pathLanguage}products/duraguard-microfiber-cement`}>{t("navigation.Microfiber")}</Nav.Link> 
                                                <Nav.Link as={Link} to={`${pathLanguage}products/duraguard-rapidx-cement`}>{t("navigation.RapidX")}</Nav.Link>                                   
                                                {/* <Nav.Link as={Link} to={`${pathLanguage}products/duraguard-waterseal-cement">Waterseal</Nav.Link>  */}
                                                <Nav.Link as={Link} to={`${pathLanguage}products/duraguard-xtra-cement`}>{t("navigation.Xtra")}</Nav.Link>  
                                                <Nav.Link as={Link} to={`${pathLanguage}products/duraguard-f2f`}>{t("navigation.F2F")}</Nav.Link>                               
                                                {/* <Nav.Link as={Link} to={`${pathLanguage}products/duraguard-xtra-cement">Xtra Cement</Nav.Link>  */}

                                                <h3>{t("navigation.Infracem")}</h3>
                                                {/* <Nav.Link as={Link} to={`${pathLanguage}products/double-bull-opc-43">Double Bull - OPC 43</Nav.Link> 
                                                <Nav.Link as={Link} to={`${pathLanguage}products/double-bull-opc-53">Double Bull - OPC 53</Nav.Link> */}
                                                <Nav.Link as={Link} to={`${pathLanguage}products/infracem-cement`}>{t("navigation.Infracem")}</Nav.Link>
                                                {/* <Nav.Link as={Link} to={`${pathLanguage}products/double-bull-procem-cement">Double Bull Procem</Nav.Link> */}                                 
                                                 
                                                <h3>{t("navigation.Nirmax")}</h3>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nirmax-ppc`}>{t("navigation.Nirmax")}</Nav.Link>                                
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nirmax-opc`}>{t("navigation.Nirmax43")}</Nav.Link> 
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nirmax-opc`}>{t("navigation.Nirmax53")}</Nav.Link>

                                                <h3 className="mt-3">{t("navigation.PremiumSlag")}</h3>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/p-s-c`}>{t("navigation.Premium_PSC")}</Nav.Link>
                                                
                                            
                                            </Navbar.Toggle>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>{t("navigation.readymixconcrete")}</Accordion.Header>
                                        <Accordion.Body>
                                            <Navbar.Toggle>
                                            <h3>{t("navigation.Artiste")}</h3>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/artiste-bare`}>{t("navigation.Bare")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/artiste-coarse`}>{t("navigation.Coarse")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/artiste-engrave`}>{t("navigation.Engrave")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/artiste-hue`}>{t("navigation.Hue")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/artiste-lumos`}>{t("navigation.Lumos")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/artiste-signature`}>{t("navigation.Signature")}</Nav.Link>

                                            <h3>{t("navigation.concreto")}</h3>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-agile`}>{t("navigation.Agile")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-agile-flow`}>{t("navigation.AgileFlow")}</Nav.Link> 
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-corrosafe-plus`}>{t("navigation.Corrosafe")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-cwt-plus`}>{t("navigation.CWTPlus")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-easyfille`}>{t("navigation.Easyfille")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-endura`}>{t("navigation.Endura")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-fluide`}>{t("navigation.Fluide")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-glyde`}>{t("navigation.Glyde")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-instante`}>{t("navigation.Instante")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-lente`}>{t("navigation.Lente")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-permadure`}>{t("navigation.Permadure")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-polibre`}>{t("navigation.Polibre")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-regletherme`}>{t("navigation.Regletherme")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-robuste`}>{t("navigation.Robuste")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-steelibre`}>{t("navigation.Steelibre")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-xlite`}>{t("navigation.Xlite")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/concreto-xlite-structure`}>{t("navigation.XliteStructure")}</Nav.Link>
                                        
                                            <h3>{t("navigation.Ecodure")}</h3>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/ecodure`}>{t("navigation.Ecodure")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/ecodure-plus`}>{t("navigation.Plus")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/ecodure-prime`}>{t("navigation.Prime")}</Nav.Link>

                                            <h3 className="mt-3">{t("navigation.instamix")}</h3>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/instamix`}>{t("navigation.instamix")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/instamix-microne`}>{t("navigation.Microne")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/instamix-mortare`}>{t("navigation.mortane")}</Nav.Link>  

                                            <h3 className="mt-3">{t("navigation.X_CON")}</h3>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/x-con`}>{t("navigation.X_CON")}</Nav.Link>
                                            </Navbar.Toggle>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>{t("navigation.mbm")}</Accordion.Header>
                                        <Accordion.Body>
                                            <Navbar.Toggle>
                                                {/* <h3>InstaMix</h3>
                                                <Nav.Link as={Link} to="products/instamix-bond-aid-joining-mortar">Bond-Aid Joining Mortar</Nav.Link>
                                                <Nav.Link as={Link} to="products/instamix-plastosmart-dry-plaster">Plastosmart Dry Plaster</Nav.Link>
                                                <Nav.Link as={Link} to="products/instamix-xpress-dry-bag-concrete">Xpress Dry Bag Concrete</Nav.Link> */}

                                                
                                                <h3>{t("navigation.ZeroM")}</h3>
                                                <h3 className="mt-3 f15">{t("navigation.Waterproofing_Solutions")}</h3>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-iwc`}>{t("navigation.IWC")}</Nav.Link>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-2k`}>{t("navigation.2K")}</Nav.Link>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-multipurpose-latex-expert`}>{t("navigation.Latex_Expert")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-multipurpose-acrylic-expert`}>{t("navigation.Acrylic_Expert")}</Nav.Link>
                                            <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-roof-shield`}>{t("navigation.Roof_Shield")}</Nav.Link>

                                                <h3 className="mt-3 f15">{t("navigation.Tile_Stone_Fixing_Solutions")}</h3>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-tile-adhesive-t1`}>{t("navigation.Tile_T1")}</Nav.Link> 
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-tile-adhesive-t2`}>{t("navigation.Tile_T2")}</Nav.Link>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-tile-adhesive-t3`}>{t("navigation.Tile_T3")}</Nav.Link>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-tile-adhesive-t4`}>{t("navigation.Tile_T4")}</Nav.Link>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-tile-adhesive-t5`}>{t("navigation.Tile_T5")}</Nav.Link> 
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-epoxy-tile-grout`}>{t("navigation.Epoxy_Tile_Grout")}</Nav.Link>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-tile-grout`}>{t("navigation.Tile_Grout")}</Nav.Link>

                                                <h3 className="mt-3 f15">{t("navigation.Surface_Preparation")}</h3>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-wall-putty`}>{t("navigation.Wall_Putty")}</Nav.Link> 
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-dry-plaster`}>{t("navigation.Dry_Plaster")}</Nav.Link>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-dry-concrete`}>{t("navigation.Dry_Concrete")}</Nav.Link>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-block-jointing-mortar`}>{t("navigation.Block_Joining")}</Nav.Link>

                                                <h3 className="f15">{t("navigation.Repair_Solutions")}</h3>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-tile-cleaner`}>{t("navigation.Tile_Cleaner")}</Nav.Link>
                                            
                                                <h3 className="mt-3 f15">{t("navigation.Ancillary_Solutions")}</h3>
                                                <Nav.Link as={Link} to={`${pathLanguage}products/nuvoco-zero-m-cover-block`}>{t("navigation.Cover_Block")}</Nav.Link>



                                                
                                            </Navbar.Toggle>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className="wow fadeInRight">
                            <Accordion.Header>{t("navigation.Sustainability")}</Accordion.Header>
                            <Accordion.Body>
                                <Navbar.Toggle>
       
                                <Nav.Link as={Link} to="/environment">{t("navigation.Environment")}</Nav.Link>
                                <Nav.Link as={Link} to="/social">{t("navigation.Social")}</Nav.Link>                    
                                <Nav.Link as={Link} to="/governance">{t("navigation.Governance_ESG")}</Nav.Link>                    
                                <Nav.Link as={Link} to={`${pathLanguage}health-and-safety`}>{t("navigation.Health_Safety")}</Nav.Link>



                                </Navbar.Toggle>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4" className="wow fadeInRight">
                            <Accordion.Header>{t("navigation.Investor_Relations")}</Accordion.Header>
                            <Accordion.Body>
                                <Navbar.Toggle>
                                <Nav.Link as={Link} to="performance-highlights">{t("navigation.Performance_Highlights")}</Nav.Link>
                                <Nav.Link as={Link} to="investors-corner">{t("navigation.Investors_Corner")}</Nav.Link>                    
                                <Nav.Link as={Link} to="corporate-governance">{t("navigation.Corporate_Governance")}</Nav.Link>                    
                                <Nav.Link as={Link} to="annual-reports">{t("navigation.Annual_Reports")}</Nav.Link>
                                    <a className="nav-link" href="https://annualreport.nuvoco.com" target="_blank">{t("navigation.Annual_Report_FY2023_24_Microsite")}</a> 
                                </Navbar.Toggle>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5" className="wow fadeInRight">
                            <Accordion.Header>{t("navigation.Life_Nuvoco")}</Accordion.Header>
                            <Accordion.Body>
                                <Navbar.Toggle>
                                    <Nav.Link as={Link} to={{ pathname: "/vision-mission-and-values", hash: "#ourValue" }}>{t("navigation.Our_Values")}</Nav.Link>
                                    <Nav.Link as={Link} to={`${pathLanguage}life-at-nuvoco#ourPeople`}>{t("navigation.Our_People")}</Nav.Link>
                                    <Nav.Link as={Link} to={`${pathLanguage}our-employee-value-proposition`}>{t("navigation.Our_Employee_Value_Proposition")}</Nav.Link>
                                    <Nav.Link as={Link} to={`${pathLanguage}life-at-nuvoco#awardandrecognition`}>{t("navigation.Awards_Recognition")} </Nav.Link>
                                    <a class="nav-link" href="https://career44.sapsf.com/career?company=nuvocovist" target="_blank" rel="noreferrer">
                                    {t("navigation.Join_Our_Team")}</a>
                                </Navbar.Toggle>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Navbar.Toggle>
                        <Nav.Link as={Link} to={`${pathLanguage}media/Press-Releases`} className="wow fadeInRight">{t("navigation.media")}</Nav.Link>
                        <Nav.Link as={Link} to={`${pathLanguage}contact-us`} className="wow fadeInRight">{t("navigation.Contactus")} </Nav.Link>
                    </Navbar.Toggle>
                </div>
            </div>
        </>

    );
}

export default MobileMenu;