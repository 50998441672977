import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { usePathLanguage } from '../hooks/usePathLanguage';

function LanguageDisclaimer({ modalOpen, closeModal }) {
  const { t } = useTranslation();
  const pathLanguage = usePathLanguage();

  return (
    <Modal
      size="lg"
      centered="true"
      show={modalOpen}
      onHide={closeModal}
      backdrop={false}
      className="bob_modal bob_modal1 product_modal our_brand_inner card_round"
    >
      <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
          <Col md={12} className="d-flex align-items-center">
            <h2 className="grad-heading pd-5 m-0">
              {t("home.disclaimer.title")}
            </h2>
          </Col>
          <Col md={12}>
            <div className="d-flex align-item-center">
              <article className="p-4">
              
                <p>{t("home.disclaimer.english_language_retained")} <a href="/hi">{t("home.disclaimer.hindi_continue")}</a> or <a href="javascript:void(0)" onClick={closeModal} >{t("home.disclaimer.english_continue")}</a></p>

                <p>{t("home.disclaimer.english_language_retained_inHindi")} <a href="/hi">{t("home.disclaimer.hindiButton")}</a> या इस  <a href="javascript:void(0)" onClick={closeModal} >{t("home.disclaimer.englishView")}</a></p>
              </article>
            </div>
           
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default LanguageDisclaimer;
