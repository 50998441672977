import { useLocation } from "react-router-dom";
import { supportedLngsObj } from "../helpers/utils";
import { useEffect, useState } from "react";

export const usePathLanguage = () => {
  const [pathLanguage, setPathLanguage] = useState("");
  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    if (pathSegments.length > 1 && supportedLngsObj.HINDI == pathSegments[1]) {
      setPathLanguage(`/${pathSegments[1]}/`);
    } else {
      setPathLanguage("");
    }
  }, [location]);

  return pathLanguage;
};
