import React, { useState } from "react";
import { Container, Row, Col, Card, Breadcrumb, Button, Modal } from 'react-bootstrap';
import { Helmet } from "react-helmet";

import contact_icon from '../assets/images/contact_icon.png';
import Carousel from 'react-bootstrap/Carousel';
// import banner from '../assets/images/lifeatnuvocobanner.jpg';
import banner from '../assets/images/lifestNuvocoBanner.png';
// import bannermobile from '../assets/images/life-at-nuvoco-mobile.jpg';
import bannermobile from '../assets/images/lifestNuvocoMobileBanner.png';

import lifeicon from '../assets/images/lifeaticon.png';

import total from '../assets/images/Total-Rewards.png';
import learning from '../assets/images/learning-Development.png';
import employee from '../assets/images/EmployeeEngament.png';
import job from '../assets/images/growth-accelerator.png';

import CII16th from '../assets/images/CII16th.jpg';
import JJCP from '../assets/images/JJCP.jpg';
import MEJIA from '../assets/images/MEJIA.jpg';
import SHEAWARDED from '../assets/images/SHEAWARDED.jpg';
import MinesSafety from '../assets/images/MinesSafety.jpg';
import BCP from '../assets/images/BCP.jpg';
import NGRMPW from '../assets/images/NGRMPW.jpg';
import GPA from '../assets/images/GPA.jpg';
import StrategyOfTheYear from '../assets/images/StrategyOfTheYear.jpg';
import cwtpa from '../assets/images/cwtpa.jpg';
import IRIM from '../assets/images/IRIM.jpg';
import fiveStarAward from '../assets/images/fiveStarAward.jpg';
import WHRS from '../assets/images/WHRS.jpg';
import MEMC from '../assets/images/MEMC.jpg';
import SEEM from '../assets/images/SEEM.jpg';
import RMXGreenPro from '../assets/images/RMXGreenPro.jpg';
import JOJOBera5Star from '../assets/images/JOJOBera5Star.jpg';
import awardCommon from '../assets/images/awardCommon.jpg';
import csrAward from '../assets/images/CSR-Impact-Awards.png';
import shikshaShreeAward from '../assets/images/Shiksha-Shree-Award.png';
import indianCSRAward from '../assets/images/indian-CSR-Award.jpg';
import scaleAward from '../assets/images/scale.jpeg';
import GoldenPeacockAward from '../assets/images/GoldenPeacockAward.jpeg';
import CiiNational from '../assets/images/CIINational.png';
import MejiaCementPlant from '../assets/images/Mejia-Cement-Plant.png';
import NationalSustainabilityCompetitionRMX from '../assets/images/National-Sustainability-CompetitionRMX.png';
import ChittorCementAchievedQCFI from '../assets/images/ChittorCementAchievedQCFI.png';
import FastestDecarbonising from '../assets/images/Nuvoco-Awarded-Fastest-Decarbonising-Cement-Company.png';
import SonadihLimestoneMines from '../assets/images/SonadihLimestoneMines.png';
import GoldenPetalAwardNimbol from '../assets/images/Golden-Petal-Award-to-Nimbol-Cement-Plant.png';
import GlobalHealthCareWellness from '../assets/images/GlobalHealthCareWellness.png';

import smart from '../assets/images/smart.jpg';
import smart1 from '../assets/images/smart1.jpg';
import smart2 from '../assets/images/smart2.jpg';
import smarticon from '../assets/images/smarticon.png';
import smarticon1 from '../assets/images/smarticon1.png';
import smarticon2 from '../assets/images/smarticon2.png';
import safer2018 from '../assets/images/safer2018.jpg';
import smarter2018 from '../assets/images/smarter2018.jpg';
import sustainable2018 from '../assets/images/sustainable2018.jpg';
import safer2019 from '../assets/images/safer2019-1.png';
import safer20192 from '../assets/images/safer2019-2.png';
import safer20201 from '../assets/images/safer20201.jpg';
import safer20202 from '../assets/images/safer20202.jpg';
import smarter20211 from '../assets/images/smarter20211.jpg';
import awardblur from '../assets/images/award-blur.jpg';
import safer20221 from '../assets/images/safer20221.jpg';
import safer20223 from '../assets/images/safer20223.jpg';
import IndianGreenManufacturer from '../assets/images/IndianGreenManufacturer.jpeg';
import RisdaAward from '../assets/images/Risda-Award.jpeg';


import smarter20221 from '../assets/images/smarter20221.jpg';
import smarter20222 from '../assets/images/smarter20222.jpg';
import smarter20223 from '../assets/images/smarter20223.jpg';
import smarter20224 from '../assets/images/smarter20224.jpg';

import smarter20231 from '../assets/images/smarter20231.JPG';


import sustainable20222 from '../assets/images/sustainable20222.jpg';
import sustainable20223 from '../assets/images/sustainable20223.jpg';

import smarter20201 from '../assets/images/smarter20201.jpg';
import smarter20202 from '../assets/images/smarter20202.jpg';

import sustainable2020 from '../assets/images/sustainable2020.jpg';

import { Link } from "react-router-dom";
import Slider from "react-slick";
import CareerForm from "../common/careerForm";
import { withTranslation } from "react-i18next";
const settings = {
    //autoplay:true,
    autoplaySpeed: 2000,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};
class LifeatNuvoco extends React.Component {
    constructor(props) {
        super();
        this.state = {
            text2: false,
            text3: false,
            text4: false,
            text5: false,
            text6: false,
            text7: true,
            isOpen: false
        };
        this.hideComponent = this.hideComponent.bind(this);



    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({ disclaimer: true });
        }, 3000);
    }

    openModal1 = () => this.setState({ isOpen1: true });
    closeModal1 = () => this.setState({ isOpen1: false });

    openModal2 = () => this.setState({ isOpen2: true });
    closeModal2 = () => this.setState({ isOpen2: false });

    openModal3 = () => this.setState({ isOpen3: true });
    closeModal3 = () => this.setState({ isOpen3: false });

    openModal4 = () => this.setState({ isOpen4: true });
    closeModal4 = () => this.setState({ isOpen4: false });

    disclaimer = () => this.setState({ disclaimer: true });
    closeDisclaimer = () => this.setState({ disclaimer: false });

    hideComponent(varname) {
        switch (varname) {
            case "text2":
                this.setState({ text1: false, text2: true, text3: false, text4: false, text5: false, text6: false, text7: false });
                break;
            case "text3":
                this.setState({ text1: false, text2: false, text3: true, text4: false, text5: false, text6: false, text7: false });
                break;
            case "text4":
                this.setState({ text1: false, text2: false, text3: false, text4: true, text5: false, text6: false, text7: false });
                break;
            case "text5":
                this.setState({ text1: false, text2: false, text3: false, text4: false, text5: true, text6: false, text7: false });
                break;
            case "text6":
                this.setState({ text1: false, text2: false, text3: false, text4: false, text5: false, text6: true, text7: false });
                break;
            case "text7":
                this.setState({ text1: false, text2: false, text3: false, text4: false, text5: false, text6: false, text7: true });
                break;


            default: return;

        }
    }

    render() {
        const { t } = this.props;
        const { text2, text3, text4, text5, text6, text7 } = this.state;
        return (

            <div className="aboutus-pge inner_page lifeatnuvocopage">
                <Helmet>
                    <title>Awards - Nuvoco Vistas Corp Ltd.</title>
                    <meta name="keywords" description="" />
                    <meta name="description" content="" />
                </Helmet>
                <div className="banner text-center position-relative">
                    <img className='desktopbannerinner' src={banner} alt="" />
                    <img className='mobilebannerinner w-100' src={bannermobile} alt="" />
                    <article className='position-absolute text-center'>
                        <span className='d-flex justify-content-center align-items-center mx-auto'><img src={lifeicon} alt="" /></span>
                        <h1 className="text-uppercase">{t("life_at_nuvoco.pageTitle")}</h1>
                    </article>
                    <div className='breadouter'>
                        <div className='container'>
                            <Breadcrumb className='mb-0 py-2'>
                                <Breadcrumb.Item href="#">{t("breadcrumb.home")}</Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {t("life_at_nuvoco.pageTitle")}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                <div className='midpart mb-5'>

                    <Container>
                        <Row>
                            <Col md={5}>
                                <h2 className='grad-heading'>{t("life_at_nuvoco.title")}</h2>
                                <p>{t("life_at_nuvoco.desc1")}</p>
                                <p>{t("life_at_nuvoco.desc2")}</p>
                                <p>{t("life_at_nuvoco.desc3")} </p>

                            </Col>

                            <Col md={7} className="lifemiddle">
                                <Row>
                                    <Col className="wow fadeInUp mb-4" md={6} data-wow-delay="0.5s">
                                        <Card onClick={this.openModal1}>
                                            <Card.Img variant="top" src={total} />

                                            <Card.Body>
                                                <Card.Title className="g_invert">{t("home.life_nuvoco.totla_rewards")}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col className="wow fadeInUp mb-4" md={6} data-wow-delay="0.6s">
                                        <Card onClick={this.openModal2}>
                                            <Card.Img variant="top" src={learning} />
                                            <Card.Body>
                                                <Card.Title className="g_invert">{t("home.life_nuvoco.learning_development")}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col className="wow fadeInUp" md={6} data-wow-delay="0.7s">
                                        <Card onClick={this.openModal3}>
                                            <Card.Img variant="top" src={employee} />
                                            <Card.Body>
                                                <Card.Title className="g_invert">{t("home.life_nuvoco.employee_engagement")}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col className="wow fadeInUp" md={6} data-wow-delay="0.8s">
                                        <Card onClick={this.openModal4}>
                                            <Card.Img variant="top" src={job} />
                                            <Card.Body>
                                                <Card.Title className="g_invert">{t("home.life_nuvoco.growth_accelerator")}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <div className="award mt-5">
                            <Row>
                                <Col>
                                    <h2 className='grad-heading'>{t("life_at_nuvoco.awards.title")}</h2>

                                    <Container className='text-center LifeTab'>
                                        <Button onClick={() => this.hideComponent("text2")} variant="success" className={text2 ? 'active' : ''}>2019</Button>
                                        <Button onClick={() => this.hideComponent("text3")} variant="success" className={text3 ? 'active' : ''}>2020</Button>
                                        <Button onClick={() => this.hideComponent("text4")} variant="success" className={text4 ? 'active' : ''}>2021</Button>
                                        <Button onClick={() => this.hideComponent("text5")} variant="success" className={text5 ? 'active' : ''}>2022</Button>
                                        <Button onClick={() => this.hideComponent("text6")} variant="success" className={text6 ? 'active' : ''}>2023</Button>
                                        <Button onClick={() => this.hideComponent("text7")} variant="success" className={text7 ? 'active' : ''}>2024</Button>

                                        {text2 ? <div className="card_round lifemt">
                                            <Row className="justify-content-center mb-3">
                                                <Col md={12} className="lifemiddle">
                                                    <Row className="justify-content-center">
                                                        <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain align-items-center" data-wow-delay="0.5s">

                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Safer")}</h4>
                                                                <img src={smarticon} />
                                                            </div>

                                                            <Slider {...settings}>
                                                                <Card>

                                                                    <Card.Img variant="top" src={safer2019} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco's Mejia Cement Plant (MCP) was recognised with a <strong>‘Runner-up Award’ for significant contribution in Safety, Health and Environment (SHE) in Manufacturing</strong> Section by Confederation of Indian Industry (Eastern Region).
                                                                        </p>
                                                                    </Card.Body>
                                                                </Card>


                                                                <Card>

                                                                    <Card.Img variant="top" src={awardblur} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco’s Ready-Mix Concrete (RMX) team bagged the<strong> ‘OHSSAI Occupational Health Safety Environment and Sustainability Award’</strong> at the OSHAI 4th Annual HSE Excellence & Sustainability Awards, 2019.</p>
                                                                    </Card.Body>
                                                                </Card>

                                                                <Card>
                                                                    <Card.Img variant="top" src={safer20192} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco's Chittorgarh Cement Plant (CCP) won the <strong>‘Special Commendation for Golden Peacock Occupational Health & Safety Award 2019’</strong> by the Jury of the Golden Peacock Awards, instituted by the Institute of Directors, India </p>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Slider>

                                                        </Col>



                                                    </Row>
                                                </Col>
                                            </Row>

                                        </div>
                                            : ''}

                                        {text3 ?
                                            <div className="card_round lifemt">
                                                <Row className="justify-content-center mb-3">
                                                    <Col md={12} className="lifemiddle">
                                                        <Row>
                                                            <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.5s">

                                                                <div className="lifeatnuvocosmart justify-content-center row">
                                                                    <h4 className="row">{t("life_at_nuvoco.awards.Safer")}</h4>
                                                                    <img src={smarticon} />
                                                                </div>
                                                                <Slider {...settings}>
                                                                    <Card>

                                                                        <Card.Img variant="top" src={safer20202} />
                                                                        <Card.Body>
                                                                            <p>Nuvoco's Chittorgarh Cement Plant (CCP) was recognised with a <strong>‘Silver Award’ under the Manufacturing and Engineering Sector – Cement and Allied in Large Enterprise</strong> Category by the Indian Chamber of Commerce - Kolkata at the ICC National Occupational Health and Safety Awards 2020.
                                                                            </p>
                                                                        </Card.Body>
                                                                    </Card>

                                                                    <Card>

                                                                        <Card.Img variant="top" src={safer20201} />
                                                                        <Card.Body>
                                                                            <p>Nuvoco's Chittorgarh Cement Plant received the <strong>‘Factory Safety Award Programme – 2020’ </strong>for the in the large category by the Inspection Department of Factories and Boilers, Government of Rajasthan.
                                                                            </p>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Slider>

                                                            </Col>

                                                            <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.5s">
                                                                <div className="lifeatnuvocosmart justify-content-center row">
                                                                    <h4 className="row">{t("life_at_nuvoco.awards.Smarter")}</h4>
                                                                    <img src={smarticon1} />
                                                                </div>
                                                                <Slider {...settings}>
                                                                    <Card>
                                                                        <Card.Img variant="top" src={smarter20202} />
                                                                        <Card.Body>
                                                                            <p>Nuvoco’s <strong>InstaMix (ready-to-use bag concrete and mortar) won the ‘Golden Peacock Innovative Product/Service Award 2020’.</strong></p>
                                                                        </Card.Body>
                                                                    </Card>
                                                                    <Card>
                                                                        <Card.Img variant="top" src={smarter20201} />
                                                                        <Card.Body>
                                                                            <p>Nuvoco won the <strong>‘10th CII National HR Excellence Award 2019-20’ </strong>for demonstrating the Best Practices in HR. With this win, Nuvoco is placed in the 401-500 Band, which represents a “Strong Commitment to HR Excellence”.</p>
                                                                        </Card.Body>
                                                                    </Card>

                                                                </Slider>

                                                            </Col>

                                                            <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.5s">
                                                                <div className="lifeatnuvocosmart justify-content-center row">
                                                                    <h4 className="row">{t("life_at_nuvoco.awards.Sustainable")}</h4>
                                                                    <img src={smarticon2} />
                                                                </div>
                                                                <Card>
                                                                    <Card.Img variant="top" src={sustainable2020} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco won the <strong>‘FICCI CSR Award 2018-19’ in the Education category</strong> for project ‘Shikshit Sunderhattu’ near Jojobera Cement Plant (JCP) at the 18th edition of FICCI CSR Awards.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>


                                                        </Row>
                                                    </Col>
                                                </Row>

                                            </div>
                                            : ''}

                                        {text4 ? <div className="card_round lifemt">
                                            <Row className="justify-content-center mb-3">
                                                <Col md={12} className="lifemiddle">
                                                    <Row>
                                                        <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.5s">

                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Safer")}</h4>
                                                                <img src={smarticon} alt='' />
                                                            </div>
                                                            <Card>
                                                                <Card.Img variant="top" src={awardblur} />
                                                                <Card.Body>
                                                                    <p>Nuvoco’s Arasmeta Cement Plant (ACP) Medical Center gets featured in <strong>‘Asia Book of Record’ for conducting maximum blood sugar tests in a single day.</strong>
                                                                    </p>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>

                                                        <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.5s">
                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Smarter")}</h4>
                                                                <img src={smarticon1} alt='' />
                                                            </div>
                                                            <Card>
                                                                <Card.Img variant="top" src={smarter20211} />
                                                                <Card.Body>
                                                                    <p>Nuvoco received the <strong>first-ever Patent Certificate for 'Water Resistant Cement Composition' (Duraguard WaterSeal)</strong> and will be valid for the next 20 years.</p>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>

                                                        <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.5s">
                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Sustainable")}</h4>
                                                                <img src={smarticon2} alt='' />
                                                            </div>
                                                            <Slider {...settings}>
                                                                <Card>
                                                                    <Card.Img variant="top" src={awardblur} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco’s Sonadih Limestone received <strong>5 Star rating for remarkable performance in the Implementation of the Sustainable Development Framework by Shri Pralhad Joshi, Minister of Mines - Government of India.</strong></p>
                                                                    </Card.Body>
                                                                </Card>

                                                                <Card>
                                                                    <Card.Img variant="top" src={awardblur} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco’s Chittorgarh Cement Plant (CCP) bagged the Title of <strong>‘Excellent Energy Efficient Unit’</strong> by Confederation of Indian Industries (CII).</p>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Slider>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>


                                        </div>
                                            : ''}


                                        {text5 ? <div className="card_round lifemt">
                                            <Row className="justify-content-center mb-3">
                                                <Col md={12} className="lifemiddle">
                                                    <Row>
                                                        <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.5s">
                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Safer")}</h4>
                                                                <img src={smarticon} alt='' />
                                                            </div>
                                                            <Slider {...settings}>
                                                                <Card>
                                                                    <Card.Img variant="top" src={safer20221} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco’s Ready-Mix Concrete (RMX) team won<strong> Gold for Occupational Health and Safety</strong>  at the 6th Annual HSE Excellence and Sustainability during OHSSAI Conclave 2021 in the category of Occupational Health and Safety under manufacturing segment.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={awardCommon} />
                                                                    <Card.Body>
                                                                        <p>Chittorgarh Cement Plant (CCP) received the ‘Workplace OHSE Excellence Award’ from WSO (World Safety Organisation).</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={CII16th} />
                                                                    <Card.Body>
                                                                        <p>Sonadih Cement Plant (SCP) Limestone Mines won awards in safety in the Category of Mining.
                                                                        </p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={JJCP} />
                                                                    <Card.Body>
                                                                        <p>Jajpur Cement Plant (JJCP) was recognised at the 3rd National Electrical Safety
                                                                            Week 2022 for competing with state-level competitors and showcasing the best
                                                                            electrical practices adopted in the plant.
                                                                        </p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={MEJIA} />
                                                                    <Card.Body>
                                                                        <p>Mejia Cement Plant (MCP) was awarded the CII SHE Excellence State Level 1st Runner-up Award for Safety, Health, and Environment standards, in the category of Large-Scale Manufacturing.
                                                                        </p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={SHEAWARDED} />
                                                                    <Card.Body>
                                                                        <p>Sonadih Limestone Mines and Arasmeta Mines were recognised at the state level with a certificate during Yuva Utsav at Raipur for excellent work in the field of Safety, Health and Environment in the Mining sector.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={MinesSafety} />
                                                                    <Card.Body>
                                                                        <p>CCP mines team won a total of 3 awards in 46th Mines Safety Week 2022 1st Prize in SMP, OHS, First Aid & VTC, 1st Prize in Azadi Ka Amrit Mahotsav, and 2nd Prize in Maintenance of Mine Plans & Sections.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={BCP} />
                                                                    <Card.Body>
                                                                        <p>Bhiwani Cement Plant (BCP) gets honoured with a ‘Silver Award’ by the Indian Chamber of Commerce (ICC) in the categories of cement, paints, and allied (medium) industries for achieving excellence in the realm of Health and Safety by sustaining zero LTI in the last 10 years</p>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Slider>
                                                        </Col>

                                                        <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.6s">

                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Smarter")}</h4>
                                                                <img src={smarticon1} alt='' />
                                                            </div>
                                                            <Slider {...settings}>
                                                                <Card>
                                                                    <Card.Img variant="top" src={smarter20221} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco won the <strong>‘Best Brand in
                                                                            Construction and Building Materials Award’</strong>  at the Exchange4media
                                                                            presents 'Pride of India Brands'
                                                                            conference and awards.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={smarter20223} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco won <strong>Topmost Innovation Leader (Global) Award</strong> at the 14th World Innovation Congress and Awards, 2022. </p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={smarter20222} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco's Finance Team won the <strong>'Finance Team of the Year' Award </strong>at the 9th Finance Transformation India Summit & Awards 2022, held at Holiday Inn, Mumbai.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={NGRMPW} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco Vistas Corp Ltd was recognized as the Most Preferred Workplace in the Manufacturing Industry.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={GPA} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco has been conferred with the globally acclaimed Golden Peacock Award for Excellence in Corporate Governance – 2022</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={awardCommon} />
                                                                    <Card.Body>
                                                                        <p>Concreto Cement has been awarded the No. 1 Premium Cement Brand under the Excellence in Infrastructure Development Category at Jagran Achievers Awards 2022 – Dubai.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={StrategyOfTheYear} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco won the Sales and Operations Planning (S&OP) strategy of the Year award at the 6th Edition Demand Planning & Forecasting Summit and Awards 2022 hosted by UBS Forums Private Limited in Bengaluru</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={awardCommon} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco’s IM team received the “Game Changers” category award from SAP - the world leader in enterprise application software and cloud solutions for Project DEN (Digitally Enabled Nuvoco).</p>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Slider>

                                                        </Col>

                                                        <Col md={6} lg={4} className="wow fadeInUp lifeatnuvocosmartMain" data-wow-delay="0.7s">
                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Sustainable")}</h4>
                                                                <img src={smarticon2} alt='' />
                                                            </div>
                                                            <Slider {...settings}>
                                                                <Card>
                                                                    <Card.Img variant="top" src={sustainable20223} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco’s Panagarh Cement Plant (PCP) won the <strong> Clean and Green Environment Award for eco-friendly disposal of e-waste</strong>  from J.S.Pigments Ltd. </p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={sustainable20222} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco’s Chittorgarh Cement Plant won <strong>National Award (Silver Medal) for Manufacturing Competitiveness (NAMC) 2021 and Special Award for Extensive Use of Alternate Fuels & Raw Materials</strong> from the International Research Institute for Manufacturing (IRIM). </p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={cwtpa} />
                                                                    <Card.Body>
                                                                        <p>Chittorgarh Cement Plant (CCP) has won two prestigious awards in the Cement Manufacturing Industry.</p>
                                                                        <p>1. First Prize for energy conservation in cement manufacturing by Rajasthan Government under “Rajasthan Energy Conservation Award 2022”.</p>
                                                                        <p>2. National Award for circular Economy in 17th NCB International Conference for Cement Awards and Recognition</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={awardCommon} />
                                                                    <Card.Body>
                                                                        <p>Sonadih Cement Plant (SCP) won the Gold Medal on the Sustainability assessment by India Green Manufacturing Challenge (IGMC).</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={IRIM} />
                                                                    <Card.Body>
                                                                        <p>Sonadih Cement Plant (SCP) and Chittorgarh Cement Plant (CCP) received Gold Awards at India Green Manufacturing Challenge 2021-22.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={fiveStarAward} />
                                                                    <Card.Body>
                                                                        <p>For the second time in a row, Sonadih Limestone Mines received a 5-star Award for the Sustainable Development of Mines for the Year 2020-2021 from the Ministry of Mines, Government of India, in Delhi.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={awardCommon} />
                                                                    <Card.Body>
                                                                        <p>Chittorgarh Cement Plant (CCP) received the award for Achieving Circular Economy in Integrated Cement Plants for the years 2019-22.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={WHRS} />
                                                                    <Card.Body>
                                                                        <p>Chittorgarh Cement Plant (CCP) won the Waste to Energy, Clean Energy Award - 2022 in the Category of WHRS.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={awardCommon} />
                                                                    <Card.Body>
                                                                        <p>Sinla and Digrana mines bagged four prizes in various categories in the 32nd Mines Environment and Mineral Conservation Week
                                                                            First Prize in Waste Dump Management, Second Prize in Reclamation and Rehabilitation
                                                                            third Prize in Systematic and Scientific Development and Publicity and Propaganda.
                                                                        </p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={MEMC} />
                                                                    <Card.Body>
                                                                        <p>Chittorgarh Cement Plant’s SRK and AJ limestone mines won 4 awards in the following, Second Place in Mineral Conservation, and Third Place in Waste Dump Management, Reclamation and Rehabilitation and Sustainable Development.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={SEEM} />
                                                                    <Card.Body>
                                                                        <p>Chittorgarh Cement Plant (CCP) won SEEM National Award for the second consecutive year.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={RMXGreenPro} />
                                                                    <Card.Body>
                                                                        <p>Six RMX plants namely Naroda, Gurgaon, Miyapur, Patancheru, Whitefield, and Sanathal received the GreenPro certification from CII IGBC for producing Ecodure - low carbon.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={JOJOBera5Star} />
                                                                    <Card.Body>
                                                                        <p>Jojobera Cement Plant (JCP) was awarded a 5-star rating by Jharkhand State Pollution Control Board for excellent compliance with the Environment.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Slider>
                                                        </Col>


                                                    </Row>
                                                </Col>
                                            </Row>

                                        </div>
                                            : ''}
                                        {text6 ? <div className="card_round lifemt">
                                            <Row className="justify-content-center mb-3">
                                                <Col md={12} className="lifemiddle">
                                                    <Row className="justify-content-center">
                                                        <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.5s">
                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Safer")}</h4>
                                                                <img src={smarticon} alt='' />
                                                            </div>
                                                            <Slider {...settings}>
                                                                <Card>
                                                                    <Card.Img variant="top" src={csrAward} />
                                                                    <Card.Body>
                                                                        <p><strong>The Best CSR Impact Awards</strong><br />Nuvoco’s CSR team has been honored with the prestigious “Best CSR Impact Award” at the 8th Edition Corporate Social Responsibility Summit and Awards by UBS Forums. We nominated PROJECT TARA under Swasth Bharat- Best CSR Impact Category.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" style={{ maxWidth: "326px", maxHeight: "206px", objectFit: "contain" }} src={GlobalHealthCareWellness} />
                                                                    <Card.Body>
                                                                        <p><strong>Excellence in Global Health Care & Wellness</strong><br />We are excited to share that Dr. Abhishek Pandey, Head Medical Officer at Nuvoco, has been honoured with the prestigious Best Occupational Health Physician Award at the Biggest Healthcare Summit conducted by the Global Healthcare & Wellness Awards on December 25, 2023.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Slider>
                                                        </Col>
                                                        <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.5s">
                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Sustainable")}</h4>
                                                                <img src={smarticon} alt='' />
                                                            </div>
                                                            <Slider {...settings}>
                                                                <Card>
                                                                    <Card.Img variant="top" src={RisdaAward} />
                                                                    <Card.Body>
                                                                        <p><b>Environmental Excellence Award for Risda</b><br />Risda Cement Plant received the <b>Environmental Excellence Award in the Gold Category</b> from the <b>Indian Chamber of Commerce at the 17th Environment Partnership Summit in Kolkata.</b> The Honorable Minister of Environment and IAS Member Secretary, West Bengal Pollution Control Board presented the award.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={IndianGreenManufacturer} />
                                                                    <Card.Body>
                                                                        <p><b>Chittor Cement Plant Secures Gold at India Green Manufacturing Challenge 2023</b><br />Our Chittor Cement Plant (CCP) clinched the gold category at the prestigious <b>India Green Manufacturing Challenge 2023</b> organized by the International Research Institute for Manufacturing (IRIM). This marks our second consecutive year in the Gold,</p>
                                                                    </Card.Body>
                                                                </Card>

                                                                <Card>
                                                                    <Card.Img variant="top" src={safer20223} />
                                                                    <Card.Body>
                                                                        <p>Nuvoco’s Chittor Cement Plant (CCP) won the prestigious <strong>Golden Peacock Award for Best Energy Efficient Plant in the cement sector.</strong> This recognition highlights Nuvoco's commitment to sustainability and innovation, setting a new benchmark for energy efficiency in the industry.</p>
                                                                    </Card.Body>
                                                                </Card>

                                                            </Slider>
                                                        </Col>

                                                        <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.6s">

                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Smarter")}</h4>
                                                                <img src={smarticon1} alt='' />
                                                            </div>
                                                            <Slider {...settings}>
                                                                <Card>
                                                                    <Card.Img variant="top" src={GoldenPeacockAward} />
                                                                    <Card.Body>
                                                                        <p><strong>Chittor Cement Plant Bags Golden Peacock Award 2023</strong><br />Our Chittor Cement Plant ( CCP ) has been honored with the prestigious <b>Golden Peacock Innovation Management Award 2023</b> marking the second Golden Peacock Award for the Plant.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={scaleAward} />
                                                                    <Card.Body>
                                                                        <p><strong>Logistics Team Wins Prestigious SCALE Award 2023</strong><br />We achieved a significant milestone by clinching the prestigious 10th edition of the <b>SCALE Award (Supply Chain and Logistics Excellence) 2023 for ‘Best-in-class Usage of GPS Technology in the Cement Industry’</b> in the Cement Category organized by the Confederation of Indian Industry (CII).</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={indianCSRAward} />
                                                                    <Card.Body>
                                                                        <p><strong>Indian CSR Awards</strong><br />Nuvoco's Team CSR has been recognized with the prestigious <b>INDIAN CSR AWARDS - 2023</b> in the <b>"Best Skill Development in Youth & Women Empowerment"</b> category. This accolade was presented at a formal event on <b>October 7, 2023</b>, in New Delhi, hosted by Marketing & Brand Honchos.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={shikshaShreeAward} />
                                                                    <Card.Body>
                                                                        <p><strong>Shiksha Shree Award</strong><br />Nuvoco’s NCP has been honored with the prestigious "Shiksha Shree Award" by the District Education Department of the Rajasthan Government. This award Is a testament to our outstanding commitment to the development of schools in villages near our plant while making a positive impact.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img variant="top" src={smarter20231} />
                                                                    <Card.Body>
                                                                        <p>Concreto Cement has been awarded the prestigious <strong>Big Impact Awards 2023</strong> for <strong>Big Impact Creator</strong> in the Premium Building Material category.</p>
                                                                    </Card.Body>
                                                                </Card>

                                                            </Slider>

                                                        </Col>


                                                    </Row>
                                                </Col>
                                            </Row>

                                        </div>
                                            : ''}
                                        {text7 ? <div className="card_round lifemt">
                                            <Row className="justify-content-center mb-3">
                                                <Col md={12} className="lifemiddle">
                                                    <Row className="justify-content-center">
                                                        {/* <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.5s">
                                    <div className="lifeatnuvocosmart justify-content-center row">
                                    <h4 className="row">SAFER</h4>  
                                        <img src={smarticon} alt='' />  
                                    </div>
                                    <Slider {...settings}>
                                        <Card>                                
                                            <Card.Img variant="top" style={{maxWidth:"326px", maxHeight:"206px", objectFit:"contain"}} src={GlobalHealthCareWellness} />
                                            <Card.Body>
                                            <p><strong>Excellence in Global Health Care & Wellness</strong><br/>We are excited to share that Dr. Abhishek Pandey, Head Medical Officer at Nuvoco, has been honoured with the prestigious Best Occupational Health Physician Award at the Biggest Healthcare Summit conducted by the Global Healthcare & Wellness Awards on December 25, 2023.</p>
                                            </Card.Body>
                                        </Card>
                                    </Slider>
                                </Col>  */}
                                                        <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.5s">
                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Sustainable")}</h4>
                                                                <img src={smarticon} alt='' />
                                                            </div>
                                                            <Slider {...settings}>
                                                                <Card>
                                                                    <Card.Img style={{ maxHeight: "206px", margin: "0px auto", objectFit: "contain" }} variant="top" src={FastestDecarbonising} />
                                                                    <Card.Body>
                                                                        <p><b>Nuvoco Awarded as the Fastest Decarbonising Cement Company</b><br />EQ International Magazine hosted the <b>"SuryaCon Kolkata Conference + East India Annual Solar Awards 2024" on May 9, 2024, at Taj City Center, New Town, Kolkata. Nuvoco was honored with the "Fastest Decarbonising Cement Company of the Year"</b> award for East India.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img style={{ maxHeight: "206px", margin: "0px auto", objectFit: "contain" }} variant="top" src={NationalSustainabilityCompetitionRMX} />
                                                                    <Card.Body>
                                                                        <p><b>Excellence Award in National Sustainability Competition for RMX Industry</b><br />Nuvoco received the <b>Excellence Award</b> from the Jury at the <b>3rd Edition</b> of the <b>National Awards for Sustainability</b> in the Cement and Concrete Industry and Net Zero Conclave held at Hotel Novotel in Hyderabad on January 29th and 30th, 2024.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img style={{ maxHeight: "206px", margin: "0px auto", objectFit: "contain" }} variant="top" src={ChittorCementAchievedQCFI} />
                                                                    <Card.Body>
                                                                        <p><b>Chittor Cement Achieved QCFI National Sustainability Awards</b><br />Chittor Cement Plant triumphed at the QCFI National Sustainability Awards, clinching the title of 'Excellent Unit' in three distinct categories: Energy, AFR , and Water.</p>
                                                                    </Card.Body>
                                                                </Card>

                                                            </Slider>
                                                        </Col>
                                                        <Col md={6} lg={4} className="wow fadeInUp mb-4 lifeatnuvocosmartMain" data-wow-delay="0.6s">
                                                            <div className="lifeatnuvocosmart justify-content-center row">
                                                                <h4 className="row">{t("life_at_nuvoco.awards.Smarter")}</h4>
                                                                <img src={smarticon1} alt='' />
                                                            </div>
                                                            <Slider {...settings}>
                                                                <Card>
                                                                    <Card.Img style={{ margin: "0px auto", maxHeight: "206px", objectFit: "contain" }} variant="top" src={GoldenPetalAwardNimbol} />
                                                                    <Card.Body>
                                                                        <p><strong>Golden Petal Award to Nimbol Cement Plant</strong><br />The HR team at Nimbol Cement Plant has been honored with the prestigious Green Maple Foundation – <b>Golden Petal Awards in the 'DIAMOND' category</b>. The awards were announced in Hyderabad, recognizing NCP’s dedication to implementing <b>"Best HR Practices"</b>.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img style={{ maxHeight: "206px", margin: "0px auto", objectFit: "contain" }} variant="top" src={CiiNational} />
                                                                    <Card.Body>
                                                                        <p><strong>14th CII National HR Excellence Award</strong><br />HR team gets recognition at the highly reputed <b>14th CII National HR Excellence Award</b> organized in Mumbai in the <b>"Significant Achievement in HR Excellence"</b> category!</p>
                                                                    </Card.Body>
                                                                </Card>

                                                                <Card>
                                                                    <Card.Img style={{ margin: "0px auto", maxHeight: "206px", objectFit: "contain" }} variant="top" src={MejiaCementPlant} />
                                                                    <Card.Body>
                                                                        <p><strong>Mejia Cement Plant Wins SHE Excellence Award</strong><br />Mejia Cement Plant secured the <b>1st Runner Up Trophy from the Confederation of Indian Industry (CII) SHE Excellence Award (2022-23)</b> for its exemplary development and implementation of a highly effective safety management system and procedures.</p>
                                                                    </Card.Body>
                                                                </Card>
                                                                <Card>
                                                                    <Card.Img style={{ margin: "0px auto", maxHeight: "206px", objectFit: "contain" }} variant="top" src={SonadihLimestoneMines} />
                                                                    <Card.Body>
                                                                        <p><strong>Sonadih Limestone Mines (SCP) has bagged 6 prizes </strong><br />Sonadih Limestone Mines (SCP) received six awards, including the Overall 1st Prize in <b>A-1 Category Mines</b>, presented by Director General of Mines Safety, <b>Mr. Prabhat Kumar</b>. The awards were handed over by <b>Mr. Anant Kumar Mahobe</b> (Chief Manufacturing Officer -East),</p>
                                                                    </Card.Body>
                                                                </Card>


                                                            </Slider>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                            : ''}



                                    </Container>


                                </Col>
                            </Row>

                            {/*  <CareerForm /> */}
                        </div>
                        <div className="mt-5 fakeEmployment">
                            <Row>
                                <Col>
                                    <h2 className='grad-heading'>{t("life_at_nuvoco.fake_desclaimer")}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: t("life_at_nuvoco.fake_desc") }}></p>
                                </Col>
                            </Row>
                        </div>

                        {/* <div className="award mt-5">
            <Row>
             <Col>
              <h2 className='grad-heading'><a href="https://career44.sapsf.com/career?company=nuvocovist&site=VjItOWdEZkt4clliVWt3ZVFyK0NtSlhSQT09" target="_blank">Join Our Team</a></h2>


              </Col>
             </Row>
             </div>     */}




                    </Container>

                </div>

                <Modal size="lg" centered="true"
                    show={this.state.isOpen1} onHide={this.closeModal1} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center lifepopupdesign">
                                    <article className="p-4">
                                        <h4>{t("home.life_nuvoco.totla_rewards")}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: t("home.life_nuvoco.totla_rewards_desc") }}></p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={this.state.isOpen2} onHide={this.closeModal2} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center lifepopupdesign">
                                    <article className="p-4">
                                        <h4>{t("home.life_nuvoco.learning_development")}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: t("home.life_nuvoco.learning_development_desc") }}></p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={this.state.isOpen3} onHide={this.closeModal3} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center lifepopupdesign">
                                    <article className="p-4">
                                        <h4>{t("home.life_nuvoco.employee_engagement")}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: t("home.life_nuvoco.employee_engagement_desc") }}></p>

                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true"
                    show={this.state.isOpen4} onHide={this.closeModal4} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
                            <Col md={12}>

                                <div className="align-item-center lifepopupdesign">
                                    <article className="p-4">
                                        <h4>{t("home.life_nuvoco.growth_accelerator")}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: t("home.life_nuvoco.growth_accelerator_desc") }}></p>
                                    </article>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                <Modal size="lg" centered="true" show={this.state.disclaimer} onHide={this.closeDisclaimer} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
                    <Modal.Body>
                        <Modal.Header closeButton></Modal.Header>
                        <Row>
                            <Col md={12} className="d-flex align-items-center">
                                <h2 className='grad-heading'>{t("life_at_nuvoco.fake_desclaimer")}</h2>
                            </Col>
                            <Col md={12}>

                                <div className="d-flex align-item-center">
                                    <article className="p-4">
                                        <p dangerouslySetInnerHTML={{ __html: t("life_at_nuvoco.fake_desc") }}></p>
                                    </article>
                                </div>


                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
export default withTranslation()(LifeatNuvoco)